import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import Select from 'react-select'

import PrimaryButton from '../../PrimaryButton'

import { fetchUsers } from '../../../actions/user'
import { closeAddUserModal, openLoaderModal, closeLoaderModal, openErrorModal } from '../../../actions/ui'
import { addUsersToTrial } from '../../../actions/trial'

import './AddUserModal.scss'

Modal.setAppElement('#root')

class AddUserModal extends Component {
  constructor() {
    super()

    this.state = {
      filteredUsers: [],
      selectedUsers: [],
    }

    this.handleAfterOpen = this.handleAfterOpen.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleChange = this.handleChange.bind(this)
  }

  handleAfterOpen() {
    this.props.fetchUsers().then(usersAction => {
      const idsToFilter = this.props.trialToUpdate.users.map(user => user.id)
      const filteredUsers = usersAction.users.filter(x => !idsToFilter.includes(x.id) && x.company.id === this.props.trialToUpdate.customer.companyId)

      this.setState({ filteredUsers })
    })
  }

  handleChange(selectedUsers) {
    this.setState({ selectedUsers })
  }

  handleSubmit() {
    const trialId = this.props.trialToUpdate.id
    const users = this.state.selectedUsers
    this.props.openLoaderModal()
    this.props.addUsersToTrial(trialId, users).then(() => {
      if (this.props.onClose) this.props.onClose()
      this.props.closeModal()
    }).catch((e) => {
      this.props.openErrorModal()
    }).then(() => {
      this.props.closeLoaderModal()
    })
  }

  render() {
    return (
      <Modal
        overlayClassName="add-user-modal-overlay"
        className="add-user-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button className="add-user-modal__close" onClick={this.props.closeModal}></button>
        <h1 className="add-user-modal__heading">Adicionar membros</h1>
        <p className="add-user-modal__description">Descricao do processo</p>
        <form className="add-user-modal__form">
          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            options={this.state.filteredUsers}
            onChange={this.handleChange}
            placeholder="Membros"
            isMulti
          />
          <PrimaryButton fullWidth onClick={this.handleSubmit}>Confirmar</PrimaryButton>
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.ui.isAddUserModalOpen,
  users: state.user.list,
  trialToUpdate: state.trial.selected,
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeAddUserModal()),
  fetchUsers: () => dispatch(fetchUsers()),
  addUsersToTrial: (trialId, users) => dispatch(addUsersToTrial(trialId, users)),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(AddUserModal)
