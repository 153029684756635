import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'

import LoginBox from '../../components/LoginBox'
import PrimaryButton from '../../components/PrimaryButton'

import './ChangePasswordSuccess.scss'

class ChangePasswordSuccess extends Component {
  constructor(props) {
    super(props)

    this.state = {
      clickedNext: false,
    }

    this.handleClick = this.handleClick.bind(this)
  }

  handleClick() {
    this.setState({ clickedNext: true })
  }

  render() {
    if (this.state.clickedNext) {
      return <Redirect to='/trials' />
    } else {
      return (
        <div className='change-password-success-screen'>
          <LoginBox subheader={ 'Sua senha foi alterada com sucesso!' }>
            <PrimaryButton fullWidth onClick={ this.handleClick }>Próximo</PrimaryButton>
          </LoginBox>
        </div>
      )
    }
  }
}

export default ChangePasswordSuccess
