import customerService from '../services/customer'

export const CREATE_CUSTOMER_START = 'CREATE_CUSTOMER_START'
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR'
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS'
export const SET_SELECTED_CUSTOMER = 'SET_SELECTED_CUSTOMER'
export const UPDATE_CUSTOMER_START = 'UPDATE_CUSTOMER_START'
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR'
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMERS_START = 'FETCH_CUSTOMERS_START'
export const FETCH_CUSTOMERS_ERROR = 'FETCH_CUSTOMERS_ERROR'
export const FETCH_CUSTOMERS_SUCCESS = 'FETCH_CUSTOMERS_SUCCESS'

export const fetchCustomersStart = () => ({
  type: FETCH_CUSTOMERS_START,
})

export const fetchCustomersCompanyIdStart = () => ({
  type: FETCH_CUSTOMERS_START,
})

export const fetchCustomersError = () => ({
  type: FETCH_CUSTOMERS_ERROR,
})

export const fetchCustomersSuccess = customers => ({
  type: FETCH_CUSTOMERS_SUCCESS,
  customers,
})

export const fetchCustomers = () => dispatch => {
  dispatch(fetchCustomersStart())

  return customerService.getAll().then(response => {
    const customers = response.data.map(customer => {
      return { ...customer, label: customer.name, value: customer.id }
    })
    return dispatch(fetchCustomersSuccess(customers))
  })
}

export const fetchCustomersByCompanyId = companyId => {
  return customerService.getByCompanyId(companyId).then(response => {
    const customers = response.data.map(customers => {
      return customers;
    })
    return customers;
  })
}

export const fetchCustomersForUser  = (userId, companyId) => {
  return customerService.getCustomersForUser(companyId, userId).then(response => {
    const customers = response.data.map(customers => {
      return customers;
    })
    return customers;
  })
}

export const createCustomerStart = () => ({
  type: CREATE_CUSTOMER_START,
})

export const createCustomerError = () => ({
  type: CREATE_CUSTOMER_ERROR,
})

export const createCustomerSuccess = () => ({
  type: CREATE_CUSTOMER_SUCCESS,
})

export const createCustomer = customer => dispatch => {
  dispatch(createCustomerStart())

  return customerService.createCustomer(customer).then(() => {
    dispatch(fetchCustomers())
    return dispatch(createCustomerSuccess())
  })
}

export const setSelectedCustomer = customer => ({
  type: SET_SELECTED_CUSTOMER,
  customer,
})

export const updateCustomerStart = () => ({
  type: UPDATE_CUSTOMER_START,
})

export const updateCustomerError = () => ({
  type: UPDATE_CUSTOMER_ERROR,
})

export const updateCustomerSuccess = () => ({
  type: UPDATE_CUSTOMER_SUCCESS,
})

export const updateCustomer = (id, customer) => dispatch => {
  dispatch(updateCustomerStart())

  return customerService.updateCustomer(id, customer).then(() => {
    dispatch(fetchCustomers())
    return dispatch(updateCustomerSuccess())
  })
}
