import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Select from "react-select";

import PrimaryButton from "../../PrimaryButton";

import { fetchCustomers, fetchCustomersForUser } from "../../../actions/customer";
import { createTrial, fetchTrials, setCurrentTrialsPage } from "../../../actions/trial";
import {
  closeNewTrialModal,
  openLoaderModal,
  closeLoaderModal,
  openErrorModal,
} from "../../../actions/ui";
import { fetchUsers } from "../../../actions/user";

import "./NewTrialModal.scss";

Modal.setAppElement("#root");

class NewTrialModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      name: "",
      status: null,
      description: "",
      users: [],
      formattedCustomers: [],
      formattedUsers: [],
      selectedCustomer: null,
    };

    this.handleAfterOpen = this.handleAfterOpen.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleStatusChange = this.handleStatusChange.bind(this);
    this.handleDescriptionChange = this.handleDescriptionChange.bind(this);
    this.handleUsersChange = this.handleUsersChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
  }

  handleAfterOpen() {
    const { customerId, fetchUsers, fetchCustomers } = this.props;

    fetchCustomers().then((action) => {
      this.getAllowedCustomers(sessionStorage.getItem('metodocUserId'), sessionStorage.getItem('metodocCompanyId'))
      .then((result) => {
        console.log(result)
        const customersItems = result.length ? result : action.customers;
        const formattedCustomers = customersItems.map((x) => ({
          id: x.id,
          name: x.name,
          label: x.name,
          value: x.id,
          company: x.company,
          companyId: x.companyId,
        }));
        const selectedCustomer =
          formattedCustomers.filter((x) => x.id === customerId)[0] ||
          formattedCustomers[0];

        fetchUsers().then((usersAction) => {
          const formattedUsers =
            usersAction.users &&
            usersAction.users.filter(
              (x) => x.company.id === selectedCustomer.companyId
            );
          this.setState({ formattedUsers });
        });

        this.setState({ formattedCustomers, selectedCustomer });
      });
    });
  }

  getAllowedCustomers(userId, companyId) {
    return new Promise((resolve, reject) => {
      fetchCustomersForUser(userId, companyId).then((response) => {
        const allowedCustomers = response.map((x) => ({
          id: x.id,
          name: x.name,
          label: x.name,
          value: x.id,
          company: x.companyId,
          companyId: x.companyId,
        }));
        
        resolve(allowedCustomers);
      });
    });
  }

  handleSubmit() {
    const trial = {
      customerId: this.state.selectedCustomer.id,
      name: this.state.name,
      status: this.state.status.name,
      description: this.state.description,
      users: this.state.users,
    };

    this.props.openLoaderModal();

    this.props
      .createTrial(trial)
      .then(() => {
        this.setState({ name: "", status: null, description: "", users: [] });
        this.props.closeModal();
        this.props.fetchTrials({
          customerId: this.state.selectedCustomer.id,
          page: 1,
        });
        this.props.setCurrentTrialsPage(0);
        if (this.props.onClose) this.props.onClose();
      })
      .catch((e) => {
        this.props.openErrorModal();
      })
      .then(() => {
        this.props.closeLoaderModal();
      });
  }

  handleCustomerChange(selectedCustomer) {
    const formattedUsers = this.props.userList.filter(
      (x) => x.company.id === selectedCustomer.companyId
    );
    this.setState({ selectedCustomer, formattedUsers });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleStatusChange(status) {
    this.setState({ status });
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleUsersChange(selectedUsers) {
    const users = selectedUsers.map((user) => user.id);
    this.setState({ users });
  }

  render() {
    const statusOptions = [
      { name: "public", label: "Público", value: "public" },
      { name: "private", label: "Privado", value: "private" },
    ];

    const { name, status, users } = this.state;
    const isValidForm =
      name && name.length > 0 && status && users;

    return (
      <Modal
        overlayClassName="trial-modal-overlay"
        className="trial-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button
          className="trial-modal__close"
          onClick={this.props.closeModal}
        ></button>
        <h1 className="trial-modal__heading">Adicionar novo processo</h1>
        <form className="trial-modal__form">
          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Cliente..."
            options={this.state.formattedCustomers}
            value={this.state.selectedCustomer}
            onChange={this.handleCustomerChange}
          />
          <input
            className="trial-modal__form__input"
            type="text"
            placeholder="Nome do processo (máx. 250 caracteres)"
            onChange={this.handleNameChange}
            value={this.state.name}
            maxLength="250"
          />
          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            options={statusOptions}
            onChange={this.handleStatusChange}
            placeholder="Status do processo"
            value={this.state.status}
          />
          <textarea
            className="trial-modal__form__textarea"
            placeholder="Descrição (máx. 500 caracteres)"
            onChange={this.handleDescriptionChange}
            value={this.state.description}
            maxLength="500"
          ></textarea>
          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Adicionar membros"
            options={this.state.formattedUsers}
            onChange={this.handleUsersChange}
            isMulti
          />
          <PrimaryButton
            disabled={!isValidForm}
            fullWidth
            onClick={this.handleSubmit}
          >
            Confirmar
          </PrimaryButton>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  customers: state.customer.list,
  customerId: state.customer.selected.id,
  isOpen: state.ui.isNewTrialModalOpen,
  userList: state.user.list,
});

const mapDispatchToProps = (dispatch) => ({
  createTrial: (trial) => dispatch(createTrial(trial)),
  closeModal: () => dispatch(closeNewTrialModal()),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchTrials: ({ customerId, page }) =>
    dispatch(fetchTrials({ customerId, page })),
  fetchCustomersForUser: () => dispatch(fetchCustomersForUser()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
  fetchCustomers: () => dispatch(fetchCustomers()),
  setCurrentTrialsPage: (page) => dispatch(setCurrentTrialsPage(page)),
});

export default connect(mapStateToProps, mapDispatchToProps)(NewTrialModal);
