const isLoggedIn = () => {
  const token = sessionStorage.getItem('metodocToken')
  return (token !== null && token !== '')
}

const isFirstAccess = () => {
  const firstAccess = sessionStorage.getItem('metodocFirstAccess')
  return firstAccess === 'true'
}

const isMetodocUser = () => {
  const userPermission = sessionStorage.getItem('metodocPermission')
  return userPermission === 'metodoc'
}

const isAdminUser = () => {
  const userPermission = sessionStorage.getItem('metodocPermission')
  return userPermission === 'admin'
}

const isRegularUser = () => {
  const userPermission = sessionStorage.getItem('metodocPermission')
  return userPermission === 'user'
}

const getName = () => {
  const name = sessionStorage.getItem('metodocUsername')
  return name === null ? '' : name
}

const getUserId = () => {
  const id = sessionStorage.getItem('metodocUserId')
  return id === null ? '' : id
}

const getCompanyId = () => {
  const id = sessionStorage.getItem('metodocCompanyId')
  return id === null ? '' : id
}

export default {
  isLoggedIn,
  isFirstAccess,
  isRegularUser,
  isAdminUser,
  isMetodocUser,
  getName,
  getCompanyId,
  getUserId
}
