import React from 'react'
import Avatar from '../Avatar'

import utilsService from '../../services/utils'

import './ActivityList.scss'

const ActivityList = ({ data }) => {

  return (
    <ul className="activity-list">
      {data && (data.length > 0) && data.map((activity, i) => {
        return (
          <li key={i} className="activity-list__item">
            <div>
              <Avatar small withSpacing username={activity.user.name} />
              <span className="activity-list__actor">{activity.user.name} </span>
              <span className="activity-list__action">{activity.description}</span>
            </div>
            <span className="activity-list__date">{utilsService.getFormattedDate(activity.createdAt.toString())}</span>
          </li>
        )
      })}
    </ul>
  )
}

export default ActivityList
