import React from 'react'
import { Link } from 'react-router-dom'

import './Tab.scss'

const Tab = ({ active, linkTo = '#', text, onClick }) => {
  return (
    <Link
      className={ 'tab' + (active ? ' tab--active' : '') }
      to={ linkTo }
      onClick={onClick}
    >{ text }</Link>
  )
}

export default Tab
