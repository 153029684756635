import axios from "axios";

import config from "../config";
import { ACTIONS, TYPES } from "../config/consts";

const getAllForUser = (customerId, page, sort, search) => {
  const params = {
    page,
  };

  if (customerId !== "") {
    Object.assign(params, { customerId });
  }

  if (sort) {
    Object.assign(params, {
      sortField: sort[0].id,
      sortOrder: sort[0].desc ? "DESC" : "ASC",
    });
  }

  Object.assign(params, { search });

  return axios.get(`${config.baseUrl}/trials`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
    params,
  });
};

const getById = (id) => {
  return axios.get(`${config.baseUrl}/trials/${id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const getDocumentsById = (params) => {
  return axios.get(`${config.baseUrl}/trials/${params.trialId}/documents`, {
    params,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const createTrial = (trial) => {
  trial.historic = getMessage(ACTIONS.CREATE, TYPES.TRIALS);
  return axios.post(`${config.baseUrl}/trials`, trial, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const updateTrial = (trialId, updates, additionalInfo) => {
  updates.historic = getMessage(ACTIONS.UPDATE, TYPES.TRIALS, additionalInfo);
  return axios.put(`${config.baseUrl}/trials/${trialId}`, updates, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const removeTrial = (trial) => {
  return axios.delete(`${config.baseUrl}/trials/${trial.id}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const downloadAllDocuments = (trial) => {
  return axios.get(`${config.baseUrl}/trials/${trial.id}/download-documents`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const addUsersToTrial = (trialId, users) => {
  const historic = getMessage(
    ACTIONS.CREATE,
    TYPES.USERS,
    users.map((u) => u.name).join(", ")
  );
  return axios.post(
    `${config.baseUrl}/trials/${trialId}/users`,
    { users: users.map((user) => user.id), historic },
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
      },
    }
  );
};

const getActivitiesForTrial = (params) => {
  return axios.get(
    `${config.baseUrl}/trialhistory/by-trial/${params.trialId}`,
    {
      params,
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
      },
    }
  );
};

const addActivityToTrial = (activity) => {
  activity.historic = getMessage(
    activity.action,
    activity.type,
    activity.additionalInfo
  );
  return axios.post(`${config.baseUrl}/trialhistory`, activity, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const getMessage = (method, type, additionalInfo) => {
  if (method === ACTIONS.CREATE && type === TYPES.USERS)
    return additionalInfo ? `{0} adicionou ${additionalInfo} ao processo.` : "";
  else if (method === ACTIONS.CREATE && type === TYPES.TRIALS)
    return "{0} criou o processo.";
  else if (method === ACTIONS.VIEW && type === TYPES.TRIALS)
    return "{0} visualizou o processo.";
  else if (method === ACTIONS.CREATE && type === TYPES.DOCUMENTS)
    return '{0} anexou o documento "{1}".';
  else if (method === ACTIONS.VIEW && type === TYPES.DOCUMENTS)
    return '{0} visualizou o documento "{1}".';
  else if (method === ACTIONS.UPDATE && type === TYPES.TRIALS)
    return additionalInfo ? `{0} alterou ${additionalInfo}.` : "";
  else if (method === ACTIONS.DELETE && type === TYPES.DOCUMENTS)
    return '{0} removeu o documento "{1}".';
  else if (method === ACTIONS.UPDATE && type === TYPES.DOCUMENTS)
    return additionalInfo
      ? `{0} alterou ${additionalInfo} do documento "{1}".`
      : "";
  else if (method === ACTIONS.ATTACH && type === TYPES.DOCUMENTS)
    return additionalInfo
      ? `{0} vinculou ${additionalInfo} ao documento "{1}".`
      : "";
  else if (method === ACTIONS.DOWNLOAD)
    return '{0} fez download do documento "{1}".';
  else if (method === ACTIONS.DOWNLOAD_ALL)
    return "{0} fez download do processo.";
  else throw Error("invalid history process");
};

export default {
  addUsersToTrial,
  getActivitiesForTrial,
  addActivityToTrial,
  getAllForUser,
  getById,
  getDocumentsById,
  createTrial,
  updateTrial,
  removeTrial,
  downloadAllDocuments,
  getMessage,
};
