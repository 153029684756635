import React, { Component } from "react";
import { connect } from "react-redux";

import {
  toggleDocumentDetailPopover,
  openConfirmModal,
  closeConfirmModal,
  openEditDocumentModal,
  openAttachDocumentModal,
  openInfoDocumentModal,
  openErrorModal,
  openLoaderModal,
  closeLoaderModal,
} from "../../actions/ui";
import ConfirmModal from "../../components/modals/ConfirmModal";

import { addActivity } from "../../actions/trial";

import "./DocumentDetails.scss";
import { removeDocument, setSelectedDocument } from "../../actions/document";
import config from "../../config";
import { PENDING_STORAGE, TYPES, ACTIONS } from "../../config/consts";
import auth from "../../services/auth";
import utilsService from "../../services/utils";

import docPlaceholderImg from "../../assets/doc-placeholder.svg";

class DocumentDetails extends Component {
  constructor() {
    super();

    this.state = {
      showConfirmModal: false,
      timestamp: Date.now(),
    };
  }

  componentDidUpdate(prevProps) {
    if (
      prevProps.doc &&
      this.props.doc &&
      prevProps.doc.updatedAt !== this.props.doc.updatedAt
    ) {
      this.setState({ timestamp: Date.now() });
    }
  }

  download(doc) {
    const docType =
      doc &&
      doc.url &&
      doc.url
        .split(".")
        .pop()
        .toLowerCase();

    let downloadUrl;
    if (docType === "pdf") {
      const filename = `${doc.name.replace(
        /[^A-Za-z0-9_\s]/g,
        "_"
      )}.${docType}`;
      downloadUrl = `${config.pdfServiceUrl}/pdf/download?url=${doc.url}&filename=${filename}`;
    } else {
      downloadUrl = `${this.props.baseUrl}/download/${doc.code}`;
    }

    this.saveDocument(`${doc.name}.${docType}`, downloadUrl)
      .then(() => {
        this.props.addActivity({
          documentId: doc.code,
          trialId: doc.trialId,
          action: ACTIONS.DOWNLOAD,
        });
      })
      .catch((e) => {
        this.props.openErrorModal();
      });
  }

  saveDocument(name, href) {
    return new Promise((resolve, reject) => {
      try {
        var downloadLink = document.createElement("a");
        downloadLink.download = name;
        downloadLink.href = href;
        downloadLink.onclick = (e) => {
          document.body.removeChild(e.target);
        };
        downloadLink.style.display = "none";
        document.body.appendChild(downloadLink);
        downloadLink.click();
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  }

  remove(doc) {
    this.props.openLoaderModal();
    this.props
      .removeDoc(doc)
      .then(() => {
        this.setState({ showConfirmModal: false });
        this.props.setSelectedDoc(null);
        if (this.props.onUpdate) this.props.onUpdate();
      })
      .catch((e) => {
        this.props.openErrorModal();
      })
      .then(() => {
        this.props.closeLoaderModal();
      });
  }

  addViewActivity() {
    const { doc } = this.props;
    this.props.addActivity({
      trialId: doc.trialId,
      documentId: doc.code,
      action: ACTIONS.VIEW,
      type: TYPES.DOCUMENTS,
    });
  }

  render() {
    const { doc, showPopover } = this.props;
    const docType =
      doc &&
      doc.url &&
      doc.url
        .split(".")
        .pop()
        .toLowerCase();

    const renderDeleteConfirmation = () => {
      return (
        <ConfirmModal
          isOpen={this.state.showConfirmModal}
          message="Você realmente deseja remover este documento?"
          comment="Ele deixará de ser acessível para os usuários da plataforma caso você o faça."
          confirmButtonLabel="Sim, estou ciente."
          cancelButtonLabel="Cancelar"
          handleConfirm={(e) => {
            this.remove(doc);
          }}
          handleCancel={(e) => {
            this.setState({ showConfirmModal: false });
          }}
        />
      );
    };

    const renderDocumentDetailsOptions = () => {
      const hasStorageLocation =
        doc.physicalStorageLocation !== null &&
        doc.physicalStorageLocation !== undefined &&
        doc.physicalStorageLocation !== "" &&
        doc.physicalStorageLocation !== PENDING_STORAGE;
      const canEdit = auth.isMetodocUser() || (auth.isRegularUser() && !hasStorageLocation);
      return (
        <div>
          <div
            className="doc-details__popover-toggle"
            onClick={this.props.togglePopover}
          >
            ...
          </div>
          <div
            className={
              "doc-details__popover" +
              (showPopover ? " doc-details__popover--active" : "")
            }
          >
            {canEdit && (
              <p
                onClick={(e) => {
                  this.props.togglePopover();
                  this.props.openEditDocumentModal(doc, {
                    onClose: this.props.onUpdate,
                  });
                }}
              >
                {" "}
                Editar{" "}
              </p>
            )}

            <p
              onClick={(e) => {
                this.props.togglePopover();
                this.download(doc);
              }}
            >
              Baixar
            </p>

            {((docType === 'pdf' || docType === 'pdfa') && (auth.isMetodocUser() ||
              (auth.isRegularUser() &&
                doc.physicalStorageLocation === PENDING_STORAGE))) && 
            <p
              onClick={(e) => {
                this.props.togglePopover();
                this.props.openAttachDocumentModal(doc, {
                  onClose: this.props.onUpdate,
                });
              }}
            >
              {" "}
              Vincular{" "}
            </p>}


            {<p
              onClick={(e) => {
                this.props.togglePopover();
                this.props.openInfoDocumentModal(doc, {
                  onClose: this.props.onUpdate,
                });
              }}
            >
              {" "}
              Informações{" "}
            </p>}

            {(auth.isMetodocUser() ||
              (auth.isRegularUser() &&
                doc.physicalStorageLocation === PENDING_STORAGE)) && (
              <p
                onClick={(e) => {
                  this.setState({ showConfirmModal: true });
                  this.props.togglePopover();
                }}
              >
                {" "}
                Excluir{" "}
              </p>
            )}

            {renderDeleteConfirmation()}
          </div>
        </div>
      );
    };

    const renderDocument = () => {
      if (docType === "pdf") {
        return (
          <iframe
            className="doc-details__doc-iframe"
            title="Documento"
            name="doc-iframe"
            id="doc-iframe"
            frameBorder="0"
            border="0"
            cellSpacing="0"
            src={`${doc.url}${config.azureToken}&no-cache=${this.state.timestamp}#toolbar=1&navpanes=0`}
            onLoad={() => this.addViewActivity()}
          ></iframe>
        );
      } else if (docType === "jpg" || docType === "jpeg" || docType === "png") {
        return (
          <img
            alt="Documento"
            className="doc-details__doc-img"
            src={
              doc.url + config.azureToken + `&no-cache=${this.state.timestamp}`
            }
            onLoad={() => this.addViewActivity()}
          />
        );
      } else {
        return (
          <div className="doc-details__view-unavailable-container">
            <img src={docPlaceholderImg} alt="Documento" />
            <p className="doc-details__view-unavailable-text">
              Não é possível pré-visualizar arquivos {docType.toUpperCase()}
            </p>
          </div>
        );
      }
    };

    return (
      <div className="doc-details">
        <div className="doc-details-wrapper">
          {renderDocumentDetailsOptions()}
          <h1 className="doc-details__name">{doc.name}</h1>
          <div className="doc-details__info-row">
            <div className="doc-details__subheader-header">
              <h2 className="doc-details__subheader-header-item">
                Identificador
              </h2>
              <h2 className="doc-details__subheader-header-item">
                Data do documento
              </h2>
              <h2 className="doc-details__subheader-header-item">
                Data de validade
              </h2>
            </div>
            <div className="doc-details__subheader-body">
              <p className="doc-details__subheader-body-item">{doc.code}</p>
              <p className="doc-details__subheader-body-item">
                {utilsService.getFormattedDate(doc.date)}
              </p>
              <p className="doc-details__subheader-body-item">
                {utilsService.getFormattedDate(doc.expiryDate)}
              </p>
            </div>
          </div>
          <div className="doc-details__info-row">
            <div className="doc-details__subheader-header">
              <h2 className="doc-details__subheader-header-item">
                Local de armazenamento
              </h2>
            </div>
            <div className="doc-details__subheader-body">
              <p className="doc-details__subheader-body-item">
                {doc.physicalStorageLocation}
              </p>
            </div>
          </div>
          <h2 className="doc-details__subheader">
            Assunto, Tipo Documental e Observações
          </h2>
          <p className="doc-details__description">{doc.observation}</p>
        </div>
        <div className="doc-details__doc-preview-container">
          {renderDocument()}
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state) => ({
  doc: state.doc.selected,
  baseUrl: state.doc.baseUrl,
  showPopover: state.ui.showDocumentDetailPopover,
});

const mapDispatchToProps = (dispatch) => ({
  togglePopover: () => dispatch(toggleDocumentDetailPopover()),
  openConfirmModal: () => dispatch(openConfirmModal()),
  closeConfirmModal: () => dispatch(closeConfirmModal()),
  openEditDocumentModal: (doc) => dispatch(openEditDocumentModal(doc)),
  openAttachDocumentModal: (doc) => dispatch(openAttachDocumentModal(doc)),
  openInfoDocumentModal: (doc) => dispatch(openInfoDocumentModal(doc)),
  addActivity: (activity) => dispatch(addActivity(activity)),
  setSelectedDoc: (doc) => dispatch(setSelectedDocument(doc)),
  removeDoc: (doc) => dispatch(removeDocument(doc)),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(DocumentDetails);
