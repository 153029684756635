import axios from "axios";

import config from "../config";
import { ACTIONS, TYPES } from "../config/consts";
import getExtensionFromFileName from "../utils/getExtensionFromFileName";

import trialService from "./trial";
import * as azureStorage from "@hobbytraceorg/azure-storage-browser";

const getAll = (params) => {
  return axios.get(`${config.baseUrl}/documents`, {
    params,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const getFileInfo = (code) => {
  return axios.get(`${config.baseUrl}/documents/information/${code}`, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const getUploadAuthorization = (trialId, code, docType) => {
  return axios.get(
    `${config.baseUrl}/documents/storage/authorization?trialId=${trialId}${
      code ? "&code=".concat(code) : ""
    }&type=${docType}`,
    {
      headers: {
        Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
      },
    }
  );
};

const addDocument = (doc) => {
  doc.historic = trialService.getMessage(ACTIONS.CREATE, TYPES.DOCUMENTS);
  return axios.post(`${config.baseUrl}/documents`, doc, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const updateDocument = (doc, additionalInfo) => {
  doc.historic = trialService.getMessage(
    ACTIONS.UPDATE,
    TYPES.DOCUMENTS,
    additionalInfo
  );
  return axios.put(`${config.baseUrl}/documents`, doc, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const updateDocumentLocation = (doc, additionalInfo) => {
  doc.historic = trialService.getMessage(
    ACTIONS.UPDATE,
    TYPES.TRIALS,
    additionalInfo
  );
  return axios.put(`${config.baseUrl}/documents/location`, doc, {
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const removeDocument = (doc) => {
  doc.historic = trialService.getMessage(ACTIONS.DELETE, TYPES.DOCUMENTS);
  return axios.delete(`${config.baseUrl}/documents/${doc.code}`, {
    data: doc,
    headers: {
      Authorization: `Bearer ${sessionStorage.getItem("metodocToken")}`,
    },
  });
};

const convertToPdfa = (file) => {
  const data = new FormData();
  data.append("file", file);

  return axios.post(`${config.pdfServiceUrl}/pdf/convert`, data);
};

const attachPdf = (file, docToUpdate) => {
  const data = new FormData();
  data.append("file", file);
  data.append("docToUpdate", docToUpdate);

  return axios.post(`${config.pdfServiceUrl}/pdf/attach-pdf`, data);
};

const getBase64FromURL = (doc) => {
  return axios.get(doc.url);
};

const uploadFiles = ({ file, trial }) => {
  const docType = getExtensionFromFileName(file.name);
  let contentType = "application/octet-stream";

  if (docType === "pdf") {
    contentType = "application/pdf";
  } else if (docType === "jpg" || docType === "jpeg" || docType === "png") {
    contentType = `image/${docType}`;
  } else {
    contentType = "application/octet-stream";
  }

  return new Promise((resolve, reject) =>
    getUploadAuthorization(trial.id, null, docType).then((action) => {
      let authorization = action.data;

      const storageService = azureStorage.createBlobServiceWithSas(
        authorization.host,
        config.azureToken
      );

      return storageService.createContainerIfNotExists(
        authorization.share,
        (err) => {
          if (err) {
            return reject(err);
          }

          return storageService.createBlockBlobFromBrowserFile(
            authorization.share,
            `${authorization.directory}/${authorization.filename}`,
            file,
            { contentSettings: { contentType } },
            (err) => {
              if (err) {
                return reject(err);
              }
              return resolve(authorization);
            }
          );
        }
      );
    })
  );
};

export default {
  getUploadAuthorization,
  addDocument,
  updateDocument,
  updateDocumentLocation,
  getAll,
  getBase64FromURL,
  removeDocument,
  convertToPdfa,
  attachPdf,
  uploadFiles,
  getFileInfo,
};
