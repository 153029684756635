import userService from '../services/user'


export const CREATE_USER_START = 'CREATE_USER_START'
export const CREATE_USER_ERROR = 'CREATE_USER_ERROR'
export const CREATE_USER_SUCCESS = 'CREATE_USER_SUCCESS'
export const SET_SELECTED_USER = 'SET_SELECTED_USER'
export const UPDATE_USER_START = 'UPDATE_USER_START'
export const UPDATE_USER_ERROR = 'UPDATE_USER_ERROR'
export const UPDATE_USER_SUCCESS = 'UPDATE_USER_SUCCESS'
export const FETCH_USERS_START = 'FETCH_USERS_START'
export const FETCH_USERS_ERROR = 'FETCH_USERS_ERROR'
export const FETCH_USERS_SUCCESS = 'FETCH_USERS_SUCCESS'
export const FETCH_USER_BY_ID_START = 'FETCH_USER_BY_ID_START'
export const FETCH_USER_BY_ID_ERROR = 'FETCH_USER_BY_ID_ERROR'
export const FETCH_USER_BY_ID_SUCCESS = 'FETCH_USER_BY_ID_SUCCESS'
export const FETCH_USER_PROFILE_START = 'FETCH_USER_PROFILE_START'
export const FETCH_USER_PROFILE_ERROR = 'FETCH_USER_PROFILE_ERROR'
export const FETCH_USER_PROFILE_SUCCESS = 'FETCH_USER_PROFILE_SUCCESS'

export const fetchUsersStart = () => ({
  type: FETCH_USERS_START,
})

export const fetchUsersError = () => ({
  type: FETCH_USERS_ERROR,
})

export const fetchUsersSuccess = users => ({
  type: FETCH_USERS_SUCCESS,
  users,
})

export const fetchUsers = () => dispatch => {
  dispatch(fetchUsersStart())

  return userService.getAll().then(response => {
    const users = response.data.map(user => {
      return { ...user, label: user.name, value: user.id }
    })

    return dispatch(fetchUsersSuccess(users))
  })
}

export const createUserStart = () => ({
  type: CREATE_USER_START,
})

export const createUserError = () => ({
  type: CREATE_USER_ERROR,
})

export const createUserSuccess = () => ({
  type: CREATE_USER_SUCCESS,
})

export const createUser = user => dispatch => {
  dispatch(createUserStart())

  return userService.createUser(user).then(() => {
    return dispatch(createUserSuccess())
  })
}

export const setSelectedUser = user => ({
  type: SET_SELECTED_USER,
  user,
})

export const updateUserStart = () => ({
  type: UPDATE_USER_START,
})

export const updateUserError = () => ({
  type: UPDATE_USER_ERROR,
})

export const updateUserSuccess = () => ({
  type: UPDATE_USER_SUCCESS,
})

export const updateUser = (user) => dispatch => {
  dispatch(updateUserStart())

  return userService.updateUser(user).then(() => {
    dispatch(fetchUsers())
    return dispatch(updateUserSuccess())
  })
}

export const fetchUserProfileStart = () => ({
  type: FETCH_USER_PROFILE_START,
})

export const fetchUserProfileError = () => ({
  type: FETCH_USER_PROFILE_ERROR,
})

export const fetchUserProfileSuccess = (profile) => ({
  type: FETCH_USER_PROFILE_SUCCESS,
  profile
})

export const fetchUserProfile = (userId) => dispatch => {
  dispatch(fetchUserProfileStart())

  return userService.getById(userId).then((response) => {
    return dispatch(fetchUserProfileSuccess(response.data))
  })
}
