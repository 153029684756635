import { combineReducers } from 'redux'

import customerReducers from './customer'
import companyReducers from './company'
import trialReducers from './trial'
import uiReducers from './ui'
import userReducers from './user'
import documentReducers from './document'

export default combineReducers({
  customer: customerReducers,
  trial: trialReducers,
  ui: uiReducers,
  user: userReducers,
  company: companyReducers,
  doc: documentReducers,
})
