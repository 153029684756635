import React, { Component, Fragment } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Select from "react-select";
import { connect } from "react-redux";

import EditDocumentModal from "../../components/modals/EditDocumentModal";
import EditDocumentLocationModal from "../../components/modals/EditDocumentLocationModal";
import Arrow from '../../components/Arrow';
import SearchText from '../../components/SearchText';
import Layout from '../../components/Layout';
import Loading from "../../components/Loader";

import { setCurrentCustomerTrials } from "../../actions/trial";
import { fetchDocuments, setSelectedDocument } from "../../actions/document";
import { setSelectedCompany, fetchCompanies } from "../../actions/company";
import { setSelectedCustomer, fetchCustomers, fetchCustomersForUser } from "../../actions/customer";
import {
  openEditDocumentModal,
  openEditDocumentLocationModal
} from "../../actions/ui";

import editIcon from "../../assets/edit.svg";
import editLocationIcon from "../../assets/edit-location.svg";
import "./Documents.scss";
import auth from "../../services/auth";
import utilsService from '../../services/utils'

class Documents extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredCustomers: [],
      updatesListener: {},
      page: 0,
      sort: [{ id: 'name', desc: false }],
      search: "",
      isLoading: false,
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.openEditDocumentModal = this.openEditDocumentModal.bind(this);
    this.fetchDocuments = this.fetchDocuments.bind(this);
    this.openEditDocumentLocationModal = this.openEditDocumentLocationModal.bind(
      this
    );
  }

  componentDidMount() {
    this.getCustomers().then(() => {
      this.getCompanies().then(() => {
        const sortedCompanies = utilsService.sortByName(this.props.companies)
        const firstCompany = sortedCompanies[0]
        this.filterCustomers(this.props.selectedCompany.id || (firstCompany && firstCompany.id), false)
      });
    });
  }

  // ---- Company
  getCompanies() {
    const { selectedCustomer } = this.props

    return this.props.fetchCompanies().then(action => {
      selectedCustomer && selectedCustomer.name 
        ? this.props.setSelectedCompany(action.companies.filter(company => company.id === selectedCustomer.companyId)[0]) 
        : this.props.setSelectedCompany(action.companies[0]);
      return action.companies;
    });
  }

  handleCompanyChange(selectedCompany) {
    this.props.setSelectedCompany(selectedCompany);
    this.props.setSelectedCustomer({});
    this.filterCustomers(selectedCompany.id, true);
  }

  // ---- Customer
  getCustomers() {
    return new Promise((resolve, reject) => {
      this.props.fetchCustomers().then(action => {
        let customers = action.customers;
        let update =
          customers.length > 0 &&
          (!this.props.selectedCustomer || !this.props.selectedCustomer.name);
        if (update) this.props.setSelectedCustomer(customers[0]);
        resolve(customers);
      });
    });
  }

  handleCustomerChange(selectedCustomer) {
    this.props.setSelectedCustomer(selectedCustomer);
    this.fetchDocuments({ selectedCustomer, page: 0 });
  }

  filterCustomers(companyId, isRefresh) {
    this.getAllowedCustomers(sessionStorage.getItem('metodocUserId'), sessionStorage.getItem('metodocCompanyId'))
    .then((result) => {
      const allCustomersForCompany = utilsService.sortByName(result.length ? result : this.props.customers).filter(
        customer =>
          customer && customer.companyId === companyId
      );
      const filterCustomerForCompany = allCustomersForCompany[allCustomersForCompany.findIndex(obj => obj.id === this.props.selectedCustomer.id)];
      if (allCustomersForCompany.length > 0 && isRefresh)
        this.props.setSelectedCustomer(allCustomersForCompany[0]);
      else if (allCustomersForCompany.length > 0 && this.props.selectedCustomer.name && !isRefresh)
        this.props.setSelectedCustomer(filterCustomerForCompany === undefined ? allCustomersForCompany[0] : this.props.selectedCustomer);
      else
        this.props.setSelectedCustomer({ id: 0 });
      this.setState({page: 0});
      this.setState({ allCustomersForCompany }, () => this.fetchDocuments(this.state));
    });
  }

  getAllowedCustomers(userId, companyId) {
    return new Promise((resolve, reject) => {
      fetchCustomersForUser(userId, companyId).then((response) => {
        const allowedCustomers = response.map((x) => ({
          id: x.id,
          name: x.name,
          label: x.name,
          value: x.id,
          company: x.companyId,
          companyId: x.companyId,
        }));
        
        resolve(allowedCustomers);
      });
    });
  }

  // ---- Documents
  fetchDocuments({ selectedCustomer, page, sorted, search }) {

    let sort;
    if (sorted)
    sort = sorted[sorted.length - 1]
    if (this.state.sort && !sort)
    sort = this.state.sort[this.state.sort.length - 1]
    
    const customerId = (selectedCustomer || this.props.selectedCustomer).id
    const nextPage = page === undefined ? this.state.page : page
    const nextSearch = search === undefined ? this.state.search : search

    this.setState({ isLoading: true });
    
    this.props.fetchDocuments({
      customerId,
      page: nextPage + 1,
      sortField: sort.id,
      sortOrder: sort.desc ? 'DESC' : 'ASC',
      search: nextSearch
    }).then(() => {
      this.setState({
        page: nextPage,
        sort: [sort],
        search: nextSearch
      });
    }).finally(() => {
      this.setState({ isLoading: false });
    });
  }

  viewDocument(document) {
    this.props.setSelectedDocument(document);
  }

  openEditDocumentModal(document) {
    this.props.setSelectedDocument(document);
    this.props.openEditDocumentModal();
  }

  openEditDocumentLocationModal(document) {
    this.props.setSelectedDocument(document);
    this.props.openEditDocumentLocationModal();
  }

  render() {
    const columns = [
      {
        Header: "ID",
        accessor: "code",
        width: 100,
        Cell: d => <span>{d.original.code}</span>
      },
      {
        Header: "Nome do Documento",
        accessor: "name",
        Cell: d => (
          <Link
            onClick={e => this.props.setSelectedDocument(d.original)}
            to={`/trials/${d.original.trialId}`}
          >
            {d.original.name}
          </Link>
        )
      },
      {
        Header: "Processo",
        accessor: "trial",
        Cell: d => <span>{d.original.trial.trialName}</span>
      },
      {
        Header: "Data",
        id: "date",
        accessor: "date",
        Cell: d => <span>{utilsService.getFormattedDate(d.value)}</span>
      },
      {
        Header: undefined,
        headerStyle: { cursor: "auto", outline: "none" },
        sortable: false,
        id: "edit-location",
        width: 50,
        Cell: d =>
          auth.isMetodocUser() && (
            <button
              title="Editar local de armazenamento físico"
              className="documents-screen__edit-document-button"
              onClick={() => this.openEditDocumentLocationModal(d.original)}
            >
              <img
                src={editLocationIcon}
                alt="Editar local de armazenamento físico"
              />
            </button>
          )
      },
      {
        Header: undefined,
        headerStyle: { cursor: "auto", outline: "none" },
        sortable: false,
        id: "edit",
        width: 50,
        Cell: d => !auth.isAdminUser() && (
          <button
            title="Editar"
            className="documents-screen__edit-document-button"
            onClick={() => this.openEditDocumentModal(d.original)}
          >
            <img src={editIcon} alt="Editar" />
          </button>
        )
      }
    ];

    return (
      <Layout>
        <div className="documents-screen">
          <div className="documents-screen__searchbar-container">
            <div className="documents-screen__searchbar-selects">
              {auth.isMetodocUser() && (
                <Fragment>
                  <Select
                    className="react-select-container"
                    classNamePrefix="react-select"
                    options={utilsService.sortByName(this.props.companies)}
                    value={this.props.selectedCompany}
                    onChange={this.handleCompanyChange}
                  />
                  <span className="documents-screen__searchbar-separator" />
                </Fragment>
              )}
              <Select
                className="react-select-container"
                classNamePrefix="react-select"
                options={utilsService.sortByName(this.state.allCustomersForCompany)}
                value={this.props.selectedCustomer}
                onChange={this.handleCustomerChange}
              />
            </div>
            <span className="documents-screen__searchbar-separator" />
            <div className="documents-screen__searchbar-wrapper">
              <span className="documents-screen__searchbar-icon" />
              <SearchText
                changed={search => {
                  this.setState({ search })
                  this.fetchDocuments({ page: 0, search })
                }} />
            </div>
          </div>
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <ReactTable
              data={this.props.documents}
              manual
              page={this.state.page}
              pages={this.props.pages}
              loading={this.props.loading}
              onPageChange={page => this.fetchDocuments({ page })}
              onSortedChange={sorted => this.fetchDocuments({ sorted })}
              sorted={this.state.sort}
              columns={columns}
              resizable={false}
              minRows={1}
              defaultPageSize={10}
              showPageSizeOptions={false}
              showPageJump={false}
              showPaginationBottom={true}
              showPaginationTop={true}
              pageText="Página"
              ofText="de"
              noDataText="Nenhum registro encontrado"
              previousText={<Arrow />}
              nextText={<Arrow right />}
            />
          )}
          <EditDocumentModal onClose={() => this.fetchDocuments(this.state)} />
          <EditDocumentLocationModal onClose={() => this.fetchDocuments(this.state)} />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = state => ({
  documents: state.doc.list,
  pages: state.doc.pages,
  loading: state.doc.loading,
  customers: state.customer.list,
  selectedCustomer: state.customer.selected,
  companies: state.company.list,
  selectedCompany: state.company.selected
});

const mapDispatchToProps = dispatch => ({
  fetchDocuments: params => dispatch(fetchDocuments(params)),
  setSelectedDocument: document => dispatch(setSelectedDocument(document)),
  openEditDocumentModal: () => dispatch(openEditDocumentModal()),
  openEditDocumentLocationModal: doc =>
    dispatch(openEditDocumentLocationModal(doc)),
  fetchCustomers: () => dispatch(fetchCustomers()),
  fetchCustomersForUser: () => dispatch(fetchCustomersForUser()),
  setSelectedCustomer: selectedCustomer =>
    dispatch(setSelectedCustomer(selectedCustomer)),
  fetchCompanies: () => dispatch(fetchCompanies()),
  setSelectedCompany: selectedCompany =>
    dispatch(setSelectedCompany(selectedCompany)),
  setCurrentCustomerTrials: trial => dispatch(setCurrentCustomerTrials(trial))
});

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(Documents);
