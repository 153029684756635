import axios from 'axios'
import { Service } from 'axios-middleware'

const handle = () => {
    const service = new Service(axios)
    service.register({
        onRequest(config) { return config },
        onSync(promise) { return promise },
        onResponseError(error) {
            if (error && `${error}`.indexOf('402') > -1) {
                sessionStorage.clear()
                window.location.href = ''
            }
        }
    })
}

export default {
    handle
}
