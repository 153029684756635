import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import Header from '../../components/Header'
import LoaderModal from '../../components/modals/LoaderModal'
import ErrorModal from '../../components/modals/ErrorModal'

import authService from '../../services/auth'

import './Layout.scss'
import { fetchUserProfile } from '../../actions/user';

class Layout extends Component {
  constructor() {
    super()

    this.state = {
      trials: [],
      filteredTrials: [],
    }
  }

  componentDidMount() {
    if (!sessionStorage.getItem('metodocUserId')) {
      this.fetchProfile()
    }
  }

  fetchProfile() {
    return new Promise((resolve, reject) => {
      this.props.getUserProfile(authService.getUserId()).then((action) => {
        let profile = action.profile
        sessionStorage.setItem('metodocFirstAccess', profile.firstAccess)
        sessionStorage.setItem('metodocUsername', profile.name)
        sessionStorage.setItem('metodocUserId', profile.id)
        sessionStorage.setItem('metodocPermission', profile.permission)
        sessionStorage.setItem('metodocCompanyId', profile.companyId)
        resolve(action.profile)
      })
    })
  }

  render() {
    if (!authService.isLoggedIn()) {
      return <Redirect to='/login' />
    } else if (authService.isFirstAccess()) {
      return <Redirect to='/changePassword' />
    } else {
      const username = authService.getName()
      return (
        <div className="layout-screen">
          <Header username={username} />
          {this.props.children}
          <LoaderModal />
          <ErrorModal />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  getUserProfile: userId => dispatch(fetchUserProfile(userId))
})

export default connect(mapStateToProps, mapDispatchToProps)(Layout)
