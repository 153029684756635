import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import { closeLoaderModal } from '../../../actions/ui'
import Loader from '../../Loader'

import './LoaderModal.scss'


Modal.setAppElement('#root')

class LoaderModal extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  render() {
    return (
      <Modal
        overlayClassName="loader-modal-overlay"
        className="loader-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <Loader />
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.ui.isLoaderModalOpen,
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeLoaderModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(LoaderModal)
