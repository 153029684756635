import React from 'react'

import leftArrow from "../../assets/left_arrow.svg";

import './Arrow.scss'

const Arrow = ({ right }) => {
  return (<img className={`pagination__${right ? "right" : "left"}-arrow`} src={leftArrow} alt="Seta" />);
}

export default Arrow
