import React from "react";

import utilsService from "../../services/utils";

import "./DocumentList.scss";

const DocumentList = ({ data, selected, onClick }) => {
  return (
    <ul className="doc-list">
      {data &&
        data.length > 0 &&
        data.map((doc) => {
          return (
            <li
              key={doc.code}
              className={
                "doc-list__item" +
                (selected && selected.code === doc.code
                  ? " doc-list__item--selected"
                  : "")
              }
              onClick={() => onClick(doc)}
            >
              <span className="doc-list__item__identifier">{doc.code}</span>
              <span className="doc-list__item__name">{doc.name}</span>
              <span className="doc-list__item__date">
                {utilsService.getFormattedDate(doc.date)}
              </span>
            </li>
          );
        })}
    </ul>
  );
};

export default DocumentList;
