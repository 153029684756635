import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";

import * as azureStorage from "@hobbytraceorg/azure-storage-browser";

import config from "../../../config";

import utilsService from "../../../services/utils";
import removeExtesionFromFilename from "../../../utils/removeExtensionFromFileName";

import PrimaryButton from "../../PrimaryButton";

import {
  updateDocument,
  setSelectedDocument,
  getDocAuthorization
} from "../../../actions/document";
import {
  closeEditDocumentModal,
  openLoaderModal,
  closeLoaderModal,
  openErrorModal,
} from "../../../actions/ui";

import "./EditDocumentModal.scss";
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

class EditDocumentModal extends Component {
  constructor() {
    super();
    this.state = {
      code: "",
      loadingFile: false,
      name: "",
      file: "",
      filename: "",
      date: "",
      expiryDate: "",
      displayDate: null,
      displayExpiryDate: null,
      observation: "",
      hasFileUpdate: false,
      dateValid: true,
      expiryDateValid: true,
    };

    this.handleAfterOpen = this.handleAfterOpen.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleExpiryDateChange = this.handleExpiryDateChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleObservationChange = this.handleObservationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleAfterClose = this.handleAfterClose.bind(this);
    this.isDateWithinRange = this.isDateWithinRange.bind(this);
  }

  handleAfterOpen() {
    this.setState(
      {
        ...this.props.docToUpdate,
        displayDate: undefined,
        displayExpiryDate: undefined,
        dateValid: true,
        expiryDateValid: true,
      },
      (state) => {
        this.setState({
          displayDate: new Date(this.state.date),
          displayExpiryDate: this.state.expiryDate
            ? new Date(this.state.expiryDate)
            : "",
            dateValid: true,
            expiryDateValid: true,
        });
      }
    );
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleDateChange(e) {
    if (e) {
      const parsedDate = new Date(e);
      const isValidDate = this.isDateWithinRange(parsedDate.toISOString().split('T')[0]);

      this.setState({ dateValid: isValidDate });
      this.setState({ date: e.toISOString(), displayDate: e });
    } else {
      this.setState({ date: '', displayDate: null });
      this.setState({ dateValid: false });
    }
  }

  handleExpiryDateChange(e) {
    if (!e) {
      this.setState({ expiryDate: "", displayExpiryDate: null });
    } else {
      const parsedDate = new Date(e);
      const isValidDate = this.isDateWithinRange(parsedDate.toISOString().split('T')[0]);
      
      this.setState({ expiryDateValid: isValidDate });
      this.setState({ expiryDate: e.toISOString(), displayExpiryDate: e });
    }
  }

  isDateWithinRange(parsedDate) {
    const lastDate = new Date('2100-01-01');
    const earliestDate = new Date('1900-01-01');
    const inputDate = new Date(parsedDate);

    return inputDate >= earliestDate && inputDate <= lastDate;
  }

  handleFileChange(e) {
    const file = e.target.files[0];
    const name = removeExtesionFromFilename(file.name);

    const date = new Date();

    if (utilsService.verifyDocument(file)) {
      this.setState({
        file,
        filename: file.name,
        name,
        displayDate: date,
        date: date.toISOString(),
      });
    }
  }

  handleObservationChange(e) {
    this.setState({ observation: e.target.value });
  }

  uploadFile(doc) {
    const docType = doc.file.name
      .split(".")
      .pop()
      .toLowerCase();
    let contentType = "application/octet-stream";

    if (docType === "pdf") {
      contentType = "application/pdf";
    } else if (docType === "jpg" || docType === "jpeg" || docType === "png") {
      contentType = `image/${docType}`;
    } else {
      contentType = "application/octet-stream";
    }

    return new Promise((resolve, reject) => {
      this.props
        .getAuthorization(doc.trialId, doc.code, docType)
        .then((action) => {
          let authorization = action.authorization;
          const storageService = azureStorage.createBlobServiceWithSas(
            authorization.host,
            config.azureToken
          );
          storageService.createContainerIfNotExists(
            authorization.share,
            (err) => {
              if (err) return reject(err);
              storageService.createBlockBlobFromBrowserFile(
                authorization.share,
                `${authorization.directory}/${authorization.filename}`,
                doc.file,
                { contentSettings: { contentType } },
                (err) => {
                  if (err) return reject(err);
                  resolve(authorization);
                }
              );
            }
          );
        })
        .catch((e) => {
          this.props.closeLoaderModal();
          this.props.openErrorModal();
        });
    });
  }

  updateDoc(upload) {
    const originalDoc = {
      name: this.props.docToUpdate.name,
      date: new Date(this.props.docToUpdate.date).toLocaleDateString("pt-BR"),
      expiryDate: this.props.docToUpdate.expiryDate
        ? new Date(this.props.docToUpdate.expiryDate).toLocaleDateString(
            "pt-BR"
          )
        : "",
      observation: this.props.docToUpdate.observation,
    };

    const updates = {
      name: this.state.name,
      date: new Date(this.state.date).toLocaleDateString("pt-BR"),
      expiryDate: this.state.expiryDate
        ? new Date(this.state.expiryDate).toLocaleDateString("pt-BR")
        : "",
      observation: this.state.observation,
    };

    if (this.state.file) updates.file = this.state.filename;

    const additionalInfo = utilsService.formatDiff(originalDoc, updates);

    if (!this.state.file && !additionalInfo) {
      this.props.closeModal();
      if (this.props.onClose) this.props.onClose();
      this.props.closeLoaderModal();
    } else {
      const dataToUpdate = { ...this.state, file: undefined };

      if (upload) {
        dataToUpdate.url = upload.url;
      }

      if (this.state.expiryDate === "") {
        dataToUpdate.expiryDate = null;
      }

      this.props
        .updateDocument(dataToUpdate, additionalInfo)
        .then((action) => {
          this.props.closeModal();
          this.props.setSelectedDoc(action.updatedDoc);
          if (this.props.onClose) this.props.onClose();
        })
        .catch((e) => {
          this.props.openErrorModal();
        })
        .then(() => {
          this.props.closeLoaderModal();
        });
    }
  }

  handleSubmit() {
    this.props.openLoaderModal();
    if (this.state.file) {
      this.uploadFile({
        trialId: this.state.trialId,
        code: this.state.code,
        file: this.state.file,
      })
        .then((upload) => {
          this.updateDoc(upload);
        })
        .catch((e) => {
          this.props.openErrorModal();
        });
    } else {
      this.updateDoc();
    }
  }

  handleAfterClose() {
    this.setState({
      code: "",
      loadingFile: false,
      name: "",
      file: "",
      filename: "",
      date: "",
      expiryDate: "",
      displayDate: null,
      displayExpiryDate: null,
      dateValid: true,
      expiryDateValid: true,
      observation: "",
      hasFileUpdate: false,
    });
  }

  render() {
    const { name, displayDate, file, dateValid, expiryDateValid } = this.state;
    const isValidForm = name && name.length > 0 && (displayDate && dateValid);
    const isBigFile = file && file.size && file.size / 1024 / 1024 > 9;
    return (
      <Modal
        overlayClassName="add-doc-modal-overlay"
        className="add-doc-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterClose={this.handleAfterClose}
        onAfterOpen={this.handleAfterOpen}
      >
        <button
          className="add-doc-modal__close"
          onClick={this.props.closeModal}
        ></button>
        <h1 className="add-doc-modal__heading">Editar documento</h1>
        <form className="add-doc-modal__form">
          <label className="add-doc-modal__form__custom-file-input">
            {this.state.loadingFile
              ? "Carregando arquivo..."
              : this.state.filename !== ""
              ? this.state.filename
              : "Alterar arquivo"}
            <span className="add-doc-modal__form__custom-file-input__icon"></span>
            <input
              className="add-doc-modal__form__file-input"
              type="file"
              accept="image/jpeg,image/jpg,image/png,application/pdf,.re,.pfx,.xml,.xls,.xlsx,.xlsm,.doc,.docx,.docm"
              onChange={this.handleFileChange}
            />
          </label>
          {isBigFile && (
            <div className="add-doc-modal__form__input-warning">
              Arquivo com tamanho superior a 10 MB, pode demorar dependendo da
              sua conexão de internet.
            </div>
          )}
          <input
            className="add-doc-modal__form__input"
            type="text"
            onChange={this.handleNameChange}
            value={this.state.name}
            placeholder="Nome do documento (máx. 250 caracteres)"
            maxLength="250"
            onKeyDown={(event) => {
              if (event.key === "Enter" && isValidForm) {
                this.handleSubmit();
              }
            }}
          />
          <DatePicker
            className={`add-doc-modal__form__input ${dateValid ? '' : 'error-input'}`}
            onChange={this.handleDateChange}
            selected={this.state.displayDate}
            placeholderText="Data do documento"
            dateFormat="dd/MM/yyyy"
            customInput={
              <MaskedTextInput
                type="text"
                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              />
            }
            onKeyDown={(event) => {
              if (event.key === "Enter" && isValidForm) {
                this.handleSubmit();
              }
            }}
          />

          <DatePicker
            className={`add-doc-modal__form__input ${expiryDateValid ? '' : 'error-input'}`}
            onChange={this.handleExpiryDateChange}
            selected={this.state.displayExpiryDate}
            placeholderText="Data de validade"
            dateFormat="dd/MM/yyyy"
            customInput={
              <MaskedTextInput
                type="text"
                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              />
            }
            onKeyDown={(event) => {
              if (event.key === "Enter" && isValidForm) {
                this.handleSubmit();
              }
            }}
          />
          <textarea
            className="add-doc-modal__form__textarea"
            onChange={this.handleObservationChange}
            value={this.state.observation}
            placeholder="Assunto, Tipo Documental e Observações (máx. 250 caracteres)"
            maxLength="250"
            onKeyDown={(event) => {
              if (event.key === "Enter" && isValidForm) {
                this.handleSubmit();
              }
            }}
          ></textarea>
          <PrimaryButton
            fullWidth
            onClick={this.handleSubmit}
            disabled={!isValidForm}
          >
            Salvar
          </PrimaryButton>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.ui.isEditDocumentModalOpen,
  docToUpdate: state.doc.selected,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => { 
    dispatch(closeEditDocumentModal())
  },
  updateDocument: (doc, additionalInfo) =>
    dispatch(updateDocument(doc, additionalInfo)),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
  setSelectedDoc: (doc) => dispatch(setSelectedDocument(doc)),
  getAuthorization: (trialId, code, docType) =>
    dispatch(getDocAuthorization(trialId, code, docType))
});

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentModal);
