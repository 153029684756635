import React, { Component } from "react";
import { Link } from "react-router-dom";
import ReactTable from "react-table";
import Select from "react-select";
import { connect } from "react-redux";
import { debounce } from "lodash";

import Avatar from "../../components/Avatar";
import EditTrialModal from "../../components/modals/EditTrialModal";
import Arrow from "../../components/Arrow";
import Layout from "../../components/Layout";
import Loading from "../../components/Loader";

import { setSelectedCustomer, fetchCustomers, fetchCustomersForUser } from "../../actions/customer";

import {
  fetchTrials,
  setSelectedTrial,
  setCurrentCustomerTrials,
  setCurrentTrialsPage
} from "../../actions/trial";
import { openEditTrialModal } from "../../actions/ui";

import editIcon from "../../assets/edit.svg";
import "./Trials.scss";
import auth from "../../services/auth";
import utilsService from "../../services/utils";
import { STATUS } from "../../config/consts";

class Trials extends Component {
  constructor(props) {
    super(props);

    this.state = {
      filteredTrials: [],
      sort: [{ id: "name", desc: false }],
      search: "",
      isLoading: false,
      allowedCustomers: [],
    };

    this.handleCustomerChange = this.handleCustomerChange.bind(this);
    this.openEditTrialModal = this.openEditTrialModal.bind(this);
  }

  componentDidMount() {
    this.getAllowedCustomers(sessionStorage.getItem('metodocUserId'), sessionStorage.getItem('metodocCompanyId'));
    this.refreshCustomers();
    this.refreshTrials();
  }

  refreshTrials() {
    this.fetchTrials({
      customerId: this.props.selectedCustomer.id,
      page: this.props.currentPage,
      sort: this.state.sort,
      search: this.state.search,
    });
  }

  findCompanyByCompanyId(customers, companyId) {
    for (let i = 0; i < customers.length; i++) {
        if (customers[i].companyId === companyId) {
          return customers[i];
        }
    }
    return [];
  }

  refreshCustomers() {
    this.props.fetchCustomers(0).then((action) => {
      let customers = action.customers;
      let updateCustomer =
        (!this.props.selectedCustomer || !this.props.selectedCustomer.name) &&
        customers.length > 0;

      if (updateCustomer) {
        const companyId = parseInt(sessionStorage.getItem('metodocCompanyId'), 10);
        if(companyId === 1) {
          this.handleCustomerChange(utilsService.sortByName(customers)[0]);
        } else {
          this.handleCustomerChange(this.findCompanyByCompanyId(utilsService.sortByName(customers), companyId, 10));
        }
      }
    });
  }

  getAllowedCustomers(userId, companyId) {
    fetchCustomersForUser(userId, companyId).then((response) => {
      const allowedCustomers = response.map((x) => ({
        id: x.id,
        name: x.name,
        label: x.name,
        value: x.id,
        company: x.companyId,
        companyId: x.companyId,
      }));
      this.setState({ allowedCustomers: response.length > 0 ? allowedCustomers : this.props.customers});
    });
  }

  handleCustomerChange(selectedCustomer) {
    this.props.setSelectedCustomer(selectedCustomer);
    this.setState({ selectedCustomer });
    this.fetchTrials({ customerId: selectedCustomer.id });
  }

  fetchTrials({ customerId, page, sort, search }) {
    const nextPage = page || 0;
    this.setState({ isLoading: true });

    this.props
      .fetchTrials({ customerId, page: nextPage + 1, sort, search })
      .then(() => {
        this.props.setCurrentCustomerTrials(this.props.trials);
        this.props.setCurrentTrialsPage(nextPage);
      })
      .finally(() => {
        this.setState({ isLoading: false });
      });
  }

  openEditTrialModal(trial) {
    this.props.setSelectedTrial(trial);
    this.props.openEditTrialModal();
  }

  debouncedSearch = debounce((value) => {
    this.fetchTrials({
      customerId: this.props.selectedCustomer.id,
      search: value,
    });
  }, 500);

  handleSearch({ target: { value } }) {
    this.setState({ search: value });

    if(value.length > 2 || value.length === 0)
      this.debouncedSearch(value);
  }

  render() {
    const renderAvatars = (users) => {
      return (
        <div className="avatars-list">
          {users.map((user) => {
            return (
              <Avatar key={user.id} small withSpacing username={user.name} />
            );
          })}
        </div>
      );
    };

    const columns = [
      {
        Header: "Nome do Processo",
        accessor: "name",
        id: "name",
        Cell: (d) => (
          <Link
            to={`/trials/${d.original.id}`}
            className={`trials-screen__table__trial-link ${
              d.original.status === STATUS.PRIVATE
                ? "trials-screen__table__private-icon"
                : ""
            }`}
          >
            {d.original.name}
          </Link>
        ),
      },
      {
        Header: "Membros",
        id: "members",
        accessor: (d) => renderAvatars(d.users),
        sortable: true,
      },
      {
        Header: "Data",
        id: "createdAt",
        accessor: "createdAt",
        Cell: (d) => <span>{utilsService.getFormattedDate(d.value)}</span>,
      },
      {
        Header: undefined,
        headerStyle: { cursor: "auto", outline: "none" },
        sortable: false,
        id: "edit",
        Cell: (d) =>
          !auth.isAdminUser() && (
            <button
              title="Editar"
              className="trials-screen__edit-trial-button"
              onClick={() => this.openEditTrialModal(d.original)}
            >
              <img src={editIcon} alt="Editar" />
            </button>
          ),
      },
    ];

    const selectedAllowedUser = this.state.allowedCustomers[this.state.allowedCustomers.findIndex(obj => obj.id === this.props.selectedCustomer.id)];
    
    return (
      <Layout>
        <div className="trials-screen">
          <div className="trials-screen__searchbar-container">
            <Select
              className="react-select-container"
              classNamePrefix="react-select"
              options={utilsService.sortByName(this.state.allowedCustomers.length ? this.state.allowedCustomers : this.props.customers)}
              value={this.state.allowedCustomers.length ? selectedAllowedUser ? selectedAllowedUser : this.state.allowedCustomers[0] : this.props.selectedCustomer}
              onChange={this.handleCustomerChange}
            />
            <span className="trials-screen__searchbar-separator" />
            <div className="trials-screen__searchbar-wrapper">
              <span className="trials-screen__searchbar-icon" />
              <input
                className="trials-screen__searchbar"
                type="text"
                placeholder="Busca por processo pelo seu nome"
                onChange={(e) => this.handleSearch(e)}
                value={this.state.search}
                onBlur={() => this.props.setCurrentTrialsPage(0)}
              />
            </div>
          </div>
          {this.state.isLoading ? (
            <Loading />
          ) : (
            <ReactTable
              data={this.props.trials}
              page={this.props.currentPage}
              pages={this.props.pages}
              loading={this.state.loading}
              onPageChange={(page) => {
                  this.fetchTrials({
                    customerId: this.props.selectedCustomer.id,
                    page,
                    search: this.state.search,
                    sort: this.state.sort,
                  })
                }
              }
              onSortedChange={(sort) => {
                this.fetchTrials({
                  customerId: this.props.selectedCustomer.id,
                  page: this.props.currentPage,
                  sort,
                  search: this.state.search,
                });
                this.setState({ sort });
              }}
              columns={columns}
              resizable={false}
              minRows={1}
              defaultPageSize={20}
              showPageSizeOptions={false}
              showPageJump={false}
              showPaginationBottom={true}
              showPaginationTop={true}
              pageText="Página"
              ofText="de"
              noDataText="Nenhum registro encontrado"
              previousText={<Arrow />}
              nextText={<Arrow right />}
              sorted={this.state.sort}
              manual
            />
          )}

          <EditTrialModal
            onClose={(e) => {
              this.refresh();
            }}
          />
        </div>
      </Layout>
    );
  }
}

const mapStateToProps = (state) => ({
  trials: state.trial.list,
  loading: state.trial.loading,
  pages: state.trial.trialsPages,
  currentPage: state.trial.currentTrialPage,
  customers: state.customer.list,
  selectedCustomer: state.customer.selected,
});

const mapDispatchToProps = (dispatch) => ({
  setSelectedCustomer: (selectedCustomer) =>
    dispatch(setSelectedCustomer(selectedCustomer)),
  fetchCustomers: () => dispatch(fetchCustomers()),
  fetchCustomersForUser: () => dispatch(fetchCustomersForUser()),
  fetchTrials: ({ customerId, page, sort, search }) =>
    dispatch(fetchTrials({ customerId, page, sort, search })),
  setSelectedTrial: (trial) => dispatch(setSelectedTrial(trial)),
  setCurrentCustomerTrials: (trial) =>
    dispatch(setCurrentCustomerTrials(trial)),
  openEditTrialModal: () => dispatch(openEditTrialModal()),
  setCurrentTrialsPage: (selectedPage) =>
    dispatch(setCurrentTrialsPage(selectedPage))
});

export default connect(mapStateToProps, mapDispatchToProps)(Trials);
