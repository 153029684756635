import React from 'react'

import './SecondaryButton.scss'

const SecondaryButton = ({ children, onClick, fullWidth }) => {
  return (
    <button className={ 'btn--secondary' + (fullWidth ? ' btn--secondary--full' : '') } onClick={ onClick }>{ children }</button>
  )
}

export default SecondaryButton
