import diff from 'deep-diff'

const getInitialsFromUsername = username => {
  if (username === null || username === '' || username.toLowerCase() === 'metodoc') {
    return 'MD'
  } else {
    const splitName = username.split(' ')
    if (splitName.length < 2) {
      return splitName[0].trim().substring(0, 2).toUpperCase();
    } else {
      const firstLetter = splitName[0].trim().charAt(0).toUpperCase()
      const secondLetter = splitName[1].trim().charAt(0).toUpperCase()
      return `${firstLetter}${secondLetter}`
    }
  }
}

const formatDiff = (original, updated, users) => {
  const changes = diff(original, updated)
  const result = []

  if (changes)
    changes.forEach(change => {
      if (change.kind === 'E' && change.path[0] !== "users")
        result.push(`${translate(change.path[0])} de "${translate(change.lhs)}" para "${translate(change.rhs)}"`)
      else if (change.kind === 'N')
        result.push(`${translate(change.path[0])} para "${translate(change.rhs)}"`)
    })

  if (users) {
    original.users.filter(u => !updated.users.includes(u)).forEach(uId => {
      result.push(`removeu o usuário "${users.find(u => u.id === uId).name}"`)
    })

    updated.users.filter(u => !original.users.includes(u)).forEach(uId => {
      result.push(`adicionou o usuário "${users.find(u => u.id === uId).name}"`)
    })
  }

  return result.join(", ")
}

const translate = str => {
  if (!str) {
    return ''
  }

  return translateMap[str] || str
}

const translateMap = {
  name: 'o nome',
  status: 'o status',
  description: 'a descrição',
  date: 'a data',
  expiryDate: 'a data de validade',
  observation: 'a observação',
  file: 'o arquivo',
  physicalStorageLocation: 'o local de armazenamento',
  public: 'Público',
  private: 'Privado'
}

const getFormattedDate = stringdate => {
  if (!stringdate) {
    return ''
  }

  const date = new Date(stringdate)
  const day = date.getDate()
  const month = date.getMonth() + 1
  const year = date.getFullYear()

  return `${day}/${month}/${year}`
}

const acceptedFiles = ['.pdf', '.jpg', '.jpeg', '.png', '.re', '.pfx', '.xml', '.xls', '.xlsx' , '.xlsm', '.doc', '.docx', '.docm']

const verifyDocument = file => {
  if (file && file.name && !acceptedFiles.find(f => file.name.toLowerCase().endsWith(f))) {
    alert(`Formato de arquivo inválido, os únicos aceitos são (${acceptedFiles.join(', ')}).`)
    return false
  }
  return true
}

const sortByName = array => {
  if (!array || array.length === 0) return array
  return array.sort((a, b) => {
    const first = a.name.toUpperCase()
    const second = b.name.toUpperCase()
    if (first > second) return 1
    if (first < second) return -1
    return 0
  })
}

const convertBase64PdfToBlob = content => {
  const sliceSize = 512
  const byteCharacters = window.atob(content)
  const byteArrays = []

  for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
    const slice = byteCharacters.slice(offset, offset + sliceSize)
    const byteNumbers = new Array(slice.length)

    for (let i = 0; i < slice.length; i++) {
      byteNumbers[i] = slice.charCodeAt(i)
    }

    const byteArray = new Uint8Array(byteNumbers)
    byteArrays.push(byteArray)
  }

  const blob = new Blob(byteArrays, { type: 'application/pdf' })
  return blob
}

export default { getInitialsFromUsername, formatDiff, getFormattedDate, verifyDocument, sortByName, convertBase64PdfToBlob }
