import React, { Component } from 'react'
import ReactTable from 'react-table'
import { connect } from 'react-redux'

import EditUserModal from '../../components/modals/EditUserModal'

import { fetchUsers, setSelectedUser, updateUser } from '../../actions/user'
import { openEditUserModal, openNewUserModal, openLoaderModal, closeLoaderModal, openErrorModal } from '../../actions/ui'

import editIcon from '../../assets/edit.svg'
import checkedIcon from '../../assets/checkbox-checked.svg'
import uncheckedIcon from '../../assets/checkbox-unchecked.svg'
import PrimaryButton from '../../components/PrimaryButton';
import NewUserModal from '../../components/modals/NewUserModal';
import Arrow from '../../components/Arrow';

import './UserList.scss'
import ConfirmModal from '../modals/ConfirmModal';

class UserList extends Component {
  constructor() {
    super()
    this.state = {
      allUsers: [],
      filteredUsers: [], 
      page: undefined,
      sort: [{ id: 'name', desc: false }],
      search: ''
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    this.props.fetchUsers().then((action) => {
      this.setState({ allUsers: action.users, filteredUsers: action.users })
    })
  }

  handleSearch(value) {
    if (value !== '') {
      const filteredUsers = this.state.allUsers.filter(user => {
        return user.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
      this.setState({ filteredUsers })
    } else
      this.setState({ filteredUsers: this.state.allUsers })

    this.setState({ search: value, page: 0 })
  }

  openEditUserModal(user) {
    this.props.setSelectedUser(user)
    this.props.openEditUserModal()
  }

  toggleUser(user) {
    let toggledUser = { ...user, isActive: !user.isActive }
    this.props.openLoaderModal()
    this.props.updateUser(toggledUser).then(() => {
      this.refresh()
      this.setState({ showToggleModal: false })
    }).catch((e) => {
      this.props.openErrorModal()
    }).then(() => {
      this.props.closeLoaderModal()
    })
  }

  render() {
    const renderStatusButton = (user) =>
      <button
        title={user.isActive ? 'Desativar' : 'Ativar'}
        className="user-list__toggle-user-status-button"
        onClick={() => {
          this.props.setSelectedUser(user);
          if (user.isActive) this.setState({ showToggleModal: true })
          else this.toggleUser(user)
        }}>
        <img src={user.isActive ? checkedIcon : uncheckedIcon} alt={user.isActive ? 'Desativar' : 'Ativar'} />
      </button>

    const columns = [
      { Header: 'Ativo', id: 'toggle', width: 100, Cell: d => renderStatusButton(d.original) },
      { Header: 'Nome do Usuário', accessor: 'name', maxWidth: 500, Cell: (d) => <div>{d.original.name}</div> },
      { Header: 'E-mail', accessor: 'email', Cell: (d) => <div>{d.original.email}</div> },
      { Header: '', id: 'edit', width: 50, Cell: d => <button title="Editar" className="user-list__edit-user-button" onClick={() => this.openEditUserModal(d.original)}><img src={editIcon} alt="Editar" /></button> },
    ]

    return (
      <div className="user-list">
        <div className="user-list__searchbar-container">
          <div className="user-list__searchbar-wrapper">
            <span className="user-list__searchbar-icon"></span>
            <input
              className="user-list__searchbar"
              type="text"
              placeholder="Buscar usuário pelo nome"
              onChange={e => this.handleSearch(e.target.value)}
              value={this.state.search}
              onBlur={() => this.setState({ page: undefined })}
            />
          </div>
        </div>
        <div className="user-list__actionbuttons-container">
          <PrimaryButton onClick={(e) => this.props.openNewUserModal()}>
            Adicionar usuário
          </PrimaryButton>
        </div>
        <ReactTable data={this.state.filteredUsers} columns={columns}
          resizable={false} minRows={1} defaultPageSize={10}
          showPageSizeOptions={false} showPageJump={false} page={this.state.page}
          sorted={this.state.sort} onSortedChange={sort => this.setState({ sort })} 
          pageText="Página" ofText="de" noDataText="Nenhum registro encontrado" 
          previousText={<Arrow />} nextText={<Arrow right />}
        />
        <EditUserModal onClose={(e) => { this.refresh() }} />
        <NewUserModal onClose={(e) => { this.refresh() }} />
        <ConfirmModal
          isOpen={this.state.showToggleModal}
          message={`Você realmente deseja desativar o usuário?`}
          comment='Ele(a) terá seu acesso à plataforma interrompido caso você o faça.'
          confirmButtonLabel='Sim, estou ciente.'
          cancelButtonLabel='Cancelar'
          handleConfirm={(e) => { this.toggleUser(this.props.selectedUser) }}
          handleCancel={(e) => { this.setState({ showToggleModal: false }) }}
        />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  users: state.user.list,
  selectedUser: state.user.selected
})

const mapDispatchToProps = dispatch => ({
  fetchUsers: () => dispatch(fetchUsers()),
  setSelectedUser: user => dispatch(setSelectedUser(user)),
  openEditUserModal: () => dispatch(openEditUserModal()),
  openNewUserModal: () => dispatch(openNewUserModal()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
  updateUser: user => dispatch(updateUser(user)),
})

export default connect(mapStateToProps, mapDispatchToProps)(UserList)