import React, { Component } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroller";
import * as lodash from "lodash";

import Avatar from "../../components/Avatar";
import DocumentList from "../../components/DocumentList";
import ActivityList from "../../components/ActivityList";
import PrimaryButton from "../../components/PrimaryButton";
import AddUserModal from "../../components/modals/AddUserModal";
import EditTrialModal from "../../components/modals/EditTrialModal";
import AddDocumentModal from "../../components/modals/AddDocumentModal";
import EditDocumentModal from "../../components/modals/EditDocumentModal";
import AttachDocumentModal from "../../components/modals/AttachDocumentModal";
import InfoDocumentModal from "../../components/modals/InfoDocumentModal";
import EditDocumentLocationModal from "../../components/modals/EditDocumentLocationModal";

import DocumentDetails from "../DocumentDetails";
import ConfirmModal from "../../components/modals/ConfirmModal";
import SearchText from "../../components/SearchText";
import Loader from "../../components/Loader";
import Layout from "../../components/Layout";
import UploadZone from "../../components/UploadZone";

import { fetchUsers } from "../../actions/user";
import {
  fetchTrialById,
  fetchActivity,
  setSelectedTrial,
  removeTrial,
  fetchTrialDocumentsById,
  addActivity,
  downloadAllDocuments,
} from "../../actions/trial";
import {
  setSelectedDocument,
  setIsAddDocFromTrialDetails,
  fetchUploadZone,
  setShowUploadZoneFiles,
  cleanSelectedDocument,
} from "../../actions/document";
import {
  openAddDocumentModal,
  openAddUserModal,
  openEditTrialModal,
  toggleTrialDetailPopover,
  openConfirmModal,
  closeConfirmModal,
  openLoaderModal,
  closeLoaderModal,
  openErrorModal,
  openEditDocumentLocationModal,
} from "../../actions/ui";

import lock from "../../assets/lock.svg";

import "./TrialDetails.scss";
import auth from "../../services/auth";
import config from "../../config";
import { ACTIONS, TYPES, STATUS } from "../../config/consts";
import FilesListUpload from "./FilesListUpload";

class TrialDetails extends Component {
  constructor() {
    super();

    this.state = {
      docPage: 1,
      activityPage: 1,
      search: "",
      updatesListener: {},
      addNewDoc: false,
    };

    this.fetchDocuments = this.fetchDocuments.bind(this);
    this.fetchActivities = this.fetchActivities.bind(this);
    this.getTrial = this.getTrial.bind(this);
    this.onDocumentSelect = this.onDocumentSelect.bind(this);
    this.downloadAllDocuments = this.downloadAllDocuments.bind(this);
  }

  componentDidMount() {
    this.refresh();
    this.props.setIsAddDocFromTrialDetails(false);
    this.props.fetchUploadZone();
  }

  componentDidUpdate(prevProps) {
    const isTrialUpdate = !lodash.isEqual(this.props.trial, prevProps.trial);

    if (isTrialUpdate) {
      this.refreshActivities();
    }
  }

  refresh(docPage) {
    return new Promise((resolve, reject) => {
      this.getTrial().then((res) => {
        this.fetchDocuments({ page: docPage || 1 });
        this.refreshActivities({ page: 1 });
        resolve(res);
      });
    });
  }

  refreshActivities() {
    this.fetchActivities({ page: 1 });
  }

  handleChildrenUpdate(docPage) {
    this.refresh(docPage);
  }

  getTrial() {
    return new Promise((resolve, reject) => {
      const { fetchTrialById, match } = this.props;
      fetchTrialById(match.params.id).then((action) => {
        const isAllowed =
          auth.isMetodocUser() ||
          action.trial.status === STATUS.PUBLIC ||
          action.trial.users.filter(
            (u) => `${u.id}` === `${auth.getUserId()}`
          )[0];
        if (!isAllowed) {
          reject();
          this.props.history.goBack();
        } else {
          this.props.addActivity({
            trialId: action.trial.id,
            action: ACTIONS.VIEW,
            type: TYPES.TRIALS,
          });
          resolve(action);
        }
      });
    });
  }

  fetchDocuments({ page, search }) {
    const {
      fetchTrialDocumentsById,
      setSelectedDoc,
      match,
      docsLoading,
      setIsAddDocFromTrialDetails,
    } = this.props;

    if (!docsLoading) {
      const nextPage = page === undefined ? this.state.docPage : page;
      fetchTrialDocumentsById({
        trialId: match.params.id,
        page: nextPage,
        search: search === undefined ? this.state.search : search,
      }).then(() => {
        if (this.props.currentDoc) {
          if(this.props.currentDoc.trialId !== +match.params.id) {
            this.props.cleanSelectedDocument();
          }
        }
        else {
          if (!this.props.currentDoc && this.props.docs.length > 0) {
            setSelectedDoc(this.props.docs[0]);
          } else if (this.props.isAddDocFromTrialDetails) {
            setSelectedDoc(this.props.docs[this.props.docs.length - 1]);
            setIsAddDocFromTrialDetails(false);
          }
        }
      });

      this.setState({ docPage: nextPage + 1 });
    }
  }

  fetchActivities({ page }) {
    const { fetchActivity, activitiesLoading, match } = this.props;

    if (!activitiesLoading) {
      const nextPage = page === undefined ? this.state.activityPage : page;
      fetchActivity({ trialId: match.params.id, page: nextPage });
      this.setState({ activityPage: nextPage + 1 });
    }
  }

  remove(trial) {
    this.props.openLoaderModal();
    this.props
      .removeTrial(trial)
      .then(() => {
        this.setState({ showConfirmModal: false });
        if (this.props.onUpdate) this.props.onUpdate();
        this.props.history.goBack();
      })
      .catch((e) => {
        this.props.openErrorModal();
      })
      .then(() => {
        this.props.closeLoaderModal();
      });
  }

  downloadAllDocuments(trial) {
    this.props.openLoaderModal();
    this.props
      .downloadAllDocuments(trial)
      .then(() => {
        this.setState({ showDownloadAllDocumentsModal: false });
        this.props.addActivity({
          trialId: trial.id,
          action: ACTIONS.DOWNLOAD_ALL,
        });
        if (this.props.onUpdate) this.props.onUpdate();
      })
      .catch((e) => {
        this.props.openErrorModal();
      })
      .then(() => {
        this.props.closeLoaderModal();
      });
  }

  onDocumentSelect(doc) {
    if (window.innerWidth <= 1024) {
      this.props.addActivity({
        documentId: doc.code,
        trialId: doc.trialId,
        action: ACTIONS.DOWNLOAD,
      });
      window.open(
        doc.url + config.azureToken + `&no-cache=${Date.now()}`,
        "_blank"
      );
    }
    this.props.setSelectedDoc(doc);
  }

  componentWillUnmount() {
    this.props.setSelectedTrial(null);
    this.props.setSelectedDoc(null);
  }

  render() {
    const {
      trial,
      showPopover,
      docsPages,
      docsLoading,
      activitiesPages,
      activitiesLoading,
      currentDoc,
      docs,
    } = this.props;
    const isDocLastPage = docsPages <= this.state.docPage - 1;
    const isActivitityLastPage = activitiesPages <= this.state.activityPage - 1;

    const renderMembers = (users) => {
      return (
        <ul className="trial-details-screen__member-list">
          {users.map((user) => (
            <li key={user.id}>
              <Avatar small withSpacing username={user.name} />
            </li>
          ))}

          {
            !auth.isAdminUser() && (
            <li>
              <div
                className="trial-details-screen__add-member-button"
                onClick={this.props.openAddUserModal}
              >
                +
              </div>
            </li>
          )}
        </ul>
      );
    };

    if (trial) {
      const renderDeleteConfirmation = () => {
        return (
          <ConfirmModal
            isOpen={this.state.showConfirmModal}
            message={`Você realmente deseja remover o processo "${trial.name}"?`}
            comment="Ele deixará de ser acessível para os usuários da plataforma caso você o faça."
            confirmButtonLabel="Sim, estou ciente."
            cancelButtonLabel="Cancelar"
            handleConfirm={(e) => {
              this.remove(trial);
            }}
            handleCancel={(e) => {
              this.setState({ showConfirmModal: false });
            }}
          />
        );
      };

      const renderDownloadAllDocumentsConfirmation = () => {
        return (
          <ConfirmModal
            isOpen={this.state.showDownloadAllDocumentsModal}
            message={`Você realmente deseja baixar todos os documentos do processo "${trial.name}"?`}
            comment="Essa operação poderá levar alguns minutos dependendo da quantidade e tamanho dos arquivos a serem processados. Você receberá um e-mail com o link para download após o processamento de todos os documentos."
            confirmButtonLabel="Sim, estou ciente."
            cancelButtonLabel="Cancelar"
            handleConfirm={(e) => {
              this.downloadAllDocuments(trial);
            }}
            handleCancel={(e) => {
              this.setState({ showDownloadAllDocumentsModal: false });
            }}
          />
        );
      };

      const renderTrialOptions = () => {
        return (
          <div>
            <div
              className="trial-details-screen__popover-toggle"
              onClick={this.props.togglePopover}
            >
              ...
            </div>
            <div
              className={
                "trial-details-screen__popover" +
                (showPopover ? " trial-details-screen__popover--active" : "")
              }
            >
              {
                (auth.isMetodocUser() || auth.isRegularUser()) &&  (
                <p
                  onClick={(e) => {
                    this.props.togglePopover();
                    this.props.openEditTrialModal();
                  }}
                >
                  Editar
                </p>
              )}

              {
              (docs && docs.length > 0) && 
                <p
                  onClick={(e) => {
                    this.setState({ showDownloadAllDocumentsModal: true });
                    this.props.togglePopover();
                  }}
                >
                  Baixar processo
                </p>
              }

              {
                (docs && docs.length === 0 && (auth.isMetodocUser() || auth.isRegularUser())) &&
                (
                <p
                  onClick={(e) => {
                    this.setState({ showConfirmModal: true });
                    this.props.togglePopover();
                  }}
                >
                  Excluir
                </p>
              )}
              {(docs && docs.length > 0 && (auth.isMetodocUser() || auth.isRegularUser())) && (
                <p
                  onClick={(e) => {
                    this.props.openEditDocumentLocationModal();
                    this.props.togglePopover();
                  }}
                >
                  {" "}
                  Local de armazenamento físico{" "}
                </p>
              )}
              {(auth.isMetodocUser() || auth.isRegularUser()) && (
                <EditDocumentLocationModal
                  trialId={trial ? trial.id : false}
                  onClose={() => {
                    this.handleChildrenUpdate();
                    // this.props.setSelectedDoc(this.props.currentDoc);
                    // this.onDocumentSelect(this.props.currentDoc)
                  }}
                />
              )}
              {
                (auth.isMetodocUser() || auth.isRegularUser()) && renderDeleteConfirmation()
              }
              {
                renderDownloadAllDocumentsConfirmation()
              }
            </div>
          </div>
        );
      };

      return (
        <Layout>
          <div className="container">
            <div className="trial-details-screen__wrapper">
              <div className="trial-details-screen">
                {
                  renderTrialOptions()
                }
                <div className="trial-details-screen__title-container">
                  <img
                    className={
                      "trial-details-screen__status" +
                      (trial.status === STATUS.PRIVATE
                        ? " trial-details-screen__status--private"
                        : "")
                    }
                    src={lock}
                    alt="Processo privado"
                  />
                  <h1 className="trial-details-screen__name">{trial.name}</h1>
                </div>
                <h2 className="trial-details-screen__subheader">Descrição</h2>
                <p className="trial-details-screen__description">
                  {trial.description}
                </p>
                <h2 className="trial-details-screen__subheader">Membros</h2>
                {renderMembers(trial.users)}
              </div>
              <div
                className={`trial-details-screen__doc-placeholder ${this.props
                  .isShowUploadZone && "drop-zone-active"}`}
              >
                <div className="trial-details-screen__doc-header-container">
                  <div
                    className={`trial-details-screen__searchbar-wrapper ${
                      auth.isMetodocUser() ? "" : "fullwidth"
                    }`}
                  >
                    <span className="trial-details-screen__searchbar-icon"></span>
                    <SearchText
                      isTrial
                      changed={(search) => {
                        this.setState({ search });
                        this.fetchDocuments({ page: 1, search });
                      }}
                    />
                  </div>
                  {(auth.isMetodocUser() || auth.isRegularUser()) && (
                    <PrimaryButton
                      fullWitdth
                      onClick={() => {
                        this.props.openAddDocumentModal(true);
                      }}
                    >
                      Adicionar documento
                    </PrimaryButton>
                  )}
                </div>
                <div
                  className={`${!this.props
                    .isShowUploadZone && (!auth.isAdminUser() ? 'wrapper-drag-and-drop activate-drag-and-drop' : 'wrapper-drag-and-drop')}`}
                  onDragEnter={() => {
                    !auth.isAdminUser() && this.props.setShowUploadZoneFiles(true);
                  }}
                >
                  {!this.props.isShowUploadZone && (<h2>Arraste e solte seus arquivos aqui</h2>)}
                  {!this.props.isShowUploadZone && (
                    <div className="trial-details-screen__doc-container">
                      <InfiniteScroll
                        pageStart={1}
                        useWindow={window.innerWidth <= 1024}
                        loadMore={this.fetchDocuments}
                        hasMore={!isDocLastPage || docsLoading}
                        loader={<Loader key={0} />}
                      >
                        <DocumentList
                          data={this.props.docs}
                          selected={this.props.currentDoc}
                          onClick={this.onDocumentSelect}
                        />
                      </InfiniteScroll>
                    </div>
                  )}
                </div>
              </div>
              {this.props.isShowUploadZone && <UploadZone />}
              <div className="trial-details-screen__log-placeholder">
                <h2 className="trial-details-screen__subheader">Histórico</h2>
                <div className="trial-details-screen__log-container">
                  <InfiniteScroll
                    pageStart={1}
                    useWindow={false}
                    loadMore={this.fetchActivities}
                    hasMore={!isActivitityLastPage || activitiesLoading}
                    loader={<Loader key={0} />}
                  >
                    <ActivityList data={this.props.activities} />
                  </InfiniteScroll>
                </div>
              </div>
              <AddUserModal onClose={() => this.handleChildrenUpdate()} />
              <EditTrialModal onClose={() => this.handleChildrenUpdate()} />
              <AddDocumentModal
                onClose={() => {
                  this.handleChildrenUpdate(this.props.docsPages);
                }}
              />
              <EditDocumentModal onClose={() => this.handleChildrenUpdate()} />
              <AttachDocumentModal onClose={() => this.handleChildrenUpdate()} />
              <InfoDocumentModal onClose={() => this.handleChildrenUpdate()} />
            </div>
            {currentDoc ? (
              <DocumentDetails onUpdate={() => this.handleChildrenUpdate()} />
            ) : docsLoading ? (
              <Loader />
            ) : null}
            <FilesListUpload />
          </div>
        </Layout>
      );
    } else {
      return null;
    }
  }
}

const mapStateToProps = (state) => ({
  trial: state.trial.selected,
  docs: state.trial.docs,
  docsPages: state.trial.docsPages,
  docsLoading: state.trial.docsLoading,
  currentDoc: state.doc.selected,
  activities: state.trial.activities,
  activitiesPages: state.trial.activitiesPages,
  activitiesLoading: state.trial.activitiesLoading,
  showPopover: state.ui.showTrialDetailPopover,
  isAddDocFromTrialDetails: state.doc.isAddDocFromTrialDetails,
  isShowUploadZone: state.doc.isShowUploadZone,
});

const mapDispatchToProps = (dispatch) => ({
  fetchTrialById: (id) => dispatch(fetchTrialById(id)),
  fetchTrialDocumentsById: (params) =>
    dispatch(fetchTrialDocumentsById(params)),
  fetchActivity: (params) => dispatch(fetchActivity(params)),
  fetchUsers: () => dispatch(fetchUsers()),
  togglePopover: () => dispatch(toggleTrialDetailPopover()),
  openAddUserModal: () => dispatch(openAddUserModal()),
  openAddDocumentModal: (isAddDocFromTrialDetails) => {
    dispatch(setIsAddDocFromTrialDetails(isAddDocFromTrialDetails));
    dispatch(openAddDocumentModal());
  },
  openEditTrialModal: () => dispatch(openEditTrialModal()),
  setSelectedDoc: (doc) => dispatch(setSelectedDocument(doc)),
  setSelectedTrial: (trial) => dispatch(setSelectedTrial(trial)),
  openConfirmModal: () => dispatch(openConfirmModal()),
  closeConfirmModal: () => dispatch(closeConfirmModal()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
  removeTrial: (trial) => dispatch(removeTrial(trial)),
  downloadAllDocuments: (trial) => dispatch(downloadAllDocuments(trial)),
  openEditDocumentLocationModal: () =>
    dispatch(openEditDocumentLocationModal()),
  addActivity: (activity) => dispatch(addActivity(activity)),
  setIsAddDocFromTrialDetails: (isAddDocFromTrialDetails) =>
    dispatch(setIsAddDocFromTrialDetails(isAddDocFromTrialDetails)),
  fetchUploadZone: () => dispatch(fetchUploadZone()),
  setShowUploadZoneFiles: (isShowUploadZone) =>
    dispatch(setShowUploadZoneFiles(isShowUploadZone)),
  cleanSelectedDocument: () => dispatch(cleanSelectedDocument()),
});

export default withRouter(
  connect(mapStateToProps, mapDispatchToProps)(TrialDetails)
);
