import React, { Component } from 'react'
import ReactTable from 'react-table'
import { connect } from 'react-redux'

import EditCustomerModal from '../../components/modals/EditCustomerModal'

import { fetchCustomers, setSelectedCustomer } from '../../actions/customer'
import { openEditCustomerModal, openNewCustomerModal } from '../../actions/ui'

import editIcon from '../../assets/edit.svg'
import PrimaryButton from '../../components/PrimaryButton';
import NewCustomerModal from '../../components/modals/NewCustomerModal';
import Arrow from '../../components/Arrow';

import './CustomerList.scss'
import auth from '../../services/auth';

class CustomerList extends Component {
  constructor() {
    super()
    this.state = {
      allCustomers: [],
      filteredCustomers: [],      
      page: undefined,
      sort: [{ id: 'name', desc: false }],
      search: ''
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    this.props.fetchCustomers().then((action) => {
      this.setState({ allCustomers: action.customers, filteredCustomers: action.customers })
    })
  }

  handleSearch(value) {
    if (value !== '') {
      const filteredCustomers = this.state.allCustomers.filter(customer => {
        return customer.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
      this.setState({ filteredCustomers })
    } else
      this.setState({ filteredCustomers: this.state.allCustomers })

    this.setState({ search: value, page: 0 })
  }

  openEditCustomerModal(customer) {
    this.props.setSelectedCustomer(customer)
    this.props.openEditCustomerModal()
  }

  render() {

    const columns = [
      { Header: 'Nome', accessor: 'name', Cell: (d) => <div>{d.original.name}</div> },
      { Header: 'E-mail', accessor: 'email', Cell: (d) => <div>{d.original.email}</div> },
      { Header: '', id: 'edit', Cell: d => <button title="Editar" className="customer-list__edit-customer-button" onClick={() => this.openEditCustomerModal(d.original)}><img src={editIcon} alt="Editar" /></button> },
    ]

    return (
      <div className="customer-list">
        <div className="customer-list__searchbar-container">
          <div className="customer-list__searchbar-wrapper">
            <span className="customer-list__searchbar-icon"></span>
            <input
              className="customer-list__searchbar"
              type="text"
              placeholder="Buscar cliente pelo nome"
              onChange={e => this.handleSearch(e.target.value)}
              value={this.state.search}
              onBlur={() => this.setState({ page: undefined })}
            />
          </div>
        </div>
        {(auth.isMetodocUser()) &&
          <div className="user-list__actionbuttons-container">
            <PrimaryButton onClick={(e) => this.props.openNewCustomerModal()}>
              Adicionar cliente
            </PrimaryButton>
          </div>
        }
        <ReactTable data={this.state.filteredCustomers} columns={columns}
          resizable={false} minRows={1} defaultPageSize={10}
          showPageSizeOptions={false} showPageJump={false} page={this.state.page}
          sorted={this.state.sort} onSortedChange={sort => this.setState({ sort })} 
          pageText="Página" ofText="de" noDataText="Nenhum registro encontrado" 
          previousText={<Arrow />} nextText={<Arrow right />}
        />
        <EditCustomerModal onClose={(e) => this.refresh()} />
        <NewCustomerModal onClose={(e) => this.refresh()} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  customer: state.customer.list,
  selectedCustomer: state.customer.selected
})

const mapDispatchToProps = dispatch => ({
  fetchCustomers: () => dispatch(fetchCustomers()),
  setSelectedCustomer: customer => dispatch(setSelectedCustomer(customer)),
  openEditCustomerModal: () => dispatch(openEditCustomerModal()),
  openNewCustomerModal: () => dispatch(openNewCustomerModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CustomerList)