import {
  FETCH_TRIALS_SUCCESS,
  FETCH_TRIALS_START,
  FETCH_TRIALS_ERROR,
  FETCH_TRIAL_BY_ID_SUCCESS,
  FETCH_TRIAL_BY_ID_START,
  FETCH_TRIAL_BY_ID_ERROR,
  FETCH_TRIAL_DOCUMENTS_BY_ID_SUCCESS,
  FETCH_TRIAL_DOCUMENTS_BY_ID_START,
  FETCH_TRIAL_DOCUMENTS_BY_ID_ERROR,
  CREATE_TRIAL_SUCCESS,
  CREATE_TRIAL_START,
  CREATE_TRIAL_ERROR,
  ADD_USERS_TO_TRIAL_SUCCESS,
  ADD_USERS_TO_TRIAL_START,
  ADD_USERS_TO_TRIAL_ERROR,
  FETCH_ACTIVITY_SUCCESS,
  FETCH_ACTIVITY_START,
  FETCH_ACTIVITY_ERROR,
  SET_CURRENT_TRIAL,
  SET_CURRENT_CUSTOMER_TRIALS,
  UPDATE_TRIAL_SUCCESS,
  ADD_DOCUMENT_TO_PROCESS_LIST,
  SET_CURRENT_TRIALS_PAGE
} from "../actions/trial";

const initialState = {
  list: [],
  docs: [],
  docsPages: 1,
  loading: false,
  docsLoading: false,
  trialsPages: 1,
  currentTrialPage: 0,
  selected: null,
  currentId: null,
  activities: [],
  activitiesPages: 1,
  activitiesLoading: false,
  filteredByCustomer: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_TRIAL_BY_ID_SUCCESS:
      return { ...state, selected: action.trial };

    case FETCH_TRIAL_DOCUMENTS_BY_ID_START:
      return {
        ...state,
        docsLoading: true,
        docs: action.refresh ? [] : state.docs,
      };

    case FETCH_TRIAL_DOCUMENTS_BY_ID_SUCCESS:
      return {
        ...state,
        docs: [...state.docs, ...action.docs.list],
        docsPages: action.docs.pages,
        docsLoading: false,
      };

    case FETCH_TRIALS_SUCCESS:
      return {
        ...state,
        list: action.trials.trials,
        trialsPages: action.trials.count,
      };

    case SET_CURRENT_CUSTOMER_TRIALS:
      return { ...state, filteredByCustomer: action.customerTrials };

    case SET_CURRENT_TRIAL:
      return { ...state, selected: action.trial, docs: [], activities: [] };

    case SET_CURRENT_TRIALS_PAGE:
      return { ...state, currentTrialPage: action.page};

    case FETCH_ACTIVITY_START:
      return {
        ...state,
        activitiesLoading: true,
        activities: action.refresh ? [] : state.activities,
      };

    case FETCH_ACTIVITY_SUCCESS:
      return {
        ...state,
        activities: [...state.activities, ...action.activities.list],
        activitiesPages: action.activities.pages,
        activitiesLoading: false,
      };

    case UPDATE_TRIAL_SUCCESS:
      const updatedTrial = {
        ...state.selected,
        description: action.trial.description,
        name: action.trial.name,
        status: action.trial.status,
        users: action.trial.allInfoUsers,
      };

      const trialIndex = state.list.findIndex(
        (trial) => trial.id === action.trial.id
      );

      const newTrialList = [...state.list];
      if (trialIndex >= 0) {
        newTrialList[trialIndex] = updatedTrial;
      }

      return { ...state, list: [...newTrialList], selected: updatedTrial };

    case ADD_DOCUMENT_TO_PROCESS_LIST:
      const listProcess = [...state.docs];

      listProcess.push(action.doc);

      return {
        ...state,
        docs: listProcess,
      };

    case FETCH_TRIAL_BY_ID_START:
    case FETCH_TRIAL_BY_ID_ERROR:
    case FETCH_TRIAL_DOCUMENTS_BY_ID_ERROR:
    case FETCH_TRIALS_START:
    case FETCH_TRIALS_ERROR:
    case CREATE_TRIAL_SUCCESS:
    case CREATE_TRIAL_START:
    case CREATE_TRIAL_ERROR:
    case ADD_USERS_TO_TRIAL_SUCCESS:
    case ADD_USERS_TO_TRIAL_START:
    case ADD_USERS_TO_TRIAL_ERROR:
    case FETCH_ACTIVITY_ERROR:
    default:
      return state;
  }
};
