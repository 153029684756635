import { v4 as uuidV4 } from "uuid";
import filesize from "filesize";

import documentService from "../services/document";
import removeExtesionFromFileName from "../utils/removeExtensionFromFileName";
import { PENDING_STORAGE } from "../config/consts";
import { addDocToList } from "./trial";

export const FETCH_DOCUMENTS_START = "FETCH_DOCUMENTS_START";
export const FETCH_DOCUMENTS_ERROR = "FETCH_DOCUMENTS_ERROR";
export const FETCH_DOCUMENTS_SUCCESS = "FETCH_DOCUMENTS_SUCCESS";
export const AUTHORIZE_DOCUMENT_UPLOAD_START =
  "AUTHORIZE_DOCUMENT_UPLOAD_START";
export const AUTHORIZE_DOCUMENT_UPLOAD_ERROR =
  "AUTHORIZE_DOCUMENT_UPLOAD_ERROR";
export const AUTHORIZE_DOCUMENT_UPLOAD_SUCCESS =
  "AUTHORIZE_DOCUMENT_UPLOAD_SUCCESS";
export const ADD_DOCUMENT_START = "ADD_DOCUMENT_START";
export const ADD_DOCUMENT_ERROR = "ADD_DOCUMENT_ERROR";
export const ADD_DOCUMENT_SUCCESS = "ADD_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_START = "UPDATE_DOCUMENT_START";
export const UPDATE_DOCUMENT_ERROR = "UPDATE_DOCUMENT_ERROR";
export const UPDATE_DOCUMENT_SUCCESS = "UPDATE_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_LOCATION_START = "UPDATE_DOCUMENT_LOCATION_START";
export const UPDATE_DOCUMENT_LOCATION_ERROR = "UPDATE_DOCUMENT_LOCATION_ERROR";
export const UPDATE_DOCUMENT_LOCATION_SUCCESS =
  "UPDATE_DOCUMENT_LOCATION_SUCCESS";
export const SET_SELECTED_DOCUMENT_START = "SET_SELECTED_DOCUMENT_START";
export const SET_SELECTED_DOCUMENT_ERROR = "SET_SELECTED_DOCUMENT_ERROR";
export const SET_SELECTED_DOCUMENT_SUCCESS = "SET_SELECTED_DOCUMENT_SUCCESS";
export const CLEAN_SELECTED_DOCUMENT = "CLEAN_SELECTED_DOCUMENT";
export const REMOVE_DOCUMENT_START = "REMOVE_DOCUMENT_START";
export const REMOVE_DOCUMENT_ERROR = "REMOVE_DOCUMENT_ERROR";
export const REMOVE_DOCUMENT_SUCCESS = "REMOVE_DOCUMENT_SUCCESS";
export const ATTACH_DOCUMENT_START = "REMOVE_DOCUMENT_START";
export const ATTACH_DOCUMENT_SUCCESS = "ATTACH_DOCUMENT_SUCCESS";
export const UPDATE_DOCUMENT_ATTACH_START = "UPDATE_DOCUMENT_ATTACH_START";
export const SET_ADD_DOC_FROM_TRIAL_DETAILS = "SET_ADD_DOC_FROM_TRIAL_DETAILS";
export const SET_UPLOADED_FILES = "SET_UPLOADED_FILES";
export const UPDATE_FILE = "UPDATE_FILE";
export const SET_SHOW_FEED_BACK_UPLOAD_FILES =
  "SET_SHOW_FEED_BACK_UPLOAD_FILES";
export const REMOVE_FILE_FROM_LIST = "REMOVE_FILE_FROM_LIST";
export const FETCH_UPLOAD_ZONE = "FETCH_UPLOAD_ZONE";
export const SET_SHOW_UPLOAD_ZONE_FILES = "SET_SHOW_UPLOAD_ZONE_FILES";

export const fetchDocumentsStart = () => ({
  type: FETCH_DOCUMENTS_START,
});

export const fetchDocumentsError = () => ({
  type: FETCH_DOCUMENTS_ERROR,
});

export const fetchDocumentsSuccess = (docs) => ({
  type: FETCH_DOCUMENTS_SUCCESS,
  docs,
});

export const fetchDocuments = (params) => (dispatch) => {
  dispatch(fetchDocumentsStart());

  return documentService.getAll(params).then((response) => {
    return dispatch(fetchDocumentsSuccess(response.data));
  });
};

export const getDocAuthorizationStart = () => ({
  type: ADD_DOCUMENT_START,
});

export const getDocAuthorizationError = () => ({
  type: ADD_DOCUMENT_ERROR,
});

export const getDocAuthorizationSuccess = (authorization) => ({
  type: ADD_DOCUMENT_SUCCESS,
  authorization,
});

export const getDocAuthorization = (trialId, code, docType) => (dispatch) => {
  dispatch(getDocAuthorizationStart());
  return documentService
    .getUploadAuthorization(trialId, code, docType)
    .then((response) => {
      return dispatch(getDocAuthorizationSuccess(response.data));
    });
};

export const addDocumentStart = () => ({
  type: ADD_DOCUMENT_START,
});

export const addDocumentError = () => ({
  type: ADD_DOCUMENT_ERROR,
});

export const addDocumentSuccess = () => ({
  type: ADD_DOCUMENT_SUCCESS,
});

export const addDocument = (doc) => (dispatch) => {
  dispatch(addDocumentStart());

  return documentService.addDocument(doc).then(() => {
    return dispatch(addDocumentSuccess());
  });
};

export const updateDocumentStart = () => ({
  type: UPDATE_DOCUMENT_START,
});

export const updateDocumentError = () => ({
  type: UPDATE_DOCUMENT_ERROR,
});

export const updateDocumentSuccess = (updatedDoc) => ({
  type: UPDATE_DOCUMENT_SUCCESS,
  updatedDoc,
});

export const updateDocument = (doc, additionalInfo) => (dispatch) => {
  dispatch(updateDocumentStart());

  return documentService
    .updateDocument(doc, additionalInfo)
    .then((response) => {
      return dispatch(updateDocumentSuccess(response.data));
    });
};

export const updateDocumentLocationStart = () => ({
  type: UPDATE_DOCUMENT_LOCATION_START,
});

export const updateDocumentLocationError = () => ({
  type: UPDATE_DOCUMENT_LOCATION_ERROR,
});

export const updateDocumentLocationSuccess = () => ({
  type: UPDATE_DOCUMENT_LOCATION_SUCCESS,
});

export const updateDocumentLocation = (doc, additionalInfo) => (dispatch) => {
  dispatch(updateDocumentLocationStart());

  return documentService
    .updateDocumentLocation(doc, additionalInfo)
    .then(() => {
      return dispatch(updateDocumentLocationSuccess());
    });
};

export const setSelectedDocumentStart = (doc) => ({
  type: SET_SELECTED_DOCUMENT_START,
  doc,
});

export const setIsAddDocFromTrialDetails = (isAddDocFromTrialDetails) => ({
  type: SET_ADD_DOC_FROM_TRIAL_DETAILS,
  isAddDocFromTrialDetails,
});

export const setSelectedDocumentError = () => ({
  type: SET_SELECTED_DOCUMENT_ERROR,
});

export const setSelectedDocumentSuccess = (base64) => ({
  type: SET_SELECTED_DOCUMENT_SUCCESS,
  base64,
});

export const setSelectedDocument = (doc) => (dispatch) => {
  dispatch(setSelectedDocumentStart(doc));
  // if (doc) return documentService.getBase64FromURL(doc).then(response => { return dispatch(setSelectedDocumentSuccess(response.data)) })
  return dispatch(setSelectedDocumentSuccess());
};

export const cleanSelectedDocument = () => ({
  type: CLEAN_SELECTED_DOCUMENT,
});

export const removeDocumentStart = () => ({
  type: REMOVE_DOCUMENT_START,
});

export const removeDocumentError = () => ({
  type: REMOVE_DOCUMENT_ERROR,
});

export const removeDocumentSuccess = () => ({
  type: REMOVE_DOCUMENT_SUCCESS,
});

export const removeDocument = (doc) => (dispatch) => {
  dispatch(removeDocumentStart());

  return documentService.removeDocument(doc).then(() => {
    return dispatch(removeDocumentSuccess());
  });
};

/* ATTACH DOCUMENT */

export const attachDocumentSuccess = () => ({
  type: ATTACH_DOCUMENT_SUCCESS,
});

export const attachDocumentStart = () => ({
  type: ATTACH_DOCUMENT_START,
});

export const attachDocument = (doc, attachDocument) => (dispatch) => {
  dispatch(attachDocumentStart());

  return documentService.attachPdf(doc, attachDocument).then(() => {
    return dispatch(attachDocumentSuccess());
  });
};

/* // ATTACH DOCUMENT */

export const setUploadedFiles = (files) => ({
  files,
  type: SET_UPLOADED_FILES,
});

const updateFile = (id, data) => ({
  id,
  data,
  type: UPDATE_FILE,
});

export const setShowFeedBackUploadFiles = (isShowFeedBackUpload) => ({
  isShowFeedBackUpload,
  type: SET_SHOW_FEED_BACK_UPLOAD_FILES,
});

export const setShowUploadZoneFiles = (isShowUploadZone) => {
  return {
    isShowUploadZone,
    type: SET_SHOW_UPLOAD_ZONE_FILES,
  };
};

const processUpload = ({ uploadedFiles, trial }) => (dispatch) => {
  dispatch(updateFile(uploadedFiles.id, { isProgressStart: true }));

  return documentService
    .uploadFiles({ file: uploadedFiles.file, trial })
    .then((response) => {
      const documentData = {
        code: response.code,
        url: response.url,
        trialId: trial.id,
        name: uploadedFiles.name,
        physicalStorageLocation: PENDING_STORAGE,
        date: new Date(),
      };

      documentService
        .addDocument(documentData)
        .then((res) => {
          const { data } = res;

          const doc = {
            code: data.code,
            expiryDate: data.expiryDate || null,
            name: data.name,
            date: data.date,
            observation: data.observation || "",
            trialId: data.trialId,
            url: data.url,
            physicalStorageLocation: data.physicalStorageLocation,
            updateAt: new Date(),
            trial,
          };
          dispatch(addDocToList(doc));
          dispatch(setSelectedDocument(doc));

          return dispatch(updateFile(uploadedFiles.id, { uploaded: true }));
        })
        .catch((e) => {
          return dispatch(
            updateFile(uploadedFiles.id, {
              error: true,
              isProgressStart: false,
            })
          );
        });
    })
    .catch((e) => {
      return dispatch(
        updateFile(uploadedFiles.id, {
          error: true,
          isProgressStart: false,
        })
      );
    });
};

export const handleUploadZone = ({ files, trial }) => (dispatch) => {
  const newUploadedFiles = files.map((file) => ({
    file,
    id: uuidV4(),
    name: removeExtesionFromFileName(file.name),
    readableSize: filesize(file.size),
    isProgressStart: false,
    uploaded: false,
    error: false,
  }));

  dispatch(setUploadedFiles(newUploadedFiles));

  newUploadedFiles.forEach((file) => {
    return dispatch(processUpload({ uploadedFiles: file, trial }));
  });
};

const removeFile = (id) => ({
  id,
  type: REMOVE_FILE_FROM_LIST,
});

export const removeFilesFromList = (id) => (dispatch) => {
  dispatch(removeFile(id));
};

export const fetchUploadZone = () => ({
  type: FETCH_UPLOAD_ZONE,
});
