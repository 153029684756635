import React from 'react'

import logo from '../../assets/logo.svg'
import './LoginBox.scss'

const LoginBox = ({ subheader, children }) => {
  return (
    <div className="login-box">
      <img className="login-box__logo" src={ logo } alt="Logo Metodoc" />
      <p className="login-box__subheader">{ subheader }</p>
      { children }
    </div>
  )
}

export default LoginBox
