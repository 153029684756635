import companyService from '../services/company'
import auth from '../services/auth';

export const CREATE_COMPANY_START = 'CREATE_COMPANY_START'
export const CREATE_COMPANY_ERROR = 'CREATE_COMPANY_ERROR'
export const CREATE_COMPANY_SUCCESS = 'CREATE_COMPANY_SUCCESS'
export const SET_SELECTED_COMPANY = 'SET_SELECTED_COMPANY'
export const UPDATE_COMPANY_START = 'UPDATE_COMPANY_START'
export const UPDATE_COMPANY_ERROR = 'UPDATE_COMPANY_ERROR'
export const UPDATE_COMPANY_SUCCESS = 'UPDATE_COMPANY_SUCCESS'
export const FETCH_COMPANIES_SUCCESS = 'FETCH_COMPANIES_SUCCESS'
export const FETCH_COMPANIES_START = 'FETCH_COMPANIES_START'
export const FETCH_COMPANIES_ERROR = 'FETCH_COMPANIES_ERROR'

export const setSelectedCompany = company => ({
  type: SET_SELECTED_COMPANY,
  selected: company,
})

export const fetchCompaniesStart = () => ({
  type: FETCH_COMPANIES_START,
})

export const fetchCompaniesError = () => ({
  type: FETCH_COMPANIES_ERROR,
})

export const fetchCompaniesSuccess = companies => ({
  type: FETCH_COMPANIES_SUCCESS,
  companies,
})

export const fetchCompanies = () => dispatch => {
  return new Promise(async (resolve, reject) => {
    dispatch(fetchCompaniesStart())
    try {
      let response = []
      if (auth.isMetodocUser()) response = (await companyService.getAll()).data
      else response = [(await companyService.getById(auth.getCompanyId())).data]
      const companies = response.map(company => { return { ...company, label: company.name, value: company.id } })
      dispatch(fetchCompaniesSuccess(companies))
      resolve({ companies })
    } catch (e) {
      dispatch(fetchCompaniesError())
    }
  })
}


export const createCompanyStart = () => ({
  type: CREATE_COMPANY_START,
})

export const createCompanyError = () => ({
  type: CREATE_COMPANY_ERROR,
})

export const createCompanySuccess = () => ({
  type: CREATE_COMPANY_SUCCESS,
})

export const createCompany = company => dispatch => {
  dispatch(createCompanyStart())

  return companyService.createCompany(company).then(() => {
    dispatch(fetchCompanies())
    return dispatch(createCompanySuccess())
  })
}

export const updateCompanyStart = () => ({
  type: UPDATE_COMPANY_START,
})

export const updateCompanyError = () => ({
  type: UPDATE_COMPANY_ERROR,
})

export const updateCompanySuccess = () => ({
  type: UPDATE_COMPANY_SUCCESS,
})

export const updateCompany = (id, company) => dispatch => {
  dispatch(updateCompanyStart())

  return companyService.updateCompany(id, company).then(() => {
    dispatch(fetchCompanies())
    return dispatch(updateCompanySuccess())
  })
}
