import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { connect } from "react-redux";

import "./styles.scss";
import uploadIcon from "../../assets/upload_image.svg";
import {
  handleUploadZone,
  setShowFeedBackUploadFiles,
  setShowUploadZoneFiles,
} from "../../actions/document";

const UploadZone = ({
  handleUploadZone,
  trial,
  setShowFeedBackUploadFiles,
  setShowUploadZoneFiles,
}) => {
  const onDrop = useCallback((files) => {
    handleUploadZone({ files, trial });
    setShowFeedBackUploadFiles(true);
    setShowUploadZoneFiles(false);
  }, []);

  const onDragLeave = useCallback(() => {
    setShowUploadZoneFiles(false);
  }, []);

  const {
    getRootProps,
    getInputProps,
    isDragActive,
    isDragReject,
  } = useDropzone({
    accept: [
      "image/png",
      "image/jpeg",
      "application/pdf",
      "text/xml",
      "application/octet-stream",
      "application/vnd.ms-excel",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel.sheet.macroEnabled.12",
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/x-pkcs12",
      "application/vnd.ms-word.document.macroEnabled.12"
    ],
    onDrop,
    onDragLeave,
  });

  const renderDragMessage = useCallback(() => {
    if (isDragReject) {
      return (
        <p className="upload-zone__container__text">
          Tipo de arquivo não suportado
        </p>
      );
    }

    return (
      <p className="upload-zone__container__text">
        Arraste e solte seus arquivos aqui
      </p>
    );
  }, [isDragActive, isDragReject]);

  return (
    <div className="upload-zone">
      <div {...getRootProps()} className="upload-zone__container">
        <input {...getInputProps()} className="upload-zone__container__input" />
        <img src={uploadIcon} alt="Upload icone" />
        {renderDragMessage()}
      </div>
    </div>
  );
};

const mapStateToProps = ({ doc, trial }) => ({
  trial: trial.selected,
});

const mapDispatchToProps = (dispatch) => ({
  handleUploadZone: ({ files, trial }) =>
    dispatch(handleUploadZone({ files, trial })),
  setShowFeedBackUploadFiles: (isShowFeedBackUpload) =>
    dispatch(setShowFeedBackUploadFiles(isShowFeedBackUpload)),
  setShowUploadZoneFiles: (isShowUploadZone) =>
    dispatch(setShowUploadZoneFiles(isShowUploadZone)),
});

export default connect(mapStateToProps, mapDispatchToProps)(UploadZone);
