import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import Select from 'react-select'

import PrimaryButton from '../../PrimaryButton'

import { createUser } from '../../../actions/user'
import { closeNewUserModal, openLoaderModal, closeLoaderModal, openErrorModal } from '../../../actions/ui'
import { fetchUsers } from '../../../actions/user'
import { fetchCustomersByCompanyId } from "../../../actions/customer";
import authService from '../../../services/auth'

import utilsService from "../../../services/utils";

import './NewUserModal.scss'

Modal.setAppElement('#root')

const permissionOptions = [
  { name: 'user', label: 'Administrador', value: 'user' },
  { name: 'admin', label: 'Usuário', value: 'admin' }
]

class NewUserModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      company: null,
      permission: null,
      name: '',
      email: '',
      phone: '',
      displayPhone: '',
      position: '',
      role: '',
      listCustomers: [],
      allowedCustomers: [],
    }
    this.handleAfterOpen = this.handleAfterOpen.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
    this.handleCompanyChange = this.handleCompanyChange.bind(this);
    this.handleCustomerChange = this.handleCustomerChange.bind(this);
  }

  handleAfterOpen() {
  }

  cleanUpForm() {
    this.setState({
      company: null,
      permission: null,
      name: '',
      email: '',
      phone: '',
      displayPhone: '',
      position: '',
      role: '',
      listCustomers: [],
      allowedCustomers: []
    })
  }

  getCustomers(companyId) {
    fetchCustomersByCompanyId(companyId).then((response) => {
      const listCustomers = response.map((x) => ({
        id: x.id,
        name: x.name,
        label: x.name,
        value: x.id,
        company: x.companyId,
        companyId: x.companyId,
      }));
      this.setState({ listCustomers: listCustomers });
    });
  }

  handlePermissionChange(permission) {
    this.setState({ permission: permission })
  }
  
  async handleCompanyChange(company) {
    this.setState({ company: company })
    await this.getCustomers(company.id);
    const listCustomers = this.state.listCustomers;
    const allowedCustomers = [];
    this.setState({ allowedCustomers, listCustomers});
  }
  
  handleCustomerChange(allowedCustomers) {
    this.setState({ allowedCustomers: allowedCustomers});
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value })
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handlePhoneChange(e) {
    this.setState({ phone: this.numbersOnly(e.target.value), displayPhone: this.formatPhone(e.target.value) })
  }

  numbersOnly(phone) {
    return phone.replace(/\D/g, "")
  }

  formatPhone(phone) {
    return this.numbersOnly(phone)            // Keep numbers only
      .replace(/^(\d{2})(\d)/g, "($1) $2") // Wraps the first two digits on columns
      .replace(/(\d)(\d{4})$/, "$1-$2")    // Adds the separator dash
  }

  handleRoleChange(e) {
    this.setState({ role: e.target.value })
  }

  handlePositionChange(e) {
    this.setState({ position: e.target.value })
  }

  isDuplicated(email) {
    return (this.props.users || []).filter((x) => x.email === email)[0]
  }

  handleSubmit() {
    this.props.fetchUsers().then(() => {
      const user = {
        ...this.state,
        companyId: this.state.company.id,
        permission: this.state.permission.value,
        company: undefined,
        displayPhone: undefined
      }
      delete user.listCustomers;
      if (this.isDuplicated(user.email)) {
        return this.props.openErrorModal('Endereço de e-mail duplicado!', 'Já existe um usuário cadastrado com este e-mail. Por favor, tente com um endereço de e-mail diferente.')
      }
      this.props.openLoaderModal()

      this.props.createUser(user).then(() => {
        this.cleanUpForm();
        this.props.closeModal()
        if (this.props.onClose()) this.props.onClose()
      }).catch((e) => {
        this.props.openErrorModal()
      }).then(() => {
        this.props.closeLoaderModal()
      })
    })
  }

  render() {
    const { company, permission, name, email, phone, role } = this.state
    const isValidForm =
      (permission && permission.value) &&
      (company && company.id) &&
      (name && name.length > 0) &&
      (email && email.length > 0) &&
      (phone && phone.length > 0) &&
      (role && role.length > 0)

    return (
      <Modal
        overlayClassName="user-modal-overlay"
        className="user-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button className="user-modal__close" onClick={() => { this.cleanUpForm(); this.props.closeModal() }}></button>
        <h1 className="user-modal__heading">Adicionar novo usuário</h1>
        <form className="user-modal__form">
          {
            authService.isMetodocUser() && (
              <Fragment>
                <Select
                  className="modal-select-container"
                  classNamePrefix="modal-select"
                  placeholder="Empresa..."
                  options={utilsService.sortByName(this.props.companies)}
                  value={this.state.company}
                  onChange={(e) => { this.handleCompanyChange(e) }}
                />
              </Fragment>
            )
          }

          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Selecionar clientes"
            value={this.state.allowedCustomers}
            options={utilsService.sortByName(this.state.listCustomers)}
            onChange={(e) => { this.handleCustomerChange(e) }}
            isMulti
          />

          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Permissões..."
            options={permissionOptions}
            value={this.state.permission}
            onChange={(e) => { this.handlePermissionChange(e) }}
          />
          <input
            className="user-modal__form__input"
            type="text"
            placeholder="Nome do usuário (máx. 250 caracteres)"
            onChange={(e) => { this.handleNameChange(e) }}
            value={this.state.name}
            maxLength="250"
            onKeyDown={(e) => {
              if (e.key === "Enter" && isValidForm) {
                this.handleSubmit()
              }
            }}
          />
          <input
            className="user-modal__form__input"
            type="text"
            placeholder="E-mail do usuário (máx. 250 caracteres)"
            onChange={(e) => { this.handleEmailChange(e) }}
            value={this.state.email}
            maxLength="250"
            onKeyDown={(e) => {
              if (e.key === "Enter" && isValidForm) {
                this.handleSubmit()
              }
            }}
          />
          <input
            className="user-modal__form__input"
            type="text"
            placeholder="Telefone do usuário"
            onChange={(e) => { this.handlePhoneChange(e) }}
            value={this.state.displayPhone}
            maxLength="15"
            onKeyDown={(e) => {
              if (e.key === "Enter" && isValidForm) {
                this.handleSubmit()
              }
            }}
          />
          <input
            className="user-modal__form__input"
            type="text"
            placeholder="Cargo do usuário (máx. 250 caracteres)"
            onChange={(e) => { this.handleRoleChange(e) }}
            value={this.state.role}
            maxLength="250"
            onKeyDown={(e) => {
              if (e.key === "Enter" && isValidForm) {
                this.handleSubmit()
              }
            }}
          />
          <PrimaryButton disabled={!isValidForm} fullWidth onClick={this.handleSubmit}>Confirmar</PrimaryButton>
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.ui.isNewUserModalOpen,
  companies: state.company.list,
  selectedCompany: state.company.selected,
  users: state.user.list,
})

const mapDispatchToProps = dispatch => ({
  createUser: user => dispatch(createUser(user)),
  closeModal: () => dispatch(closeNewUserModal()),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchCustomersByCompanyId: () => dispatch(fetchCustomersByCompanyId()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: (title, message) => dispatch(openErrorModal(title, message))
})

export default connect(mapStateToProps, mapDispatchToProps)(NewUserModal)
