import GoogleAnalytics from 'react-ga'

import config from '../config'

GoogleAnalytics.initialize(config.googleAnalyticsApiKey)

const pageview = (location) => {
    const page = location.pathname + location.search
    GoogleAnalytics.pageview(page)
}

export default { pageview }

