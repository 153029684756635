import React, { Component } from "react";
import { Redirect, Route, Switch, withRouter } from "react-router-dom";

// import Home from "./screens/Home";
import LoginScreen from "./screens/Login";
import ChangePasswordScreen from "./screens/ChangePassword";
import ChangePasswordSuccessScreen from "./screens/ChangePasswordSuccess";
import ForgotPasswordSuccessScreen from "./screens/ForgotPasswordSuccess";
import ForgotPasswordScreen from "./screens/ForgotPassword";
import Trials from "./screens/Trials";
import TrialDetails from "./screens/TrialDetails";
import Documents from "./screens/Documents";
import Users from "./screens/Users";
import analytics from "./services/analytics";

class App extends Component {
  constructor(props) {
    super(props);
    this.props.history.listen((location) => {
      this.handleRouteChange(location);
    });
  }

  handleRouteChange(location) {
    analytics.pageview(location);
  }

  render() {
    return (
      <Switch>
        {/* <Route path="/home" component={LoginScreen} /> */}
        <Route path='/home' component={() => {
            window.location.href = 'https://metodoc.com.br/';
            return null;
        }}/>
        <Route path="/login" component={LoginScreen} />
        <Route path="/changePassword" component={ChangePasswordScreen} />
        <Route path="/forgotPassword" component={ForgotPasswordScreen} />
        <Route
          path="/changePasswordSuccess"
          component={ChangePasswordSuccessScreen}
        />
        <Route
          path="/forgotPasswordSuccess"
          component={ForgotPasswordSuccessScreen}
        />
        <Route
          exact
          path="/trials"
          component={Trials}
          key={this.props.location.pathname}
        />
        <Route exact path="/documents" component={Documents} />
        <Route exact path="/users" component={Users} />
        <Route path="/trials/:id" component={TrialDetails} />
        <Redirect to="/home" />
      </Switch>
    );
  }
}

export default withRouter(App);
