import React, { useCallback, useState } from "react";
import { connect } from "react-redux";
import { MdClose, MdKeyboardArrowDown } from "react-icons/md";

import {
  handleUploadZone,
  removeFilesFromList,
  setShowFeedBackUploadFiles,
} from "../../actions/document";

import SpinLoader from "../../components/SpinLoader";

import fileImage from "../../assets/file_icon.svg";
import fileDone from "../../assets/icon_done.svg";
import deleteIcon from "../../assets/delete_icon.svg";

import "./FilesListUpload.scss";

const FileListUpload = ({
  allFilesToUpload,
  isShowFeedBackUpload,
  trial,
  handleUploadZone,
  removeFilesFromList,
  setShowFeedBackUploadFiles,
}) => {
  const [minimize, setMinimize] = useState(false);

  const wichIconShouldToShow = useCallback((file) => {
    if (file.isProgressStart && !file.uploaded) {
      return (
        <SpinLoader className="file-list-upload__list__item__container-right__loader" />
      );
    } else if (file.uploaded) {
      return <img src={fileDone} alt="Terminou" />;
    } else if (!file.isProgressStart && !file.uploaded && !file.error) {
      return <img src={deleteIcon} alt="Deletar" />;
    } else if (file.error) {
      return (
        <div
          className="file-list-upload__list__item__container-right__problem"
          onClick={() => {
            removeFilesFromList(file.id);
            handleUploadZone({ files: [file.file], trial });
          }}
        ></div>
      );
    }
  }, []);

  return (
    <>
      {isShowFeedBackUpload && (
        <div className="file-list-upload">
          <header className="file-list-upload__header">
            <p>Arquivos Carregados</p>
            <div className="file-list-upload__header__container">
              <MdKeyboardArrowDown
                className={`file-list-upload__header__container__arrow-down ${
                  minimize ? "minimized" : ""
                }`}
                size={24}
                onClick={() => {
                  setMinimize((state) => !state);
                }}
              />
              <MdClose
                className="file-list-upload__header__container__close"
                size={24}
                onClick={() => setShowFeedBackUploadFiles(false)}
              />
            </div>
          </header>
          <ul
            className={`file-list-upload__list ${minimize ? "minimized" : ""}`}
          >
            {allFilesToUpload.map((file) => (
              <li key={file.id} className="file-list-upload__list__item">
                <div className="file-list-upload__list__item__container">
                  <img
                    className={`${
                      file.uploaded || file.error ? "" : "opacity"
                    }`}
                    src={fileImage}
                    alt="Arquivo"
                  />
                  <span
                    className={`${
                      file.uploaded || file.error ? "" : "opacity"
                    }`}
                  >
                    {file.name}
                  </span>
                </div>
                <div className="file-list-upload__list__item__container-right">
                  <span className="file-list-upload__list__item__container-right__text">
                    {file.error && "Upload cancelado"}
                  </span>
                  {wichIconShouldToShow(file)}
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </>
  );
};

const mapStateToProps = ({ doc, trial }) => ({
  allFilesToUpload: doc.allFilesToUpload,
  isShowFeedBackUpload: doc.isShowFeedBackUpload,
  isShowUploadZone: doc.isShowUploadZone,
  trial: trial.selected,
});

const mapDispatchToProps = (dispatch) => ({
  handleUploadZone: ({ files, trial }) =>
    dispatch(handleUploadZone({ files, trial })),
  removeFilesFromList: (id) => dispatch(removeFilesFromList(id)),
  setShowFeedBackUploadFiles: (isShowFeedBackUpload) =>
    dispatch(setShowFeedBackUploadFiles(isShowFeedBackUpload)),
});

export default connect(mapStateToProps, mapDispatchToProps)(FileListUpload);
