import axios from 'axios'

import config from '../config'

const getAll = () => {
  return axios.get(`${config.baseUrl}/customers`, {
    headers: {
      'Authorization': `Bearer ${ sessionStorage.getItem('metodocToken') }`,
    },
  })
}

const getById = id => {
  return axios.get(`${config.baseUrl}/customers/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('metodocToken')}`,
    },
  })
}

const getByCompanyId = id => {
  return axios.get(`${config.baseUrl}/customers/company/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('metodocToken')}`,
    },
  })
}

const getCustomersForUser = (companyId, userId) => {
  return axios.get(`${config.baseUrl}/customers/foruser/${companyId}/${userId}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('metodocToken')}`,
    },
  });
};

const createCustomer = customer => {
  return axios.post(`${config.baseUrl}/customers`, customer, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('metodocToken')}`,
    },
  })
}

const updateCustomer = (customerId, updates) => {
  return axios.put(`${config.baseUrl}/customers/${customerId}`, updates, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('metodocToken')}`,
    },
  })
}

export default { getAll, getByCompanyId, getCustomersForUser, getById, createCustomer, updateCustomer }
