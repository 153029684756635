import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import Select from 'react-select'

import PrimaryButton from '../../PrimaryButton'

import { createCustomer } from '../../../actions/customer'
import { closeNewCustomerModal, openLoaderModal, closeLoaderModal, openErrorModal } from '../../../actions/ui'
import { fetchCustomers } from '../../../actions/customer'
import authService from '../../../services/auth'

import './NewCustomerModal.scss'
import { fetchUsers } from '../../../actions/user';

Modal.setAppElement('#root')

class NewCustomerModal extends Component {
  constructor(props) {
    super(props)

    this.state = {
      company: null,
      name: '',
      email: '',
      cnpj: '',
      displayCnpj: ''
    }
    this.handleAfterOpen = this.handleAfterOpen.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleAfterOpen() {
    this.props.fetchUsers().then(() => { })
  }

  handleCompanyChange(company) {
    this.setState({ company: company })
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value })
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handleCnpjChange(e) {
    this.setState({ cnpj: e.target.value, displayCnpj: this.formatCnpj(e.target.value) })
  }

  handleUsersChange(selectedUsers) {
    this.setState({ selectedUsers })
  }

  formatCnpj(raw) {
    return raw.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
  }

  handleSubmit() {
    this.props.openLoaderModal()
    let customer = {
      ...this.state,
      companyId: this.state.company.id,
      users: this.state.selectedUsers.map((x) => x.id),
      selectedUsers: undefined,
      company: undefined,
      displayCnpj: undefined
    }
    this.props.createCustomer(customer).then(() => {
      this.setState({ company: null, name: '', email: '', cnpj: '', displayCnpj: '', selectedUsers: [] })
      this.props.closeModal()
      if (this.props.onClose) this.props.onClose()
    }).catch((e) => {
      this.props.openErrorModal()
    }).then(() => {
      this.props.closeLoaderModal()
    })
  }

  render() {
    const { company, name, email, cnpj } = this.state
    const isValidForm =
      (company && company.id) &&
      (name && name.length > 0) &&
      (email && email.length > 0) &&
      (cnpj && cnpj.length > 0)

    return (
      <Modal
        overlayClassName="customer-modal-overlay"
        className="customer-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button className="customer-modal__close" onClick={this.props.closeModal}></button>
        <h1 className="customer-modal__heading">Adicionar novo cliente</h1>
        <form className="customer-modal__form">
          {
            authService.isMetodocUser() && (
              <Fragment>
                <Select
                  className="modal-select-container"
                  classNamePrefix="modal-select"
                  placeholder="Empresa..."
                  options={this.props.companies}
                  value={this.state.company}
                  onChange={(e) => { this.handleCompanyChange(e) }}
                />
              </Fragment>
            )
          }
          <input
            className="customer-modal__form__input"
            type="text"
            placeholder="Nome do cliente (máx. 250 caracteres)"
            onChange={(e) => { this.handleNameChange(e) }}
            value={this.state.name}
            maxLength="250"
          />
          <input
            className="customer-modal__form__input"
            type="e-mail"
            placeholder="E-mail do cliente (máx. 250 caracteres)"
            onChange={(e) => { this.handleEmailChange(e) }}
            value={this.state.email}
            maxLength="250"
          />
          <input
            className="customer-modal__form__input"
            type="string"
            placeholder="CNPJ do cliente"
            onChange={(e) => { this.handleCnpjChange(e) }}
            value={this.state.displayCnpj}
            maxLength="18"
          />
          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Usuários"
            options={this.props.userList}
            onChange={(e) => { this.handleUsersChange(e) }}
            value={this.state.selectedUsers}
            isMulti
          />
          <PrimaryButton disabled={!isValidForm} fullWidth onClick={this.handleSubmit}>Confirmar</PrimaryButton>
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.ui.isNewCustomerModalOpen,
  companies: state.company.list,
  selectedCompany: state.company.selected,
  userList: state.user.list,
})

const mapDispatchToProps = dispatch => ({
  createCustomer: customer => dispatch(createCustomer(customer)),
  closeModal: () => dispatch(closeNewCustomerModal()),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchCustomers: () => dispatch(fetchCustomers()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(NewCustomerModal)
