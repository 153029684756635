import React from 'react'

import utilsService from '../../services/utils'

import './Avatar.scss'

const Avatar = ({ small, username, withSpacing }) => {
  const initials = utilsService.getInitialsFromUsername(username)

  return (
    <div title={ username } className={ 'avatar' + (small ? ' avatar--small' : '') + (withSpacing ? ' avatar--with-spacing' : '') }>{ initials }</div>
  )
}

export default Avatar
