import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import { closeConfirmModal } from '../../../actions/ui'
import PrimaryButton from '../../PrimaryButton'
import SecondaryButton from '../../SecondaryButton'

import './ConfirmModal.scss'

Modal.setAppElement('#root')

class ConfirmModal extends Component {
  render() {

    const { message, comment, confirmButtonLabel, cancelButtonLabel } = this.props
    return (
      <Modal
        overlayClassName="confirm-modal-overlay"
        className="confirm-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.handleCancel}
      >
        <button className="confirm-modal__close" onClick={this.props.handleCancel}></button>
        <h1 className="confirm-modal__heading">{message}</h1>
        <p className="confirm-modal__description">{comment}</p>
        <div className="confirm-modal__buttons">
          <PrimaryButton fullWidth onClick={this.props.handleConfirm}>
            {confirmButtonLabel}
          </PrimaryButton> <br /> <br />
          <SecondaryButton fullWidth onClick={this.props.handleCancel}>
            {cancelButtonLabel}
          </SecondaryButton>
        </div>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeConfirmModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ConfirmModal)