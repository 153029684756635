export const PENDING_STORAGE = 'Pendente'

export const ACTIONS = {
    VIEW: 'VIEW',
    CREATE: 'CREATE',
    UPDATE: 'UPDATE',
    DELETE: 'DELETE',
    ATTACH: 'ATTACH',
    DOWNLOAD: 'DOWNLOAD',
    DOWNLOAD_ALL: 'DOWNLOAD_ALL'
}

export const TYPES = {
    TRIALS: 'TRIALS',
    DOCUMENTS: 'DOCUMENTS',
    USERS: 'USERS'
}

export const STATUS = {
    PUBLIC: 'public',
    PRIVATE: 'private'
}
