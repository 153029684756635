import axios from 'axios'

import config from '../config'

const getAll = () => {
  return axios.get(`${config.baseUrl}/users`, {
    headers: {
      'Authorization': `Bearer ${ sessionStorage.getItem('metodocToken') }`,
    },
  })
}

const getById = id => {
  return axios.get(`${config.baseUrl}/users/${id}`, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('metodocToken')}`,
    },
  })
}

const createUser = user => {
  return axios.post(`${config.baseUrl}/users`, user, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('metodocToken')}`,
    },
  })
}

const updateUser = user => {
  return axios.put(`${config.baseUrl}/users/${user.id}`, user, {
    headers: {
      'Authorization': `Bearer ${sessionStorage.getItem('metodocToken')}`,
    },
  })
}

export default { getAll, getById, createUser, updateUser }
