import React, { Component } from 'react'
import { Link, Redirect } from 'react-router-dom'

import LoginBox from '../../components/LoginBox'
import PrimaryButton from '../../components/PrimaryButton'

import loginService from '../../services/login'
import authService from '../../services/auth'

import './Login.scss'
import { connect } from 'react-redux';
import { openLoaderModal, closeLoaderModal } from '../../actions/ui'
import LoaderModal from '../../components/modals/LoaderModal';

class Login extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      password: '',
      loggedInSuccessfully: false,
      hasErrors: false,
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()

    const params = {
      email: this.state.email,
      password: this.state.password,
    }

    this.props.openLoaderModal()
    loginService.login(params).then(response => {
      sessionStorage.setItem('metodocToken', response.data.token)
      sessionStorage.setItem('metodocFirstAccess', response.data.firstAccess)
      sessionStorage.setItem('metodocUsername', response.data.name)
      sessionStorage.setItem('metodocUserId', response.data.id)
      sessionStorage.setItem('metodocPermission', response.data.permission)
      sessionStorage.setItem('metodocCompanyId', response.data.companyId)
      this.setState({ loggedInSuccessfully: true })
    }).catch(error => {
      this.setState({ hasErrors: true })
    }).then(() => {
      this.props.closeLoaderModal()
    })
  }

  render() {
    const errorMessage = () => {
      if (this.state.hasErrors) {
        return <p className="login-screen__has-error">E-mail ou senha inválidos</p>
      } else {
        return null
      }
    }

    if (authService.isFirstAccess()) {
      return <Redirect to='/changePassword' />
    } else if (this.state.loggedInSuccessfully || authService.isLoggedIn()) {
      return <Redirect to='/trials' />
    } else {
      return (
        <div className='login-screen'>
          <LoginBox subheader={'Entre com o seu login para ter acesso'}>
            {errorMessage()}
            <form className="login-screen__form" action="#">
              <input
                className="login-screen__input"
                type="text"
                placeholder="E-mail"
                onChange={this.handleEmailChange}
                value={this.state.email}
              />
              <input
                className="login-screen__input"
                type="password"
                placeholder="Senha"
                onChange={this.handlePasswordChange}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleSubmit(e)
                  }
                }}
                value={this.state.password}
              />
              <PrimaryButton fullWidth onClick={this.handleSubmit}>Entrar</PrimaryButton>
              <Link to='/forgotPassword' className="login-screen__link">Esqueci minha senha</Link>
            </form>
          </LoginBox>
          <LoaderModal />
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal())
})

export default connect(mapStateToProps, mapDispatchToProps)(Login)
