import {
  FETCH_USERS_START,
  FETCH_USERS_ERROR,
  FETCH_USERS_SUCCESS,
  FETCH_USER_BY_ID_START,
  FETCH_USER_BY_ID_ERROR,
  SET_SELECTED_USER,
  CREATE_USER_SUCCESS,
  CREATE_USER_START,
  CREATE_USER_ERROR,
  UPDATE_USER_SUCCESS,
  UPDATE_USER_START,
  UPDATE_USER_ERROR,
  FETCH_USER_PROFILE_SUCCESS,
  FETCH_USER_PROFILE_START,
  FETCH_USER_PROFILE_ERROR,
} from '../actions/user'

const initialState = {
  list: [],
  selected: null,
  currentId: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_USERS_SUCCESS:
      return { ...state, list: action.users }

    case SET_SELECTED_USER:
      return { ...state, selected: action.user }

    case FETCH_USER_PROFILE_SUCCESS:
      return { ...state, loggedIn: action.profile }

    case FETCH_USER_PROFILE_START:
    case FETCH_USER_PROFILE_ERROR:
    case FETCH_USER_BY_ID_START:
    case FETCH_USER_BY_ID_ERROR:
    case FETCH_USERS_START:
    case FETCH_USERS_ERROR:
    case CREATE_USER_SUCCESS:
    case CREATE_USER_START:
    case CREATE_USER_ERROR:
    case UPDATE_USER_SUCCESS:
    case UPDATE_USER_START:
    case UPDATE_USER_ERROR:
    default:
      return state
  }
}
