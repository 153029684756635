import trialService from "../services/trial";

export const FETCH_TRIALS_START = "FETCH_TRIALS_START";
export const FETCH_TRIALS_ERROR = "FETCH_TRIALS_ERROR";
export const FETCH_TRIALS_SUCCESS = "FETCH_TRIALS_SUCCESS";
export const FETCH_TRIAL_BY_ID_START = "FETCH_TRIAL_BY_ID_START";
export const FETCH_TRIAL_BY_ID_ERROR = "FETCH_TRIAL_BY_ID_ERROR";
export const FETCH_TRIAL_BY_ID_SUCCESS = "FETCH_TRIAL_BY_ID_SUCCESS";
export const FETCH_TRIAL_DOCUMENTS_BY_ID_START =
  "FETCH_TRIAL_DOCUMENTS_BY_ID_START";
export const FETCH_TRIAL_DOCUMENTS_BY_ID_ERROR =
  "FETCH_TRIAL_DOCUMENTS_BY_ID_ERROR";
export const FETCH_TRIAL_DOCUMENTS_BY_ID_SUCCESS =
  "FETCH_TRIAL_DOCUMENTS_BY_ID_SUCCESS";
export const CREATE_TRIAL_START = "CREATE_TRIAL_START";
export const CREATE_TRIAL_ERROR = "CREATE_TRIAL_ERROR";
export const CREATE_TRIAL_SUCCESS = "CREATE_TRIAL_SUCCESS";
export const UPDATE_TRIAL_START = "UPDATE_TRIAL_START";
export const UPDATE_TRIAL_ERROR = "UPDATE_TRIAL_ERROR";
export const UPDATE_TRIAL_SUCCESS = "UPDATE_TRIAL_SUCCESS";
export const ADD_USERS_TO_TRIAL_START = "ADD_USERS_TO_TRIAL_START";
export const ADD_USERS_TO_TRIAL_ERROR = "ADD_USERS_TO_TRIAL_ERROR";
export const ADD_USERS_TO_TRIAL_SUCCESS = "ADD_USERS_TO_TRIAL_SUCCESS";
export const FETCH_ACTIVITY_START = "FETCH_ACTIVITY_START";
export const FETCH_ACTIVITY_ERROR = "FETCH_ACTIVITY_ERROR";
export const FETCH_ACTIVITY_SUCCESS = "FETCH_ACTIVITY_SUCCESS";
export const ADD_ACTIVITY_START = "ADD_ACTIVITY_START";
export const ADD_ACTIVITY_ERROR = "ADD_ACTIVITY_ERROR";
export const ADD_ACTIVITY_SUCCESS = "ADD_ACTIVITY_SUCCESS";
export const REMOVE_TRIAL_START = "REMOVE_TRIAL_START";
export const REMOVE_TRIAL_ERROR = "REMOVE_TRIAL_ERROR";
export const REMOVE_TRIAL_SUCCESS = "REMOVE_TRIAL_SUCCESS";
export const DOWNLOAD_ALL_DOCUMENTS_START = "DOWNLOAD_ALL_DOCUMENTS_START";
export const DOWNLOAD_ALL_DOCUMENTS_ERROR = "DOWNLOAD_ALL_DOCUMENTS_ERROR";
export const DOWNLOAD_ALL_DOCUMENTS_SUCCESS = "DOWNLOAD_ALL_DOCUMENTS_SUCCESS";
export const SET_CURRENT_TRIAL = "SET_CURRENT_TRIAL";
export const SET_CURRENT_CUSTOMER_TRIALS = "SET_CURRENT_CUSTOMER_TRIALS";
export const ADD_DOCUMENT_TO_PROCESS_LIST = "ADD_DOCUMENT_TO_PROCESS_LIST";
export const SET_CURRENT_TRIALS_PAGE = "SET_CURRENT_TRIALS_PAGE";

export const fetchTrialsStart = () => ({
  type: FETCH_TRIALS_START,
});

export const fetchTrialsError = () => ({
  type: FETCH_TRIALS_ERROR,
});

export const fetchTrialsSuccess = (trials) => ({
  type: FETCH_TRIALS_SUCCESS,
  trials,
});

export const fetchTrials = ({ customerId, page, sort, search }) => (
  dispatch
) => {
  dispatch(fetchTrialsStart());

  return trialService
    .getAllForUser(customerId, page, sort, search)
    .then((response) => {
      return dispatch(fetchTrialsSuccess(response.data));
    });
};

export const fetchTrialByIdStart = () => ({
  type: FETCH_TRIAL_BY_ID_START,
});

export const fetchTrialByIdError = () => ({
  type: FETCH_TRIAL_BY_ID_ERROR,
});

export const fetchTrialByIdSuccess = (trial) => ({
  type: FETCH_TRIAL_BY_ID_SUCCESS,
  trial,
});

export const fetchTrialById = (id) => (dispatch) => {
  dispatch(fetchTrialByIdStart());

  return trialService.getById(id).then((response) => {
    return dispatch(fetchTrialByIdSuccess(response.data));
  });
};

export const fetchTrialDocumentsByIdStart = (refresh) => ({
  type: FETCH_TRIAL_DOCUMENTS_BY_ID_START,
  refresh,
});

export const fetchTrialDocumentsByIdError = () => ({
  type: FETCH_TRIAL_DOCUMENTS_BY_ID_ERROR,
});

export const fetchTrialDocumentsByIdSuccess = (docs) => ({
  type: FETCH_TRIAL_DOCUMENTS_BY_ID_SUCCESS,
  docs,
});

export const fetchTrialDocumentsById = (params) => (dispatch) => {
  dispatch(fetchTrialDocumentsByIdStart(params.page === 1));

  return trialService.getDocumentsById(params).then((response) => {
    return dispatch(fetchTrialDocumentsByIdSuccess(response.data));
  });
};

export const createTrialStart = () => ({
  type: CREATE_TRIAL_START,
});

export const createTrialError = () => ({
  type: CREATE_TRIAL_ERROR,
});

export const createTrialSuccess = () => ({
  type: CREATE_TRIAL_SUCCESS,
});

export const createTrial = (trial) => (dispatch) => {
  dispatch(createTrialStart());

  return trialService.createTrial(trial).then(() => {
    return dispatch(createTrialSuccess());
  });
};

export const addUsersToTrialStart = () => ({
  type: ADD_USERS_TO_TRIAL_START,
});

export const addUsersToTrialError = () => ({
  type: ADD_USERS_TO_TRIAL_ERROR,
});

export const addUsersToTrialSuccess = () => ({
  type: ADD_USERS_TO_TRIAL_SUCCESS,
});

export const addUsersToTrial = (trialId, users) => (dispatch) => {
  dispatch(addUsersToTrialStart());

  return trialService.addUsersToTrial(trialId, users).then(() => {
    return dispatch(addUsersToTrialSuccess());
  });
};

export const setSelectedTrial = (trial) => ({
  type: SET_CURRENT_TRIAL,
  trial,
});

export const setCurrentTrialsPage = (page) => ({
  type: SET_CURRENT_TRIALS_PAGE,
  page,
});

export const setCurrentCustomerTrials = (customerTrials) => ({
  type: SET_CURRENT_CUSTOMER_TRIALS,
  customerTrials,
});

export const fetchActivityStart = (refresh) => ({
  type: FETCH_ACTIVITY_START,
  refresh,
});

export const fetchActivityError = () => ({
  type: FETCH_ACTIVITY_ERROR,
});

export const fetchActivitySuccess = (activities) => ({
  type: FETCH_ACTIVITY_SUCCESS,
  activities,
});

export const fetchActivity = (params) => (dispatch) => {
  dispatch(fetchActivityStart(params.page === 1));

  return trialService.getActivitiesForTrial(params).then((response) => {
    return dispatch(fetchActivitySuccess(response.data));
  });
};

export const addActivityStart = () => ({
  type: ADD_ACTIVITY_START,
});

export const addActivityError = () => ({
  type: ADD_ACTIVITY_ERROR,
});

export const addActivitySuccess = (activity) => ({
  type: ADD_ACTIVITY_SUCCESS,
  activity,
});

export const addActivity = (activity) => (dispatch) => {
  dispatch(addActivityStart());
  return trialService.addActivityToTrial(activity).then((response) => {
    return dispatch(addActivitySuccess(response.data));
  });
};

export const updateTrialStart = () => ({
  type: UPDATE_TRIAL_START,
});

export const updateTrialError = () => ({
  type: UPDATE_TRIAL_ERROR,
});

export const updateTrialSuccess = ({ id, trial }) => ({
  type: UPDATE_TRIAL_SUCCESS,
  trial: { id, ...trial },
});

export const updateTrial = (id, trial, additionalInfo) => (dispatch) => {
  dispatch(updateTrialStart());

  return trialService.updateTrial(id, trial, additionalInfo).then(() => {
    return dispatch(updateTrialSuccess({ id, trial: trial }));
  });
};

export const removeTrialStart = () => ({
  type: REMOVE_TRIAL_START,
});

export const removeTrialError = () => ({
  type: REMOVE_TRIAL_ERROR,
});

export const removeTrialSuccess = () => ({
  type: REMOVE_TRIAL_SUCCESS,
});

export const removeTrial = (trial) => (dispatch) => {
  dispatch(removeTrialStart());

  return trialService.removeTrial(trial).then(() => {
    return dispatch(removeTrialSuccess());
  });
};

export const downloadAllDocumentsStart = () => ({
  type: DOWNLOAD_ALL_DOCUMENTS_START,
});

export const downloadAllDocumentsError = () => ({
  type: DOWNLOAD_ALL_DOCUMENTS_ERROR,
});

export const downloadAllDocumentsSuccess = () => ({
  type: DOWNLOAD_ALL_DOCUMENTS_SUCCESS,
});

export const downloadAllDocuments = (trial) => (dispatch) => {
  dispatch(downloadAllDocumentsStart());

  return trialService.downloadAllDocuments(trial).then(() => {
    return dispatch(downloadAllDocumentsSuccess());
  });
};

const addDocToProcessList = (doc) => ({
  doc,
  type: ADD_DOCUMENT_TO_PROCESS_LIST,
});

// The object to be used here has the following format:
/*
  {
    code: String,
    date: Date,
    expiryDate?: Date
    name: String,
    observation: String,
    physicalStorageLocation: "Pendente" | String
    trial: {
      customer: Object,
      users: ArrayObject
      customerId: Number,
      description: String,
      id: Number,
      name: String,
      status: "private" | "public"
    }, 
    trialId: Number
    updatedAt: Date
    url: String
  }
*/
export const addDocToList = (doc) => (dispatch) => {
  dispatch(addDocToProcessList(doc));
};
