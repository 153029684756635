import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import Loading from "../../../components/Loader";
import documentService from "../../../services/document";

import {
  setSelectedDocument,
  getDocAuthorization
} from "../../../actions/document";
import {
  closeInfoDocumentModal,
  openLoaderModal,
  closeLoaderModal,
  openErrorModal,
} from "../../../actions/ui";

import { addActivity } from "../../../actions/trial";
import "./InfoDocumentModal.scss";
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

class InfoDocumentModal extends Component {
  constructor() {
    super();

    this.state = {
      code: "",
      loadingFile: false,
      name: "",
      file: "",
      filename: "",
      date: "",
      expiryDate: "",
      displayDate: null,
      displayExpiryDate: null,
      observation: "",
      hasFileUpdate: false,
      fileInfo: null
    };

    this.handleAfterOpen = this.handleAfterOpen.bind(this);
    this.handleAfterClose = this.handleAfterClose.bind(this);
  }

  async handleAfterOpen() {
    documentService.getFileInfo(`${this.props.infoDoc?.trialId}-${this.props.infoDoc?.url.split('/')[this.props.infoDoc?.url.indexOf('/') - 1]}`).then((response) => {
      this.setState({ fileInfo: response });
    });
  }

  roundToDecimal(value, decimalPlaces) {
    const multiplier = 10 ** decimalPlaces;
    return Math.round(value * multiplier) / multiplier;
  }
  
  formatBytes(bytes, decimalPlaces = 2) {
    if (bytes === 0) return '0 Bytes';
  
    const k = 1024;
    const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
  
    const i = parseInt(Math.floor(Math.log(bytes) / Math.log(k)));
  
    return `${this.roundToDecimal(bytes / Math.pow(k, i), decimalPlaces)} ${sizes[i]}`;
  }

  handleAfterClose() {
    this.setState({
      code: "",
      loadingFile: false,
      name: "",
      file: "",
      filename: "",
      date: "",
      expiryDate: "",
      displayDate: null,
      displayExpiryDate: null,
      observation: "",
      hasFileUpdate: false,
    });
  }

  render() {
    const lastFileInfo = this.state.fileInfo?.data?.infos?.[this.state.fileInfo?.data?.infos.length - 1] || {};
    const { date = '', companyName = '', userName = '' } = lastFileInfo;

    const dbDate = new Date(date);
    const formattedDate = dbDate.toLocaleDateString('pt-BR', { day: '2-digit', month: '2-digit', year: 'numeric' });
    const formattedTime = dbDate.toLocaleTimeString('pt-BR', { hour: '2-digit', minute: '2-digit', second: '2-digit' });
    const formattedDateTime = `${formattedDate} - ${formattedTime}`;

    return (
        <Modal
          overlayClassName="info-doc-modal-overlay"
          className="info-doc-modal"
          isOpen={this.props.isOpen}
          onRequestClose={this.props.closeModal}
          onAfterClose={this.handleAfterClose}
          onAfterOpen={this.handleAfterOpen}
        >
          <button
            className="info-doc-modal__close"
            onClick={this.props.closeModal}
          ></button>
          <h1 className="info-doc-modal__heading">Informações do documento</h1>
          <form className="info-doc-modal__form">
          {this.state.fileInfo === null && <Loading />}
          {this.state.fileInfo && (
            <ul className="lst-informations">
              <li>
                <strong>Data e Hora da Digitalização:</strong> { formattedDateTime }
              </li>
              <li>
                <strong>Quem Digitalizou:</strong> { userName }
              </li>
              {/* <li>
                <strong>Local da Digitalização:</strong> { companyName }
              </li> */}
              <li>
                <strong>Tamanho:</strong> { this.formatBytes(this.state.fileInfo?.data.filesize) || '' }
              </li>
              <li>
                <strong>Formato:</strong> { this.props.infoDoc?.url.split('.')[this.props.infoDoc?.url.indexOf('/') - 1].toUpperCase() }
              </li>
            </ul>
          )}
          </form>
        </Modal>
      );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.ui.isInfoDocumentModalOpen,
  infoDoc: state.doc.selected,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => { 
    dispatch(closeInfoDocumentModal())
  },
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
  setSelectedDoc: (doc) => dispatch(setSelectedDocument(doc)),
  getAuthorization: (trialId, code, docType) =>
    dispatch(getDocAuthorization(trialId, code, docType)),
  addActivity: (activity) => dispatch(addActivity(activity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(InfoDocumentModal);
