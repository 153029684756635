import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import LoginBox from '../../components/LoginBox'
import PrimaryButton from '../../components/PrimaryButton'

import { openLoaderModal, closeLoaderModal } from '../../actions/ui';

import loginService from '../../services/login'
import authService from '../../services/auth'
import validator from './validator'

import './ChangePassword.scss'

class ChangePassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      password: '',
      newPassword: '',
      newPasswordConfirmation: '',
      passwordChangedSuccessfully: false,
      passwordError: false,
      unmatchedPasswords: false,
      serverError: false,
    }

    this.handlePasswordChange = this.handlePasswordChange.bind(this)
    this.handleNewPasswordChange = this.handleNewPasswordChange.bind(this)
    this.handleNewPasswordConfirmationChange = this.handleNewPasswordConfirmationChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handlePasswordChange(e) {
    this.setState({ password: e.target.value })
  }

  handleNewPasswordChange(e) {
    this.setState({ newPassword: e.target.value })
  }

  handleNewPasswordConfirmationChange(e) {
    this.setState({ newPasswordConfirmation: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()

    const params = {
      password: this.state.password,
      newPassword: this.state.newPassword,
    }

    const { newPassword, newPasswordConfirmation } = this.state

    if (!validator.validatePasswords(newPassword, newPasswordConfirmation)) {
      this.setState({ passwordError: true, unmatchedPasswords: false, serverError: false })
    }

    if (validator.validatePasswords(newPassword, newPasswordConfirmation) && !validator.passwordsMatch(newPassword, newPasswordConfirmation)) {
      this.setState({ unmatchedPasswords: true, passwordError: false, serverError: false })
    }
    this.props.openLoaderModal()
    if (validator.validatePasswords(newPassword, newPasswordConfirmation) && validator.passwordsMatch(newPassword, newPasswordConfirmation)) {
      loginService.changePassword(params).then(response => {
        sessionStorage.setItem('metodocToken', response.data.token)
        sessionStorage.setItem('metodocFirstAccess', response.data.firstAccess)
        this.setState({ passwordChangedSuccessfully: true })
      }).catch(err => {
        this.setState({ serverError: true })
      }).then(() => {
        this.props.closeLoaderModal()
      })
    }
  }

  render() {
    if (this.state.passwordChangedSuccessfully) {
      return <Redirect to='/changePasswordSuccess' />
    } else if (!authService.isLoggedIn || !authService.isFirstAccess()) {
      return <Redirect to='/trials' />
    } else {
      const passwordError = () => {
        if (this.state.passwordError) {
          return <p className="change-password-screen__has-error">A senha precisa conter pelo menos 8 caracteres</p>
        } else {
          return null
        }
      }

      const unmatchedPasswords = () => {
        if (this.state.unmatchedPasswords) {
          return <p className="change-password-screen__has-error">As senhas são diferentes</p>
        } else {
          return null
        }
      }

      const serverError = () => {
        if (this.state.serverError) {
          return <p className="change-password-screen__has-error">Erro inesperado! Tente novamente</p>
        } else {
          return null
        }
      }

      return (
        <div className='change-password-screen'>
          <LoginBox subheader={'Para realizar o seu acesso é necessário trocar a sua senha'}>
            {passwordError()}
            {unmatchedPasswords()}
            {serverError()}
            <form className="change-password-screen__form" action="#">
              <input
                className="change-password-screen__input"
                type="password"
                placeholder="Senha atual"
                onChange={this.handlePasswordChange}
                value={this.state.password}
              />
              <input
                className="change-password-screen__input"
                type="password"
                placeholder="Nova senha"
                onChange={this.handleNewPasswordChange}
                value={this.state.newPassword}
              />
              <input
                className="change-password-screen__input"
                type="password"
                placeholder="Confirmação da nova senha"
                onChange={this.handleNewPasswordConfirmationChange}
                value={this.state.newPasswordConfirmation}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleSubmit(e)
                  }
                }}
              />
              <PrimaryButton fullWidth onClick={this.handleSubmit}>Trocar senha</PrimaryButton>
            </form>
          </LoginBox>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword)