import {
  SET_SELECTED_COMPANY,
  FETCH_COMPANIES_SUCCESS,
  FETCH_COMPANIES_START,
  FETCH_COMPANIES_ERROR,
  CREATE_COMPANY_SUCCESS,
  CREATE_COMPANY_START,
  CREATE_COMPANY_ERROR,
  UPDATE_COMPANY_SUCCESS,
  UPDATE_COMPANY_START,
  UPDATE_COMPANY_ERROR,
} from '../actions/company'

const initialState = {
  selected: {
    id: 0,
  },
  list: []
}

export default (state = initialState, action) => {
  switch (action.type) {
    case SET_SELECTED_COMPANY:
      return { ...state, selected: action.selected }

    case FETCH_COMPANIES_SUCCESS:
      return { ...state, list: action.companies }

    case FETCH_COMPANIES_START:
    case FETCH_COMPANIES_ERROR:
    case CREATE_COMPANY_SUCCESS:
    case CREATE_COMPANY_START:
    case CREATE_COMPANY_ERROR:
    case UPDATE_COMPANY_SUCCESS:
    case UPDATE_COMPANY_START:
    case UPDATE_COMPANY_ERROR:
    default:
      return state
  }
}
