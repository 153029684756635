import {
  FETCH_CUSTOMERS_START,
  FETCH_CUSTOMERS_ERROR,
  FETCH_CUSTOMERS_SUCCESS,
  SET_SELECTED_CUSTOMER,
  CREATE_CUSTOMER_SUCCESS,
  CREATE_CUSTOMER_START,
  CREATE_CUSTOMER_ERROR,
  UPDATE_CUSTOMER_SUCCESS,
  UPDATE_CUSTOMER_START,
  UPDATE_CUSTOMER_ERROR,
} from '../actions/customer'

const initialState = {
  list: [],
  selected: { id: 0 },
  currentId: null
}

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_CUSTOMERS_SUCCESS:
      return { ...state, list: action.customers }

    case SET_SELECTED_CUSTOMER:
      return { ...state, selected: action.customer }

    case FETCH_CUSTOMERS_START:
    case FETCH_CUSTOMERS_ERROR:
    case CREATE_CUSTOMER_SUCCESS:
    case CREATE_CUSTOMER_START:
    case CREATE_CUSTOMER_ERROR:
    case UPDATE_CUSTOMER_SUCCESS:
    case UPDATE_CUSTOMER_START:
    case UPDATE_CUSTOMER_ERROR:
    default:
      return state
  }
}
