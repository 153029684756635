import React, { Component } from "react";
import { connect } from "react-redux";
import Select from "react-select";
import Modal from "react-modal";
import DatePicker from "react-datepicker";
import MaskedTextInput from "react-text-mask";

import * as azureStorage from "@hobbytraceorg/azure-storage-browser";
import removeExtesionFromFilename from "../../../utils/removeExtensionFromFileName";
import getExtensionFromFileName from "../../../utils/getExtensionFromFileName";

import config from "../../../config";
import { PENDING_STORAGE } from "../../../config/consts";

import PrimaryButton from "../../PrimaryButton";

import {
  addDocument,
  getDocAuthorization,
  setSelectedDocument,
} from "../../../actions/document";
import {
  closeAddDocumentModal,
  closeLoaderModal,
  openLoaderModal,
  openErrorModal,
} from "../../../actions/ui";

import utilsService from "../../../services/utils";

import "./AddDocumentModal.scss";
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

class AddDocumentModal extends Component {
  constructor() {
    super();
    this.state = {
      loadingFile: false,
      name: "",
      file: "",
      filename: "",
      date: "",
      expiryDate: "",
      displayDate: null,
      displayExpiryDate: null,
      dateValid: true,
      expiryDateValid: true,
      observation: "",
      selectedTrial: {},
    };
    this.isDateWithinRange = this.isDateWithinRange.bind(this);
    this.handleAfterOpen = this.handleAfterOpen.bind(this);
    this.handleTrialChange = this.handleTrialChange.bind(this);
    this.handleNameChange = this.handleNameChange.bind(this);
    this.handleDateChange = this.handleDateChange.bind(this);
    this.handleExpiryDateChange = this.handleExpiryDateChange.bind(this);
    this.handleFileChange = this.handleFileChange.bind(this);
    this.handleObservationChange = this.handleObservationChange.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
  }

  handleAfterOpen() {
    this.initDocument();
  }

  initDocument() {
    let formattedTrials = this.getFormattedTrials(this.props.trials);
    this.setState({
      loadingFile: false,
      name: "",
      file: "",
      filename: "",
      date: "",
      expiryDate: "",
      dateValid: true,
      expiryDateValid: true,
      displayDate: null,
      displayExpiryDate: null,
      observation: "",
      formattedTrials: formattedTrials,
    });
  }

  getFormattedTrials(trials) {
    if (trials)
      return trials.map((trial) => {
        return {
          id: trial.id,
          name: trial.name,
          label: trial.name,
          value: trial.id,
        };
      });
    else return [];
  }

  handleTrialChange(trial) {
    this.setState({ selectedTrial: trial });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }


  handleFileChange(e) {
    const file = e.target.files[0];

    const name = removeExtesionFromFilename(file.name);

    const date = new Date();

    if (utilsService.verifyDocument(file)) {
      this.setState({
        file,
        filename: file.name,
        name,
        displayDate: date,
        date: date.toISOString(),
      });
    }
  }

  /* DATES */
  handleExpiryDateChange(e) {
    if (!e) {
      this.setState({ expiryDate: "", displayExpiryDate: null });
    } else {
      const parsedDate = new Date(e);
      const isValidDate = this.isDateWithinRange(parsedDate.toISOString().split('T')[0]);
      
      this.setState({ expiryDateValid: isValidDate });
      this.setState({ expiryDate: e.toISOString(), displayExpiryDate: e });
    }
  }

  handleDateChange(e) {
    if (e) {
      const parsedDate = new Date(e);
      const isValidDate = this.isDateWithinRange(parsedDate.toISOString().split('T')[0]);

      this.setState({ dateValid: isValidDate });
      this.setState({ date: e.toISOString(), displayDate: e });
    } else {
      this.setState({ date: '', displayDate: null });
      this.setState({ dateValid: false });
    }
  }

  isDateWithinRange(parsedDate) {
    const lastDate = new Date('2100-01-01');
    const earliestDate = new Date('1900-01-01');
    const inputDate = new Date(parsedDate);

    return inputDate >= earliestDate && inputDate <= lastDate;
  }
  /* // DATES */

  handleObservationChange(e) {
    this.setState({ observation: e.target.value });
  }

  uploadFile(doc) {
    const docType = getExtensionFromFileName(doc.file.name);
    let contentType = "application/octet-stream";

    if (docType === "pdf") {
      contentType = "application/pdf";
    } else if (docType === "jpg" || docType === "jpeg" || docType === "png") {
      contentType = `image/${docType}`;
    } else {
      contentType = "application/octet-stream";
    }

    return new Promise((resolve, reject) => {
      this.props
        .getAuthorization(doc.trialId, null, docType)
        .then((action) => {
          let authorization = action.authorization;
          const storageService = azureStorage.createBlobServiceWithSas(
            authorization.host,
            config.azureToken
          );
          storageService.createContainerIfNotExists(
            authorization.share,
            (err) => {
              if (err) return reject(err);
              storageService.createBlockBlobFromBrowserFile(
                authorization.share,
                `${authorization.directory}/${authorization.filename}`,
                doc.file,
                { contentSettings: { contentType } },
                (err) => {
                  if (err) return reject(err);
                  resolve(authorization);
                }
              );
            }
          );
        })
        .catch((e) => {
          this.props.closeLoaderModal();
          this.props.openErrorModal();
        });
    });
  }

  handleSubmit() {
    let trialId = (this.props.trial || this.state.selectedTrial).id;

    this.props.openLoaderModal();
    this.uploadFile({ trialId: trialId, file: this.state.file })
      .then((upload) => {
        const { name, date, expiryDate, observation } = this.state;

        const documentData = {
          code: upload.code,
          url: upload.url,
          trialId: trialId,
          name: name,
          physicalStorageLocation: PENDING_STORAGE,
          date: date,
          observation: observation,
        };

        if (expiryDate && expiryDate !== "") {
          documentData.expiryDate = expiryDate;
        }

        this.props.addDocument(documentData).then(() => {
          if (this.props.onClose) this.props.onClose();
          this.props.closeModal();
          this.props.closeLoaderModal();
        });
      })
      .catch((e) => {
        this.props.closeLoaderModal();
        this.props.openErrorModal();
      });
  }

  render() {
    const { name, date, file, dateValid, expiryDateValid } = this.state;
    const isValidForm =
      name &&
      name.length > 0 &&
      date &&
      (date.length > 0 && dateValid) &&
      file &&
      file.name &&
      file.size > 0;
    const isBigFile = file && file.size && file.size / 1024 / 1024 > 9;
    return (
      <Modal
        overlayClassName="add-doc-modal-overlay"
        className="add-doc-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button
          className="add-doc-modal__close"
          onClick={this.props.closeModal}
        ></button>
        <h1 className="add-doc-modal__heading">Adicionar novo documento</h1>
        <form className="add-doc-modal__form">
          {!this.props.trial && (
            <Select
              className="modal-select-container"
              classNamePrefix="modal-select"
              placeholder="Processo..."
              options={this.state.formattedTrials}
              value={this.state.selectedTrial}
              onChange={this.handleTrialChange}
            />
          )}
          <label className="add-doc-modal__form__custom-file-input">
            <div className="add-doc-modal__form__custom-file-input__label">
              {this.state.loadingFile
                ? "Carregando arquivo..."
                : this.state.filename !== ""
                ? this.state.filename
                : "Anexar documento"}
            </div>
            <span className="add-doc-modal__form__custom-file-input__icon"></span>
            <input
              className="add-doc-modal__form__file-input"
              type="file"
              accept="image/jpeg,image/jpg,image/png,application/pdf,.re,.pfx,.xml,.xls,.xlsx,.xlsm,.doc,.docx,.docm"
              onChange={this.handleFileChange}
            />
          </label>
          {isBigFile && (
            <div className="add-doc-modal__form__input-warning">
              Arquivo com tamanho superior a 10 MB, pode demorar dependendo da
              sua conexão de internet.
            </div>
          )}
          <input
            className="add-doc-modal__form__input"
            type="text"
            onChange={this.handleNameChange}
            value={this.state.name}
            placeholder="Nome do documento (máx. 250 caracteres)"
            maxLength="250"
          />
          <DatePicker
            className={`add-doc-modal__form__input ${dateValid ? '' : 'error-input'}`}
            onChange={this.handleDateChange}
            selected={this.state.displayDate}
            placeholderText="Data do documento"
            dateFormat="dd/MM/yyyy"
            customInput={
              <MaskedTextInput
                type="text"
                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              />
            }
            onKeyDown={(event) => {
              if (event.key === "Enter" && isValidForm) {
                this.handleSubmit();
              }
            }}
          />
          <DatePicker
            className={`add-doc-modal__form__input ${expiryDateValid ? '' : 'error-input'}`}
            onChange={this.handleExpiryDateChange}
            selected={this.state.displayExpiryDate}
            placeholderText="Data de validade"
            dateFormat="dd/MM/yyyy"
            customInput={
              <MaskedTextInput
                type="text"
                mask={[/\d/, /\d/, "/", /\d/, /\d/, "/", /\d/, /\d/, /\d/, /\d/]}
              />
            }
            onKeyDown={(event) => {
              if (event.key === "Enter" && isValidForm) {
                this.handleSubmit();
              }
            }}
          />
          <textarea
            className="add-doc-modal__form__textarea"
            onChange={this.handleObservationChange}
            value={this.state.observation}
            placeholder="Assunto, Tipo Documental e Observações (máx. 250 caracteres)"
            maxLength="250"
            onKeyDown={(event) => {
              if (event.key === "Enter" && isValidForm) {
                this.handleSubmit();
              }
            }}
          ></textarea>
          <PrimaryButton
            disabled={!isValidForm}
            fullWidth
            onClick={this.handleSubmit}
          >
            Confirmar
          </PrimaryButton>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.ui.isAddDocumentModalOpen,
  docs: state.trial.docs,
  trials: state.trial.filteredByCustomer,
  trial: state.trial.selected,
  currentDoc: state.doc.selected,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => dispatch(closeAddDocumentModal()),
  getAuthorization: (trialId, code, docType) =>
    dispatch(getDocAuthorization(trialId, code, docType)),
  addDocument: (doc) => dispatch(addDocument(doc)),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
  setSelectedDoc: (doc) => dispatch(setSelectedDocument(doc)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AddDocumentModal);
