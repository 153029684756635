import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";
import Select from "react-select";

import PrimaryButton from "../../PrimaryButton";

import utilsService from "../../../services/utils";

import {
  closeEditTrialModal,
  openLoaderModal,
  closeLoaderModal,
  openErrorModal,
} from "../../../actions/ui";
import { fetchUsers } from "../../../actions/user";

import "./EditTrialModal.scss";
import { updateTrial } from "../../../actions/trial";

Modal.setAppElement("#root");

class EditTrialModal extends Component {
  constructor() {
    super();

    this.state = {
      name: "",
      status: "",
      description: "",
      selectedUsers: [],
      formattedUsers: [],
    };

    this.handleAfterOpen = this.handleAfterOpen.bind(this);
  }

  handleAfterOpen() {
    const statusMap = { public: "Público", private: "Privado" };
    this.props.fetchUsers().then((usersAction) => {
      const formattedUsers = usersAction.users.filter(
        (x) => x.company.id === this.props.trialToUpdate.customer.companyId
      );

      this.setState({
        name: this.props.trialToUpdate.name,
        status: {
          label: statusMap[this.props.trialToUpdate.status],
          value: this.props.trialToUpdate.status,
        },
        description: this.props.trialToUpdate.description,
        selectedUsers: this.props.trialToUpdate.users.map(({ id, name }) => ({
          id,
          name,
          label: name,
          value: id,
        })),
        formattedUsers,
      });
    });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleStatusChange(status) {
    this.setState({ status });
  }

  handleDescriptionChange(e) {
    this.setState({ description: e.target.value });
  }

  handleUsersChange(selectedUsers) {
    this.setState({ selectedUsers });
  }

  handleSubmit() {
    const originalTrial = {
      name: this.props.trialToUpdate.name,
      status: this.props.trialToUpdate.status,
      description: this.props.trialToUpdate.description,
      users: this.props.trialToUpdate.users.map((x) => x.id),
    };

    const updates = {
      name: this.state.name || originalTrial.name,
      status: this.state.status.name || originalTrial.status,
      description: this.state.description || originalTrial.description,
      users: this.state.selectedUsers.map((x) => x.id),
      allInfoUsers: this.state.selectedUsers,
    };

    const { allInfoUsers, ...updateWithoutAllInfoUsers } = updates;

    const additionalInfo = utilsService.formatDiff(
      originalTrial,
      updateWithoutAllInfoUsers,
      [...this.props.trialToUpdate.users, ...this.state.selectedUsers]
    );

    this.props.openLoaderModal();

    this.props
      .updateTrial(this.props.trialToUpdate.id, updates, additionalInfo)
      .then(() => {
        this.setState({ name: "", status: null, description: "", users: [] });
        this.props.closeModal();
      })
      .catch((e) => {
        this.props.openErrorModal();
      })
      .then(() => {
        this.props.closeLoaderModal();
      });
  }

  render() {
    const statusOptions = [
      { name: "public", label: "Público", value: "public" },
      { name: "private", label: "Privado", value: "private" },
    ];
    const { name, status, selectedUsers } = this.state;
    const isValidForm =
      name &&
      name.length > 0 &&
      status &&
      selectedUsers &&
      selectedUsers.length > 0;

    return (
      <Modal
        overlayClassName="trial-modal-overlay"
        className="trial-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button
          className="trial-modal__close"
          onClick={this.props.closeModal}
        ></button>
        <h1 className="trial-modal__heading">Editar processo</h1>
        <form className="trial-modal__form">
          <input
            className="trial-modal__form__input"
            type="text"
            placeholder="Nome do processo (máx. 250 caracteres)"
            onChange={(e) => {
              this.handleNameChange(e);
            }}
            value={this.state.name}
            maxLength="250"
          />
          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            options={statusOptions}
            onChange={(e) => {
              this.handleStatusChange(e);
            }}
            placeholder="Status do processo"
            value={this.state.status}
          />
          <textarea
            className="trial-modal__form__textarea"
            placeholder="Descrição (máx. 500 caracteres)"
            onChange={(e) => {
              this.handleDescriptionChange(e);
            }}
            value={this.state.description}
            maxLength="500"
          ></textarea>
          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Adicionar membros"
            options={this.state.formattedUsers}
            onChange={(e) => {
              this.handleUsersChange(e);
            }}
            value={this.state.selectedUsers}
            isMulti
          />
          <PrimaryButton
            disabled={!isValidForm}
            fullWidth
            onClick={(e) => {
              this.handleSubmit(e);
            }}
          >
            Confirmar
          </PrimaryButton>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.ui.isEditTrialModalOpen,
  userList: state.user.list,
  trialToUpdate: state.trial.selected,
});

const mapDispatchToProps = (dispatch) => ({
  updateTrial: (id, trial, additionalInfo) =>
    dispatch(updateTrial(id, trial, additionalInfo)),
  closeModal: () => dispatch(closeEditTrialModal()),
  fetchUsers: () => dispatch(fetchUsers()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditTrialModal);
