import {
  OPEN_NEW_TRIAL_MODAL,
  CLOSE_NEW_TRIAL_MODAL,
  TOGGLE_TRIAL_DETAIL_POPOVER,
  OPEN_ADD_USER_MODAL,
  CLOSE_ADD_USER_MODAL,
  OPEN_EDIT_TRIAL_MODAL,
  CLOSE_EDIT_TRIAL_MODAL,
  
  OPEN_ADD_DOCUMENT_MODAL,
  CLOSE_ADD_DOCUMENT_MODAL,
  OPEN_EDIT_DOCUMENT_MODAL,
  CLOSE_EDIT_DOCUMENT_MODAL,
  OPEN_ATTACH_DOCUMENT_MODAL,
  CLOSE_ATTACH_DOCUMENT_MODAL,
  
  OPEN_INFO_DOCUMENT_MODAL,
  CLOSE_INFO_DOCUMENT_MODAL,

  OPEN_EDIT_DOCUMENT_LOCATION_MODAL,
  CLOSE_EDIT_DOCUMENT_LOCATION_MODAL,
  TOGGLE_DOCUMENT_DETAIL_POPOVER,

  OPEN_NEW_COMPANY_MODAL,
  CLOSE_NEW_COMPANY_MODAL,
  OPEN_EDIT_COMPANY_MODAL,
  CLOSE_EDIT_COMPANY_MODAL,

  OPEN_NEW_USER_MODAL,
  CLOSE_NEW_USER_MODAL,
  OPEN_EDIT_USER_MODAL,
  CLOSE_EDIT_USER_MODAL,

  OPEN_NEW_CUSTOMER_MODAL,
  CLOSE_NEW_CUSTOMER_MODAL,
  OPEN_EDIT_CUSTOMER_MODAL,
  CLOSE_EDIT_CUSTOMER_MODAL,

  OPEN_ERROR_MODAL,
  CLOSE_ERROR_MODAL,

  OPEN_CONFIRM_MODAL,
  CLOSE_CONFIRM_MODAL,

  SET_SELECTED_TAB_START,
  OPEN_LOADER_MODAL,
  CLOSE_LOADER_MODAL
} from '../actions/ui'

const initialState = {
  isNewTrialModalOpen: false,
  showTrialDetailPopover: false,
  showDocumentDetailPopover: false,
  isAddUserModalOpen: false,
  isNewUserModalOpen: false,
  isCreateUserModalOpen: false,
  isEditTrialModalOpen: false,
  isAddDocumentModalOpen: false,
  isEditDocumentModalOpen: false,
  isAttachDocumentModalOpen: false,
  isInfoDocumentModalOpen: false,
  isConfirmModalOpen: false,
  isErrorModalOpen: false,
  errorModalTitle: undefined,
  errorModalMessage: undefined,
  isLoaderModalOpen: false,
  selectedTab: 'index',
}

export default (state = initialState, action) => {
  switch (action.type) {
    case OPEN_NEW_TRIAL_MODAL:
      return { ...state, isNewTrialModalOpen: true }

    case CLOSE_NEW_TRIAL_MODAL:
      return { ...state, isNewTrialModalOpen: false }

    case TOGGLE_TRIAL_DETAIL_POPOVER:
      return { ...state, showTrialDetailPopover: !state.showTrialDetailPopover }

    case OPEN_ADD_USER_MODAL:
      return { ...state, isAddUserModalOpen: true }

    case CLOSE_ADD_USER_MODAL:
      return { ...state, isAddUserModalOpen: false }

    case OPEN_EDIT_TRIAL_MODAL:
      return { ...state, isEditTrialModalOpen: true }

    case CLOSE_EDIT_TRIAL_MODAL:
      return { ...state, isEditTrialModalOpen: false }

    case OPEN_ADD_DOCUMENT_MODAL:
      return { ...state, isAddDocumentModalOpen: true }

    case CLOSE_ADD_DOCUMENT_MODAL:
      return { ...state, isAddDocumentModalOpen: false }

    case OPEN_EDIT_DOCUMENT_MODAL:
      return { ...state, isEditDocumentModalOpen: true }

    case CLOSE_EDIT_DOCUMENT_MODAL:
      return { ...state, isEditDocumentModalOpen: false }
    
    case OPEN_ATTACH_DOCUMENT_MODAL:
      return { ...state, isAttachDocumentModalOpen: true }

    case CLOSE_ATTACH_DOCUMENT_MODAL:
      return { ...state, isAttachDocumentModalOpen: false }

    case OPEN_INFO_DOCUMENT_MODAL:
      return { ...state, isInfoDocumentModalOpen: true }

    case CLOSE_INFO_DOCUMENT_MODAL:
      return { ...state, isInfoDocumentModalOpen: false }

    case OPEN_EDIT_DOCUMENT_LOCATION_MODAL:
      return { ...state, isEditDocumentLocationModalOpen: true }

    case CLOSE_EDIT_DOCUMENT_LOCATION_MODAL:
      return { ...state, isEditDocumentLocationModalOpen: false }

    case TOGGLE_DOCUMENT_DETAIL_POPOVER:
      return { ...state, showDocumentDetailPopover: !state.showDocumentDetailPopover }

    case OPEN_NEW_USER_MODAL:
      return { ...state, isNewUserModalOpen: true }

    case CLOSE_NEW_USER_MODAL:
      return { ...state, isNewUserModalOpen: false }

    case OPEN_EDIT_USER_MODAL:
      return { ...state, isEditUserModalOpen: true }

    case CLOSE_EDIT_USER_MODAL:
      return { ...state, isEditUserModalOpen: false }

    case OPEN_NEW_COMPANY_MODAL:
      return { ...state, isNewCompanyModalOpen: true }

    case CLOSE_NEW_COMPANY_MODAL:
      return { ...state, isNewCompanyModalOpen: false }

    case OPEN_EDIT_COMPANY_MODAL:
      return { ...state, isEditCompanyModalOpen: true }

    case CLOSE_EDIT_COMPANY_MODAL:
      return { ...state, isEditCompanyModalOpen: false }


    case OPEN_NEW_CUSTOMER_MODAL:
      return { ...state, isNewCustomerModalOpen: true }

    case CLOSE_NEW_CUSTOMER_MODAL:
      return { ...state, isNewCustomerModalOpen: false }

    case OPEN_EDIT_CUSTOMER_MODAL:
      return { ...state, isEditCustomerModalOpen: true }

    case CLOSE_EDIT_CUSTOMER_MODAL:
      return { ...state, isEditCustomerModalOpen: false }

    case OPEN_CONFIRM_MODAL:
      return { ...state, isConfirmModalOpen: true }

    case CLOSE_CONFIRM_MODAL:
      return { ...state, isConfirmModalOpen: false }

    case OPEN_ERROR_MODAL:
      return { ...state, isErrorModalOpen: true, errorModalTitle: action.title, errorModalMessage: action.message }

    case CLOSE_ERROR_MODAL:
      return { ...state, isErrorModalOpen: false, errorModalTitle: undefined, errorModalMessage: undefined }

    case SET_SELECTED_TAB_START:
      return { ...state, selectedTab: action.tab }

    case OPEN_LOADER_MODAL:
      return { ...state, isLoaderModalOpen: true }

    case CLOSE_LOADER_MODAL:
      return { ...state, isLoaderModalOpen: false }

    default:
      return state
  }
}
