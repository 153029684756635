import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import { closeErrorModal } from '../../../actions/ui'
import PrimaryButton from '../../PrimaryButton'

import './ErrorModal.scss'

Modal.setAppElement('#root')

class ErrorModal extends Component {
  render() {

    const DEFAULT_ERROR_TITLE = 'Houve um erro na operação'
    const DEFAULT_ERROR_MESSAGE = 'Por favor, tente novamente mais tarde.'

    const { title, message, isOpen } = this.props
    return (
      <Modal
        overlayClassName="alert-modal-overlay"
        className="alert-modal"
        isOpen={isOpen}
        onRequestClose={this.props.closeErrorModal}
      >
        <button className="alert-modal__close" onClick={this.props.closeErrorModal}></button>
        <h1 className="alert-modal__heading">{(!title || title === 'default') ? DEFAULT_ERROR_TITLE : title}</h1>
        <p className="alert-modal__description">{(!message || message === 'default') ? DEFAULT_ERROR_MESSAGE : message}</p>
        <PrimaryButton fullWidth onClick={this.props.closeErrorModal}>
          {'Ok'}
        </PrimaryButton> <br /> <br />
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.ui.isErrorModalOpen,
  title: state.ui.errorModalTitle,
  message: state.ui.errorModalMessage
})

const mapDispatchToProps = dispatch => ({
  closeErrorModal: () => dispatch(closeErrorModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ErrorModal)