import React, { Component, Fragment } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'
import Select from 'react-select'

import PrimaryButton from '../../PrimaryButton'

import { updateUser } from '../../../actions/user'
import { closeEditUserModal, openLoaderModal, closeLoaderModal, openErrorModal } from '../../../actions/ui'
import { fetchUsers } from '../../../actions/user'
import { fetchCustomersForUser, fetchCustomersByCompanyId } from "../../../actions/customer";
import authService from '../../../services/auth'

import utilsService from "../../../services/utils";

import './EditUserModal.scss'

Modal.setAppElement('#root')

const permissionOptions = [
  { name: 'user', label: 'Administrador', value: 'user' },
  { name: 'admin', label: 'Usuário', value: 'admin' }
]

class EditUserModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      company: null,
      permission: null,
      name: '',
      email: '',
      phone: '',
      displayPhone: '',
      position: '',
      role: '',
      listCustomers: [],
      allowedCustomers: [],
    }
    this.handleAfterOpen = this.handleAfterOpen.bind(this)
  }

  async handleAfterOpen() {
    const permissionMap = { admin: 'Usuário', user: 'Administrador' }
    let userToUpdate = {
      ...this.props.userToUpdate,
      permission: { label: permissionMap[this.props.userToUpdate.permission], value: this.props.userToUpdate.permission },
      company: this.props.companies.filter((x) => `${x.id}` === `${this.props.userToUpdate.company.id}`)[0],
      displayPhone: this.formatPhone(this.props.userToUpdate.phone)
    }
    this.setState(userToUpdate)
    
    await this.getCustomers(userToUpdate.company.id);
    const listCustomers = this.state.listCustomers;
    const allowedCustomers = [];
    this.setState({ allowedCustomers, listCustomers});
    this.getAllowedCustomers(userToUpdate.id, userToUpdate.company.id)
  }

  getCustomers(companyId) {
    fetchCustomersByCompanyId(companyId).then((response) => {
      const listCustomers = response.map((x) => ({
        id: x.id,
        name: x.name,
        label: x.name,
        value: x.id,
        company: x.companyId,
        companyId: x.companyId,
      }));
      this.setState({ listCustomers: listCustomers });
    });
  }

  getAllowedCustomers(userId, companyId) {
    fetchCustomersForUser(userId, companyId).then((response) => {
      const allowedCustomers = response.map((x) => ({
        id: x.id,
        name: x.name,
        label: x.name,
        value: x.id,
        company: x.companyId,
        companyId: x.companyId,
      }));
      this.setState({ allowedCustomers: allowedCustomers});
    });
  }

  handlePermissionChange(permission) {
    this.setState({ permission: permission })
  }

  async handleCompanyChange(company) {
    this.setState({ company: company })
    await this.getCustomers(company.id);
    const listCustomers = this.state.listCustomers;
    const allowedCustomers = [];
    this.setState({ allowedCustomers, listCustomers});
  }

  handleCustomerChange(allowedCustomers) {
    this.setState({ allowedCustomers: allowedCustomers});
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value })
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handlePhoneChange(e) {
    this.setState({ phone: this.numbersOnly(e.target.value), displayPhone: this.formatPhone(e.target.value) })
  }

  numbersOnly(phone) {
    if (!phone) return ""
    return phone.replace(/\D/g, "")  // Keep numbers only
  }

  formatPhone(phone) {
    return this.numbersOnly(phone)
      .replace(/^(\d{2})(\d)/g, "($1) $2") // Wraps the first two digits on columns
      .replace(/(\d)(\d{4})$/, "$1-$2")    // Adds the separator dash
  }

  handleRoleChange(e) {
    this.setState({ role: e.target.value })
  }

  handlePositionChange(e) {
    this.setState({ position: e.target.value })
  }

  handleSubmit() {
    this.props.fetchUsers().then(() => {
      const user = {
        ...this.state,
        companyId: this.state.company.id,
        permission: this.state.permission.value,
        company: undefined
      }
      delete user.listCustomers;
      this.props.openLoaderModal()
      this.props.updateUser(user).then(() => {
        this.setState({
          company: null,
          permission: null,
          name: '',
          email: '',
          phone: '',
          position: '',
          role: '',
          listCustomers: [],
          allowedCustomers: []
        })
        this.props.closeModal()
        if (this.props.onClose()) this.props.onClose()
      }).catch((e) => {
        this.props.openErrorModal()
      }).then(() => {
        this.props.closeLoaderModal()
      })
    })
  }

  render() {
    const { company, permission, name, email, phone, role } = this.state
    const isValidForm =
      (permission && permission.value) &&
      (company && company.id) &&
      (name && name.length > 0) &&
      (email && email.length > 0) &&
      (phone && phone.length > 0) &&
      (role && role.length > 0)

    return (
      <Modal
        overlayClassName="user-modal-overlay"
        className="user-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button className="user-modal__close" onClick={this.props.closeModal}></button>
        <h1 className="user-modal__heading">Editar usuário</h1>
        <form className="user-modal__form">
          {
            authService.isMetodocUser() && (
              <Fragment>
                <Select
                  className="modal-select-container"
                  classNamePrefix="modal-select"
                  placeholder="Empresa..."
                  options={utilsService.sortByName(this.props.companies)}
                  value={this.state.company}
                  onChange={(e) => { this.handleCompanyChange(e) }}
                />
              </Fragment>
            )
          }

          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Selecionar clientes"
            value={utilsService.sortByName(this.state.allowedCustomers)}
            options={this.state.listCustomers}
            onChange={(e) => { this.handleCustomerChange(e) }}
            isMulti
          />

          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Permissões..."
            options={permissionOptions}
            value={this.state.permission}
            onChange={(e) => { this.handlePermissionChange(e) }}
          />
          <input
            className="user-modal__form__input"
            type="text"
            placeholder="Nome do usuário (máx. 250 caracteres)"
            onChange={(e) => { this.handleNameChange(e) }}
            value={this.state.name}
            maxLength="250"
          />
          <input
            className="user-modal__form__input"
            type="text"
            placeholder="E-mail do usuário (máx. 250 caracteres)"
            onChange={(e) => { this.handleEmailChange(e) }}
            value={this.state.email}
            maxLength="250"
          />
          <input
            className="user-modal__form__input"
            type="text"
            placeholder="Telefone do usuário"
            onChange={(e) => { this.handlePhoneChange(e) }}
            value={this.state.displayPhone}
            maxLength="15"
          />
          <input
            className="user-modal__form__input"
            type="text"
            placeholder="Cargo do usuário (máx. 250 caracteres)"
            onChange={(e) => { this.handleRoleChange(e) }}
            value={this.state.role}
            maxLength="250"
          />

          <PrimaryButton disabled={!isValidForm} fullWidth onClick={(e) => { this.handleSubmit(e) }}>Confirmar</PrimaryButton>
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.ui.isEditUserModalOpen,
  companies: state.company.list,
  selectedCompany: state.company.selected,
  userToUpdate: state.user.selected,
  users: state.user.list
})

const mapDispatchToProps = dispatch => ({
  updateUser: user => dispatch(updateUser(user)),
  closeModal: () => dispatch(closeEditUserModal()),
  fetchUsers: () => dispatch(fetchUsers()),
  fetchCustomersForUser: () => dispatch(fetchCustomersForUser()),
  fetchCustomersByCompanyId: () => dispatch(fetchCustomersByCompanyId()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: (title, message) => dispatch(openErrorModal(title, message))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditUserModal)
