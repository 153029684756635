import React, { Component } from 'react'
import { connect } from 'react-redux'

import authService from '../../services/auth'

import './Users.scss'
import Tab from '../../components/Tab'
import CompanyList from '../../components/CompanyList'
import UserList from '../../components/UserList'
import CustomerList from '../../components/CustomerList'
import Layout from '../../components/Layout';

class Users extends Component {
  constructor(props) {
    super(props)
    this.state = {
      selectedTab: authService.isMetodocUser() ? 'companies' : 'customers',
    }
  }

  componentDidMount() { }

  render() {
    return (
      <Layout>
        <div className="users-screen">
          {
            authService.isMetodocUser() &&
            <div className="users-screen__tabs-container">
              <div>
                <Tab text={'Empresas'} onClick={(e) => { this.setState({ selectedTab: 'companies' }) }} active={this.state.selectedTab === 'companies'} />
                <Tab text={'Usuários'} onClick={(e) => { this.setState({ selectedTab: 'users' }) }} active={this.state.selectedTab === 'users'} />
                <Tab text={'Clientes'} onClick={(e) => { this.setState({ selectedTab: 'customers' }) }} active={this.state.selectedTab === 'customers'} />
              </div>
            </div>
          }
          {this.state.selectedTab === 'companies' && <CompanyList />}

          {this.state.selectedTab === 'customers' && <CustomerList />}

          {this.state.selectedTab === 'users' && <UserList />}
        </div>
      </Layout>
    )
  }
}

const mapStateToProps = state => ({
  customers: state.customer.list,
  selectedCustomer: state.customer.selected,
  companies: state.company.list,
  selectedCompany: state.company.selected,
  users: state.user.list
})

const mapDispatchToProps = dispatch => ({})

export default connect(mapStateToProps, mapDispatchToProps)(Users)
