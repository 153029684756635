import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import PrimaryButton from '../../PrimaryButton'

import { PENDING_STORAGE } from '../../../config/consts'

import utilsService from '../../../services/utils'

import { updateDocument, updateDocumentLocation } from '../../../actions/document'
import { closeEditDocumentLocationModal, openLoaderModal, closeLoaderModal, openErrorModal } from '../../../actions/ui'

import './EditDocumentLocationModal.scss'

Modal.setAppElement('#root')

class EditDocumentLocationModal extends Component {
  constructor(props) {
    super(props)
    this.state = {
      code: '',
      physicalStorageLocation: ''
    }

    this.handleAfterOpen = this.handleAfterOpen.bind(this)
    this.handleLocationChange = this.handleLocationChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleAfterOpen() {
    if (!this.props.trialId)
      this.setState(this.props.docToUpdate)
  }

  handleLocationChange(e) {
    this.setState({ physicalStorageLocation: e.target.value })
  }

  handleSubmit(e) {
    this.props.openLoaderModal()
    let promisse = null

    const original = {}
    if (!this.props.trialId)
      original.physicalStorageLocation = this.props.docToUpdate.physicalStorageLocation
    const updates = { physicalStorageLocation: this.state.physicalStorageLocation }
    const additionalInfo = utilsService.formatDiff(original, updates)

    if (this.props.trialId) {
      const doc = {
        trialId: this.props.trialId,
        location: this.state.physicalStorageLocation
      }

      promisse = this.props.updateDocumentLocation(doc, additionalInfo)
    } else
      promisse = this.props.updateDocument(this.state, additionalInfo)

    promisse.then(() => {
      if (this.props.onClose) this.props.onClose()
      this.props.closeModal()
      // window.location.reload();
    }).catch((e) => {
      this.props.openErrorModal()
    }).then(() => {
      this.props.closeLoaderModal()
    })
  }

  render() {
    const { trialId } = this.props
    const { physicalStorageLocation } = this.state
    const isValidForm = physicalStorageLocation.length > 0

    const description = trialId ?
      "Preencha o local de armanzenamento físico para aplicar nos documentos pendentes deste processo" :
      "Preencha o local de armanzenamento físico do documento"

    return (
      <Modal
        overlayClassName="add-doc-modal-overlay"
        className="add-doc-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button className="add-doc-modal__close" onClick={this.props.closeModal}></button>
        <h1 className="add-doc-modal__heading">Local de armazenamento físico</h1>
        <p className="add-doc-modal__description">{description}</p>
        <form className="add-doc-modal__form" onSubmit={(e) => { if (e) e.preventDefault() }}>
          <input
            className="add-doc-modal__form__input"
            type="text"
            onChange={this.handleLocationChange}
            value={this.state.physicalStorageLocation === PENDING_STORAGE ? "" : this.state.physicalStorageLocation}
            placeholder="Local de armazenamento (máx. 250 caracteres)"
            maxLength="250"
          />
          <PrimaryButton disabled={!isValidForm} fullWidth onClick={(e) => { this.handleSubmit(e) }} type="button">
            Salvar
          </PrimaryButton>
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.ui.isEditDocumentLocationModalOpen,
  docToUpdate: state.doc.selected
})

const mapDispatchToProps = dispatch => ({
  closeModal: () => {
    dispatch(closeEditDocumentLocationModal())
  },
  updateDocument: (doc, additionalInfo) => dispatch(updateDocument(doc, additionalInfo)),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
  updateDocumentLocation: (doc, additionalInfo) => dispatch(updateDocumentLocation(doc, additionalInfo))
})

export default connect(mapStateToProps, mapDispatchToProps)(EditDocumentLocationModal)
