import React from 'react'
import { debounce } from "lodash";

import './SearchText.scss'

class SearchText extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      value: ""
    }
    // debounce the passed in dispatch method
    this.changed = debounce(this.props.changed, 500)
  }
  // pass in the Redux action dispatcher and the
  // returned value via props

  handleChange = e => {
    const val = e.target.value
    this.setState({ value: val }, () => (val.length > 2 || val.length === 0) && this.changed(val))
  }
  render() {
    return (
      <input
        className={this.props.isTrial ? "trial-details-screen__searchbar" : "documents-screen__searchbar"}
        type="text"
        placeholder={this.props.isTrial ? "Busca por documentos pelo seu nome ou identificador" : "Busca por documento pelo seu nome"}
        onChange={this.handleChange}
        value={this.state.value}
      />
    )
  }
}

export default SearchText
