import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import PrimaryButton from "../../PrimaryButton";

import Select from "react-select";

import {
  closeEditCustomerModal,
  openLoaderModal,
  closeLoaderModal,
  openErrorModal,
} from "../../../actions/ui";

import "./EditCustomerModal.scss";
import { updateCustomer } from "../../../actions/customer";
import { fetchUsers } from "../../../actions/user";

Modal.setAppElement("#root");

class EditCustomerModal extends Component {
  constructor() {
    super();

    this.state = {
      active: true,
      name: "",
      email: "",
      cnpj: "",
      displayCnpj: "",
    };
    this.handleAfterOpen = this.handleAfterOpen.bind(this);
  }

  handleAfterOpen() {
    this.props.fetchUsers().then(() => {
      const users = this.props.userList.filter(
        (user) => user.company.id === this.props.customerToUpdate.companyId
      );

      let customerToUpdate = {
        ...this.props.customerToUpdate,
        displayCnpj: this.formatCnpj(this.props.customerToUpdate.cnpj),
        selectedUsers: users,
      };
      this.setState(customerToUpdate);
    });
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value });
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value });
  }

  handleCnpjChange(e) {
    this.setState({
      cnpj: e.target.value,
      displayCnpj: this.formatCnpj(e.target.value),
    });
  }

  handleStatusChange(e) {
    this.setState({ active: e.target.value });
  }

  handleUsersChange(selectedUsers) {
    this.setState({ selectedUsers });
  }

  formatCnpj(raw) {
    return raw
      ? raw
          .replace(/\D/g, "")
          .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5")
      : "";
  }

  handleSubmit() {
    let originalCustomer = this.props.customerToUpdate;
    let updates = {
      ...this.state,
      users: this.state.selectedUsers.map((x) => x.id),
    };
    this.props.openLoaderModal();
    this.props
      .updateCustomer(originalCustomer.id, updates)
      .then(() => {
        this.setState({
          name: "",
          email: "",
          cnpj: "",
          displayCnpj: "",
          users: [],
        });
        this.props.closeModal();
        if (this.props.onClose) this.props.onClose();
      })
      .catch((e) => {
        this.props.openErrorModal();
      })
      .then(() => {
        this.props.closeLoaderModal();
      });
  }

  render() {
    const { name, email, cnpj } = this.state;
    const isValidForm =
      name &&
      name.length > 0 &&
      email && email.length > 0 &&
      cnpj && cnpj.length > 17;
    return (
      <Modal
        overlayClassName="customer-modal-overlay"
        className="customer-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button
          className="customer-modal__close"
          onClick={this.props.closeModal}
        ></button>
        <h1 className="customer-modal__heading">Editar cliente</h1>
        <form className="customer-modal__form">
          <input
            className="customer-modal__form__input"
            type="text"
            placeholder="Nome do cliente (máx. 250 caracteres)"
            onChange={(e) => {
              this.handleNameChange(e);
            }}
            value={this.state.name}
            maxLength="250"
          />
          <input
            className="customer-modal__form__input"
            type="e-mail"
            placeholder="E-mail do cliente (máx. 250 caracteres)"
            onChange={(e) => {
              this.handleEmailChange(e);
            }}
            value={this.state.email}
            maxLength="250"
          />
          <input
            className="customer-modal__form__input"
            type="string"
            placeholder="CNPJ do cliente"
            onChange={(e) => {
              this.handleCnpjChange(e);
            }}
            value={this.state.displayCnpj}
            maxLength="18"
          />
          <Select
            className="modal-select-container"
            classNamePrefix="modal-select"
            placeholder="Usuários"
            options={this.props.userList}
            onChange={(e) => {
              this.handleUsersChange(e);
            }}
            value={this.state.selectedUsers}
            isMulti
          />
          <PrimaryButton
            disabled={!isValidForm}
            fullWidth
            onClick={(e) => this.handleSubmit(e)}
          >
            Confirmar
          </PrimaryButton>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.ui.isEditCustomerModalOpen,
  customerToUpdate: state.customer.selected,
  userList: state.user.list,
});

const mapDispatchToProps = (dispatch) => ({
  updateCustomer: (id, customer) => dispatch(updateCustomer(id, customer)),
  closeModal: () => dispatch(closeEditCustomerModal()),
  fetchUsers: () => dispatch(fetchUsers()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
});

export default connect(mapStateToProps, mapDispatchToProps)(EditCustomerModal);
