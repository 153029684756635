import React, { Component } from "react";
import { connect } from "react-redux";
import Modal from "react-modal";

import * as azureStorage from "@hobbytraceorg/azure-storage-browser";
import config from "../../../config";

import utilsService from "../../../services/utils";
import removeExtesionFromFilename from "../../../utils/removeExtensionFromFileName";

import PrimaryButton from "../../PrimaryButton";

import {
  setSelectedDocument,
  getDocAuthorization,
  attachDocument
} from "../../../actions/document";
import {
  closeAttachDocumentModal,
  openLoaderModal,
  closeLoaderModal,
  openErrorModal,
} from "../../../actions/ui";

import { addActivity } from "../../../actions/trial";
import { ACTIONS, TYPES } from "../../../config/consts";

import "./AttachDocumentModal.scss";
import "react-datepicker/dist/react-datepicker.css";

Modal.setAppElement("#root");

class AttachDocumentModal extends Component {
  constructor() {
    super();

    this.state = {
      code: "",
      loadingFile: false,
      name: "",
      file: "",
      filename: "",
      date: "",
      expiryDate: "",
      displayDate: null,
      displayExpiryDate: null,
      observation: "",
      hasFileUpdate: false,
    };

    this.handleAfterOpen = this.handleAfterOpen.bind(this);
    this.handleFileAttach = this.handleFileAttach.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.uploadFile = this.uploadFile.bind(this);
    this.handleAfterClose = this.handleAfterClose.bind(this);
  }

  handleAfterOpen() {
    this.setState(
      {
        ...this.props.docToUpdate,
        displayDate: undefined,
        displayExpiryDate: undefined,
      },
      (state) => {
        this.setState({
          displayDate: new Date(this.state.date),
          displayExpiryDate: this.state.expiryDate
            ? new Date(this.state.expiryDate)
            : "",
        });
      }
    );
  }

  handleFileAttach(e) {
    const file = e.target.files[0];
    const name = removeExtesionFromFilename(file.name);
    const date = new Date();
    
    if (utilsService.verifyDocument(file)) {
      this.setState({
        file,
        filename: file.name,
        name,
        displayDate: date,
        date: date.toISOString(),
      });
    }
  }

  uploadFile(doc) {
    const docType = doc.file.name
      .split(".")
      .pop()
      .toLowerCase();
    let contentType = "application/pdf";

    return new Promise((resolve, reject) => {
      this.props
        .getAuthorization(doc.trialId, doc.code, docType)
        .then((action) => {
          let authorization = action.authorization;
          const storageService = azureStorage.createBlobServiceWithSas(
            authorization.host,
            config.azureToken
          );
          storageService.createContainerIfNotExists(
            authorization.share,
            (err) => {
              if (err) return reject(err);
              storageService.createBlockBlobFromBrowserFile(
                authorization.share,
                `${authorization.directory}/${authorization.filename}`,
                doc.file,
                { contentSettings: { contentType } },
                (err) => {
                  if (err) return reject(err);
                  resolve(authorization);
                }
              );
            }
          );
        })
        .catch((e) => {
          this.props.closeLoaderModal();
          this.props.openErrorModal();
        });
    });
  }

  handleSubmit() {
    this.props.openLoaderModal();
    if (this.state.file) {
      const docToUpdate = this.props.docToUpdate;
      this.props.setSelectedDoc(null);
      this.props
        .attachDocument(this.state.file, docToUpdate.url)
        .then((action) => {
          this.props.addActivity({
            trialId: docToUpdate.trialId,
            documentId: docToUpdate.code,
            action: ACTIONS.ATTACH,
            type: TYPES.DOCUMENTS,
            additionalInfo: this.state.file.name
          });    
          this.props.closeModal();
          this.props.setSelectedDoc(docToUpdate);
          if (this.props.onClose) this.props.onClose();
        })
        .catch((e) => {
          this.props.openErrorModal();
        })
        .then(() => {
          this.props.closeLoaderModal();
        });
    } else {
      this.props.closeModal();
      if (this.props.onClose) this.props.onClose();
    }
  }

  handleAfterClose() {
    this.setState({
      code: "",
      loadingFile: false,
      name: "",
      file: "",
      filename: "",
      date: "",
      expiryDate: "",
      displayDate: null,
      displayExpiryDate: null,
      observation: "",
      hasFileUpdate: false,
    });
    this.props.setSelectedDoc(this.props.docToUpdate);
  }

  render() {
    const { file } = this.state;
    const isBigFile = file && file.size && file.size / 1024 / 1024 > 9;
    
    return (
      <Modal
        overlayClassName="add-doc-modal-overlay"
        className="add-doc-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterClose={this.handleAfterClose}
        onAfterOpen={this.handleAfterOpen}
      >
        <button
          className="add-doc-modal__close"
          onClick={this.props.closeModal}
        ></button>
        <h1 className="add-doc-modal__heading">Vincular documento</h1>
        <form className="add-doc-modal__form">
          <label className="add-doc-modal__form__custom-file-input">
            {this.state.loadingFile
              ? "Carregando arquivo..."
              : this.state.filename !== ""
              ? this.state.filename
              : "Selecione um novo arquivo para vincular"}
            <span className="add-doc-modal__form__custom-file-input__icon"></span>
            <input
              className="add-doc-modal__form__file-input"
              type="file"
              accept="application/pdf"
              onChange={this.handleFileAttach}
            />
          </label>
          {isBigFile && (
            <div className="add-doc-modal__form__input-warning">
              Arquivo com tamanho superior a 10 MB, pode demorar dependendo da
              sua conexão de internet.
            </div>
          )}
          <PrimaryButton
            fullWidth
            onClick={this.handleSubmit}
            disabled={!file}
          >
            Salvar
          </PrimaryButton>
        </form>
      </Modal>
    );
  }
}

const mapStateToProps = (state) => ({
  isOpen: state.ui.isAttachDocumentModalOpen,
  docToUpdate: state.doc.selected,
});

const mapDispatchToProps = (dispatch) => ({
  closeModal: () => { 
    dispatch(closeAttachDocumentModal())
  },
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
  setSelectedDoc: (doc) => dispatch(setSelectedDocument(doc)),
  attachDocument: (doc, docToUpdate) => dispatch(attachDocument(doc, docToUpdate)),
  getAuthorization: (trialId, code, docType) =>
    dispatch(getDocAuthorization(trialId, code, docType)),
  addActivity: (activity) => dispatch(addActivity(activity)),
});

export default connect(mapStateToProps, mapDispatchToProps)(AttachDocumentModal);
