import React from 'react'

import './Loader.scss'

const Loader = () => {
  return (
    <div className="loader">
      <div className="circ1"></div>
      <div className="circ2"></div>
      <div className="circ3"></div>
      <div className="circ4"></div>
    </div>);
}

export default Loader
