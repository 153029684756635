export const OPEN_NEW_TRIAL_MODAL = 'OPEN_NEW_TRIAL_MODAL'
export const CLOSE_NEW_TRIAL_MODAL = 'CLOSE_NEW_TRIAL_MODAL'
export const TOGGLE_TRIAL_DETAIL_POPOVER = 'TOGGLE_TRIAL_DETAIL_POPOVER'

export const OPEN_NEW_USER_MODAL = 'OPEN_NEW_USER_MODAL'
export const CLOSE_NEW_USER_MODAL = 'CLOSE_NEW_USER_MODAL'
export const OPEN_EDIT_USER_MODAL = 'OPEN_EDIT_USER_MODAL'
export const CLOSE_EDIT_USER_MODAL = 'CLOSE_EDIT_USER_MODAL'
export const OPEN_ADD_USER_MODAL = 'OPEN_ADD_USER_MODAL'
export const CLOSE_ADD_USER_MODAL = 'CLOSE_ADD_USER_MODAL'

export const OPEN_NEW_COMPANY_MODAL = 'OPEN_NEW_COMPANY_MODAL'
export const CLOSE_NEW_COMPANY_MODAL = 'CLOSE_NEW_COMPANY_MODAL'
export const OPEN_EDIT_COMPANY_MODAL = 'OPEN_EDIT_COMPANY_MODAL'
export const CLOSE_EDIT_COMPANY_MODAL = 'CLOSE_EDIT_COMPANY_MODAL'

export const OPEN_NEW_CUSTOMER_MODAL = 'OPEN_NEW_CUSTOMER_MODAL'
export const CLOSE_NEW_CUSTOMER_MODAL = 'CLOSE_NEW_CUSTOMER_MODAL'
export const OPEN_EDIT_CUSTOMER_MODAL = 'OPEN_EDIT_CUSTOMER_MODAL'
export const CLOSE_EDIT_CUSTOMER_MODAL = 'CLOSE_EDIT_CUSTOMER_MODAL'

export const OPEN_EDIT_TRIAL_MODAL = 'OPEN_EDIT_TRIAL_MODAL'
export const CLOSE_EDIT_TRIAL_MODAL = 'CLOSE_EDIT_TRIAL_MODAL'
export const OPEN_ADD_DOCUMENT_MODAL = 'OPEN_ADD_DOCUMENT_MODAL'
export const CLOSE_ADD_DOCUMENT_MODAL = 'CLOSE_ADD_DOCUMENT_MODAL'
export const OPEN_EDIT_DOCUMENT_MODAL = 'OPEN_EDIT_DOCUMENT_MODAL'
export const CLOSE_EDIT_DOCUMENT_MODAL = 'CLOSE_EDIT_DOCUMENT_MODAL'
export const OPEN_EDIT_DOCUMENT_LOCATION_MODAL = 'OPEN_EDIT_DOCUMENT_LOCATION_MODAL'
export const CLOSE_EDIT_DOCUMENT_LOCATION_MODAL = 'CLOSE_EDIT_DOCUMENT_LOCATION_MODAL'
export const TOGGLE_DOCUMENT_DETAIL_POPOVER = 'TOGGLE_DOCUMENT_DETAIL_POPOVER'

export const OPEN_ATTACH_DOCUMENT_MODAL = 'OPEN_ATTACH_DOCUMENT_MODAL'
export const CLOSE_ATTACH_DOCUMENT_MODAL = 'CLOSE_ATTACH_DOCUMENT_MODAL'

export const OPEN_INFO_DOCUMENT_MODAL = 'OPEN_INFO_DOCUMENT_MODAL'
export const CLOSE_INFO_DOCUMENT_MODAL = 'CLOSE_INFO_DOCUMENT_MODAL'

export const OPEN_ERROR_MODAL = 'OPEN_ERROR_MODAL'
export const CLOSE_ERROR_MODAL = 'CLOSE_ERROR_MODAL'

export const OPEN_CONFIRM_MODAL = 'OPEN_CONFIRM_MODAL'
export const CLOSE_CONFIRM_MODAL = 'CLOSE_CONFIRM_MODAL'

export const SET_SELECTED_TAB_START = 'SET_SELECTED_TAB_START'
export const SET_SELECTED_TAB_SUCCESS = 'SET_SELECTED_TAB_SUCCESS'
export const SET_SELECTED_TAB_ERROR = 'SET_SELECTED_TAB_ERROR'

export const OPEN_LOADER_MODAL = 'OPEN_LOADER_MODAL'
export const CLOSE_LOADER_MODAL = 'CLOSE_LOADER_MODAL'

export const openNewTrialModal = () => ({
  type: OPEN_NEW_TRIAL_MODAL,
})

export const closeNewTrialModal = () => ({
  type: CLOSE_NEW_TRIAL_MODAL,
})

export const toggleTrialDetailPopover = () => ({
  type: TOGGLE_TRIAL_DETAIL_POPOVER,
})

export const openNewUserModal = () => ({
  type: OPEN_NEW_USER_MODAL,
})

export const closeNewUserModal = () => ({
  type: CLOSE_NEW_USER_MODAL,
})

export const openEditUserModal = () => ({
  type: OPEN_EDIT_USER_MODAL,
})

export const closeEditUserModal = () => ({
  type: CLOSE_EDIT_USER_MODAL,
})

export const openAddUserModal = () => ({
  type: OPEN_ADD_USER_MODAL,
})

export const closeAddUserModal = () => ({
  type: CLOSE_ADD_USER_MODAL,
})

export const openNewCompanyModal = () => ({
  type: OPEN_NEW_COMPANY_MODAL,
})

export const closeNewCompanyModal = () => ({
  type: CLOSE_NEW_COMPANY_MODAL,
})

export const openEditCompanyModal = () => ({
  type: OPEN_EDIT_COMPANY_MODAL,
})

export const closeEditCompanyModal = () => ({
  type: CLOSE_EDIT_COMPANY_MODAL,
})

export const openNewCustomerModal = () => ({
  type: OPEN_NEW_CUSTOMER_MODAL,
})

export const closeNewCustomerModal = () => ({
  type: CLOSE_NEW_CUSTOMER_MODAL,
})

export const openEditCustomerModal = () => ({
  type: OPEN_EDIT_CUSTOMER_MODAL,
})

export const closeEditCustomerModal = () => ({
  type: CLOSE_EDIT_CUSTOMER_MODAL,
})

export const openEditTrialModal = () => ({
  type: OPEN_EDIT_TRIAL_MODAL,
})

export const closeEditTrialModal = () => ({
  type: CLOSE_EDIT_TRIAL_MODAL,
})

export const openAddDocumentModal = () => ({
  type: OPEN_ADD_DOCUMENT_MODAL,
})

export const closeAddDocumentModal = () => ({
  type: CLOSE_ADD_DOCUMENT_MODAL,
})

export const openEditDocumentModal = () => ({
  type: OPEN_EDIT_DOCUMENT_MODAL,
})

export const openAttachDocumentModal = () => ({
  type: OPEN_ATTACH_DOCUMENT_MODAL,
})

export const closeAttachDocumentModal = () => ({
  type: CLOSE_ATTACH_DOCUMENT_MODAL,
})

export const openInfoDocumentModal = () => ({
  type: OPEN_INFO_DOCUMENT_MODAL,
})

export const closeInfoDocumentModal = () => ({
  type: CLOSE_INFO_DOCUMENT_MODAL,
})

export const closeEditDocumentModal = () => ({
  type: CLOSE_EDIT_DOCUMENT_MODAL,
})

export const openEditDocumentLocationModal = () => ({
  type: OPEN_EDIT_DOCUMENT_LOCATION_MODAL,
})

export const closeEditDocumentLocationModal = () => ({
  type: CLOSE_EDIT_DOCUMENT_LOCATION_MODAL,
})

export const toggleDocumentDetailPopover = () => ({
  type: TOGGLE_DOCUMENT_DETAIL_POPOVER,
})

export const openConfirmModal = () => ({
  type: OPEN_CONFIRM_MODAL,
})

export const closeConfirmModal = () => ({
  type: CLOSE_CONFIRM_MODAL,
})

export const openErrorModal = (title, message) => ({
  type: OPEN_ERROR_MODAL,
  title, message
})

export const closeErrorModal = () => ({
  type: CLOSE_ERROR_MODAL,
})

export const setSelectedTabStart = doc => ({
  type: SET_SELECTED_TAB_START,
  doc,
})

export const setSelectedTabError = () => ({
  type: SET_SELECTED_TAB_ERROR,
})

export const setSelectedTabSuccess = base64 => ({
  type: SET_SELECTED_TAB_SUCCESS
})

export const openLoaderModal = () => ({
  type: OPEN_LOADER_MODAL,
})

export const closeLoaderModal = () => ({
  type: CLOSE_LOADER_MODAL,
})

export const setTab = tab => dispatch => {
  dispatch(setSelectedTabStart(tab))
  return dispatch(setSelectedTabSuccess())
}