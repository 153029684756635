import React, { Component } from "react";
import { connect } from "react-redux";
import { Redirect, withRouter } from "react-router-dom";

import Tab from "../Tab";
import PrimaryButton from "../PrimaryButton";
import SecondaryButton from "../SecondaryButton";
import Avatar from "../Avatar";
import AddDocumentModal from "../modals/AddDocumentModal";
import NewTrialModal from "../modals/NewTrialModal";

import { logout } from "../../actions/auth";
import { openNewTrialModal } from "../../actions/ui";
import { openAddDocumentModal } from "../../actions/ui";
import { setIsAddDocFromTrialDetails } from "../../actions/document";
import { setTab } from "../../actions/ui";
import auth from "../../services/auth";

import logo from "../../assets/logo_header.svg";
import "./Header.scss";

class Header extends Component {
  tabs = [
    { label: "Processos", path: "/trials" },
    { label: "Documentos", path: "/documents" },
  ];

  constructor() {
    super();

    this.state = {
      loggedOut: false,
    };

    if (!auth.isAdminUser())
      this.tabs.push({
        label: auth.isMetodocUser() ? "Contas" : "Ajustes",
        path: "/users",
      });

    this.logout = this.logout.bind(this);
  }

  logout(e) {
    e.preventDefault();

    this.props.logout().then(() => {
      this.setState({ loggedOut: true });
    });
  }

  render() {
    const { username, location } = this.props;
    if (this.state.loggedOut) {
      return <Redirect to="/home" />;
    } else {
      return (
        <div className="header">
          <img className="header__logo" src={logo} alt="Logo Metodoc" />
          <div className="header__tabs-container">
            {this.tabs.map((tab, index) => (
              <Tab
                key={index}
                text={tab.label}
                linkTo={tab.path}
                active={location.pathname.indexOf(tab.path) > -1}
              />
            ))}
          </div>
          {(auth.isMetodocUser() || auth.isRegularUser()) && (
            <div className="header__buttons-container">
              <SecondaryButton onClick={this.props.openNewTrialModal}>
                Adicionar processo
              </SecondaryButton>
              <PrimaryButton
                onClick={() => {
                  this.props.openAddDocumentModal(true);
                }}
              >
                Adicionar documento
              </PrimaryButton>
            </div>
          )}
          <div className="header__user-container">
            <Avatar username={username} />
            <p className="header__username">{username}</p>
          </div>
          <p className="header__logout" onClick={this.logout}>
            Sair
          </p>
          <AddDocumentModal />
          <NewTrialModal />
        </div>
      );
    }
  }
}

const mapStateToProps = (state) => ({
  selectedTab: state.ui.selectedTab,
});

const mapDispatchToProps = (dispatch) => ({
  openNewTrialModal: () => dispatch(openNewTrialModal()),
  openAddDocumentModal: (isAddDocFromTrialDetails) => {
    dispatch(openAddDocumentModal());
    dispatch(setIsAddDocFromTrialDetails(isAddDocFromTrialDetails));
  },
  setTab: (tab) => dispatch(setTab(tab)),
  logout: () => dispatch(logout()),
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Header));
