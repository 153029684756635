import React, { Component } from 'react'
import { Redirect } from 'react-router-dom'
import { connect } from 'react-redux'

import LoginBox from '../../components/LoginBox'
import PrimaryButton from '../../components/PrimaryButton'

import loginService from '../../services/login'
import { openLoaderModal, closeLoaderModal } from '../../actions/ui'

class ForgotPassword extends Component {
  constructor(props) {
    super(props)

    this.state = {
      email: '',
      isLoading: false,
      hasErrors: false,
      isSuccess: false,
    }

    this.handleEmailChange = this.handleEmailChange.bind(this)
    this.handleSubmit = this.handleSubmit.bind(this)
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handleSubmit(e) {
    e.preventDefault()

    const params = {
      email: this.state.email,
    }

    this.props.openLoaderModal()
    this.setState({ isLoading: true })
    loginService.forgotPassword(params).then(response => {
      this.setState({ isSuccess: true })
    }).catch(error => {
      this.setState({ hasErrors: true })
    }).then(() => {
      this.setState({ isLoading: false })
      this.props.closeLoaderModal()
    })
  }

  render() {
    const errorMessage = () => {
      if (this.state.hasErrors) {
        return <p className="login-screen__has-error">E-mail inválido</p>
      } else {
        return null
      }
    }

    if (this.state.isSuccess) {
      return <Redirect to='/forgotPasswordSuccess' />
    } else {
      return (
        <div className='login-screen'>
          <LoginBox subheader={'Preencha com o e-mail cadastrado para recuperar a senha'}>
            {errorMessage()}
            <form className="login-screen__form" action="#">
              <input
                className="login-screen__input"
                type="text"
                placeholder="E-mail"
                onChange={this.handleEmailChange}
                value={this.state.email}
                onKeyDown={(e) => {
                  if (e.key === 'Enter') {
                    this.handleSubmit(e)
                  }
                }}
              />
              <PrimaryButton fullWidth onClick={this.handleSubmit} disabled={this.state.isLoading}>
                Enviar nova senha por e-mail
              </PrimaryButton>
            </form>
          </LoginBox>
        </div>
      )
    }
  }
}

const mapStateToProps = state => ({})

const mapDispatchToProps = dispatch => ({
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(ForgotPassword)
