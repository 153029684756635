import config from "../config";

import {
  FETCH_DOCUMENTS_START,
  FETCH_DOCUMENTS_ERROR,
  FETCH_DOCUMENTS_SUCCESS,
  ADD_DOCUMENT_START,
  ADD_DOCUMENT_ERROR,
  ADD_DOCUMENT_SUCCESS,
  SET_SELECTED_DOCUMENT_START,
  SET_SELECTED_DOCUMENT_ERROR,
  SET_SELECTED_DOCUMENT_SUCCESS,
  CLEAN_SELECTED_DOCUMENT,
  AUTHORIZE_DOCUMENT_UPLOAD_START,
  AUTHORIZE_DOCUMENT_UPLOAD_ERROR,
  AUTHORIZE_DOCUMENT_UPLOAD_SUCCESS,
  SET_ADD_DOC_FROM_TRIAL_DETAILS,
  SET_UPLOADED_FILES,
  UPDATE_FILE,
  SET_SHOW_FEED_BACK_UPLOAD_FILES,
  REMOVE_FILE_FROM_LIST,
  FETCH_UPLOAD_ZONE,
  SET_SHOW_UPLOAD_ZONE_FILES,
} from "../actions/document";

const initialState = {
  list: [],
  pages: 0,
  loading: false,
  selected: null,
  base64: "",
  baseUrl: config.baseUrl + "/documents",
  isAddDocFromTrialDetails: false,
  isShowUploadZone: false,
  isShowFeedBackUpload: false,
  allFilesToUpload: [],
};

export default (state = initialState, action) => {
  switch (action.type) {
    case FETCH_DOCUMENTS_START:
      return { ...state, loading: true };

    case FETCH_DOCUMENTS_SUCCESS:
      return {
        ...state,
        list: action.docs.list,
        pages: action.docs.pages,
        loading: false,
      };

    case SET_SELECTED_DOCUMENT_START:
      return { ...state, selected: action.doc };

    case SET_SELECTED_DOCUMENT_SUCCESS:
      return { ...state, base64: action.base64 };

    case CLEAN_SELECTED_DOCUMENT:
      return { ...state, selected: null };

    case SET_ADD_DOC_FROM_TRIAL_DETAILS:
      return {
        ...state,
        isAddDocFromTrialDetails: action.isAddDocFromTrialDetails,
      };

    case SET_UPLOADED_FILES:
      return {
        ...state,
        allFilesToUpload: state.allFilesToUpload.concat(action.files),
      };

    case UPDATE_FILE:
      const newInfoInFiles = state.allFilesToUpload.map((file) =>
        file.id === action.id ? { ...file, ...action.data } : file
      );
      return {
        ...state,
        allFilesToUpload: newInfoInFiles,
      };

    case SET_SHOW_FEED_BACK_UPLOAD_FILES:
      return {
        ...state,
        isShowFeedBackUpload: action.isShowFeedBackUpload,
      };

    case REMOVE_FILE_FROM_LIST:
      const indexFile = state.allFilesToUpload.findIndex(
        (file) => file.id === action.id
      );

      const newAllFiles = [...state.allFilesToUpload.splice(1, indexFile)];
      return {
        ...state,
        allFilesToUpload: newAllFiles,
      };
    case FETCH_UPLOAD_ZONE:
      return {
        ...state,
        isShowUploadZone: false,
        isShowFeedBackUpload: false,
        allFilesToUpload: [],
      };

    case SET_SHOW_UPLOAD_ZONE_FILES:
      return {
        ...state,
        isShowUploadZone: action.isShowUploadZone,
      };

    case FETCH_DOCUMENTS_ERROR:
    case ADD_DOCUMENT_START:
    case ADD_DOCUMENT_ERROR:
    case ADD_DOCUMENT_SUCCESS:
    case AUTHORIZE_DOCUMENT_UPLOAD_START:
    case AUTHORIZE_DOCUMENT_UPLOAD_ERROR:
    case AUTHORIZE_DOCUMENT_UPLOAD_SUCCESS:
    case SET_SELECTED_DOCUMENT_ERROR:
    default:
      return state;
  }
};
