import React, { Component } from 'react'
import { connect } from 'react-redux'
import Modal from 'react-modal'

import PrimaryButton from '../../PrimaryButton'

import { closeEditCompanyModal, openLoaderModal, closeLoaderModal, openErrorModal } from '../../../actions/ui'

import './EditCompanyModal.scss'
import { updateCompany } from '../../../actions/company';

Modal.setAppElement('#root')

class EditCompanyModal extends Component {
  constructor() {
    super()

    this.state = {
      active: true,
      name: '',
      email: '',
      cnpj: '',
      displayCnpj: ''
    }
    this.handleAfterOpen = this.handleAfterOpen.bind(this)
  }

  handleAfterOpen() {
    let companyToUpdate = {
      ...this.props.companyToUpdate,
      displayCnpj: this.formatCnpj(this.props.companyToUpdate.cnpj)
    }
    this.setState(companyToUpdate)
  }

  handleNameChange(e) {
    this.setState({ name: e.target.value })
  }

  handleEmailChange(e) {
    this.setState({ email: e.target.value })
  }

  handleCnpjChange(e) {
    this.setState({ cnpj: e.target.value, displayCnpj: this.formatCnpj(e.target.value) })
  }

  handleStatusChange(e) {
    this.setState({ active: e.target.value })
  }

  formatCnpj(raw) {
    return raw.replace(/\D/g, '').replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, "$1.$2.$3/$4-$5");
  }

  handleSubmit() {
    let originalCompany = this.props.companyToUpdate
    this.props.openLoaderModal()
    this.props.updateCompany(originalCompany.id, this.state).then(() => {
      this.setState({ name: '', email: '', cnpj: '', displayCnpj: '' })
      this.props.closeModal()
      if (this.props.onClose) this.props.onClose()
    }).catch((e) => {
      this.props.openErrorModal()
    }).then(() => {
      this.props.closeLoaderModal()
    })
  }

  render() {
    const { name, email, cnpj } = this.state
    const isValidForm = (name && name.length > 0) && (email && email.length > 0) && (cnpj && cnpj.length > 17)
    return (
      <Modal
        overlayClassName="company-modal-overlay"
        className="company-modal"
        isOpen={this.props.isOpen}
        onRequestClose={this.props.closeModal}
        onAfterOpen={this.handleAfterOpen}
      >
        <button className="company-modal__close" onClick={this.props.closeModal}></button>
        <h1 className="company-modal__heading">Editar empresa</h1>
        <form className="company-modal__form">
          <input
            className="company-modal__form__input"
            type="text"
            placeholder="Nome da empresa (máx. 250 caracteres)"
            onChange={(e) => { this.handleNameChange(e) }}
            value={this.state.name}
            maxLength="250"
          />
          <input
            className="company-modal__form__input"
            type="e-mail"
            placeholder="E-mail da empresa (máx. 250 caracteres)"
            onChange={(e) => { this.handleEmailChange(e) }}
            value={this.state.email}
            maxLength="250"
          />
          <input
            className="company-modal__form__input"
            type="string"
            placeholder="CNPJ da empresa"
            onChange={(e) => { this.handleCnpjChange(e) }}
            value={this.state.displayCnpj}
            maxLength="18"
          />
          <PrimaryButton disabled={!isValidForm} fullWidth onClick={(e) => this.handleSubmit(e)}>Confirmar</PrimaryButton>
        </form>
      </Modal>
    )
  }
}

const mapStateToProps = state => ({
  isOpen: state.ui.isEditCompanyModalOpen,
  companyToUpdate: state.company.selected,
})

const mapDispatchToProps = dispatch => ({
  updateCompany: (id, company) => dispatch(updateCompany(id, company)),
  closeModal: () => dispatch(closeEditCompanyModal()),
  openLoaderModal: () => dispatch(openLoaderModal()),
  closeLoaderModal: () => dispatch(closeLoaderModal()),
  openErrorModal: () => dispatch(openErrorModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(EditCompanyModal)
