import React, { Component } from 'react'
import ReactTable from 'react-table'
import { connect } from 'react-redux'

import EditCompanyModal from '../../components/modals/EditCompanyModal'

import { fetchCompanies, setSelectedCompany } from '../../actions/company'
import { openEditCompanyModal, openNewCompanyModal } from '../../actions/ui'

import editIcon from '../../assets/edit.svg'
import PrimaryButton from '../../components/PrimaryButton';
import NewCompanyModal from '../../components/modals/NewCompanyModal';
import Arrow from '../../components/Arrow';

import './CompanyList.scss'

class CompanyList extends Component {
  constructor() {
    super()
    this.state = {
      allCompanies: [],
      filteredCompanies: [],
      page: undefined,
      sort: [{ id: 'name', desc: false }],
      search: ''
    }
  }

  componentDidMount() {
    this.refresh()
  }

  refresh() {
    this.props.fetchCompanies().then((action) => {
      this.setState({ allCompanies: action.companies, filteredCompanies: action.companies })
    })
  }

  handleSearch(value) {
    if (value !== '') {
      const filteredCompanies = this.state.allCompanies.filter(company => {
        return company.name.toLowerCase().indexOf(value.toLowerCase()) > -1
      })
      this.setState({ filteredCompanies })
    } else
      this.setState({ filteredCompanies: this.state.allCompanies })

    this.setState({ search: value, page: 0 })
  }

  openEditCompanyModal(company) {
    this.props.setSelectedCompany(company)
    this.props.openEditCompanyModal()
  }

  render() {

    const renderEditButton = (company) =>
      <button title="Editar" className="company-list__edit-company-button" onClick={() => this.openEditCompanyModal(company)}>
        <img src={editIcon} alt="Editar" />
      </button>

    const columns = [
      { Header: 'Nome', accessor: 'name', Cell: (d) => <div>{d.original.name}</div> },
      { Header: 'E-mail', accessor: 'email', Cell: (d) => <div>{d.original.email}</div> },
      { Header: '', id: 'edit', Cell: d => renderEditButton(d.original) },
    ]

    return (
      <div className="company-list">
        <div className="company-list__searchbar-container">
          <div className="company-list__searchbar-wrapper">
            <span className="company-list__searchbar-icon"></span>
            <input
              className="company-list__searchbar"
              type="text"
              placeholder="Buscar empresa pelo nome"
              onChange={e => this.handleSearch(e.target.value)}
              value={this.state.search}
              onBlur={() => this.setState({ page: undefined })}
            />
          </div>
        </div>
        <div className="company-list__actionbuttons-container">
          <PrimaryButton onClick={(e) => this.props.openNewCompanyModal()}>
            Adicionar empresa
          </PrimaryButton>
        </div>
        <ReactTable data={this.state.filteredCompanies} columns={columns}
          resizable={false} minRows={1} defaultPageSize={10}
          showPageSizeOptions={false} showPageJump={false} page={this.state.page}
          sorted={this.state.sort} onSortedChange={sort => this.setState({ sort })}
          pageText="Página" ofText="de" noDataText="Nenhum registro encontrado"
          previousText={<Arrow />} nextText={<Arrow right />}
        />
        <EditCompanyModal onClose={(e) => { this.refresh() }} />
        <NewCompanyModal onClose={(e) => { this.refresh() }} />
      </div>
    )
  }
}

const mapStateToProps = state => ({
  companies: state.company.list,
  selectedCompany: state.company.selected
})

const mapDispatchToProps = dispatch => ({
  fetchCompanies: () => dispatch(fetchCompanies()),
  setSelectedCompany: company => dispatch(setSelectedCompany(company)),
  openEditCompanyModal: () => dispatch(openEditCompanyModal()),
  openNewCompanyModal: () => dispatch(openNewCompanyModal()),
})

export default connect(mapStateToProps, mapDispatchToProps)(CompanyList)