import "react-app-polyfill/ie11";

import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";
// import { createLogger } from "redux-logger";
import { hotjar } from "react-hotjar";
import appReducer from "./reducers";
import { BrowserRouter } from "react-router-dom";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import session from "./services/session";
import config from "./config";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import pt from "date-fns/locale/pt-BR";
import { composeWithDevTools } from 'redux-devtools-extension'

import "./index.scss";

session.handle();

// const logger = createLogger({
//   predicate: (getState, action) => !config.isProduction,
// });
const store = createStore(appReducer, composeWithDevTools(applyMiddleware(thunk)));

registerLocale("pt-BR", pt);
setDefaultLocale("pt-BR");

ReactDOM.render(
  <Provider store={store}>
    <BrowserRouter>
      <App />
    </BrowserRouter>
  </Provider>,
  document.getElementById("root")
);

hotjar.initialize(config.hotjarAppId, config.hotjarRevision);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: http://bit.ly/CRA-PWA
serviceWorker.unregister();
